import './card_loader.component.style.scss';
import { FC } from "react";
import LoadingComponent from "../../../../components/LoadingComponent/loading.component";
import CardLoaderComponentProps from "./card_loader.component.props";

const CardLoaderComponent: FC<CardLoaderComponentProps> = ({ className, children, loading, style, condition }) => {
    if (!loading && children == null) return null;
    return (
        <div className={`card_loader_component ${className}`} style={style}>
            {(!loading || condition) ? children :
                <div className="card loader_container_card">
                    <LoadingComponent showText={false}/>
                </div>
            }
        </div>
    );
};

export default CardLoaderComponent;