import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import QACategoryEntity from "../../../../domain/entities/qa_category.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QACategoryDto from "../../../dto/qa_category.dto";

const createCategoryApiImpl = async (category: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>> => {
    try {
        const relativeUrl = "/category";
        const body = {
            "agent_id": category.agentId,
            "group": category.title,
            "description": category.description,
        };
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, { contentType: 'application/json' });
        return right(QACategoryDto.fromJSON(response));
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default createCategoryApiImpl;