import { SentimentsOnDate } from "../../domain/repositories/dashboard.repository";
import DateDto from "./date.dto";

const fromJson = (json: any): SentimentsOnDate => {
    return {
        date: DateDto.fromServerDate(json.date),
        sentiments: {
            negative: json.total_negative_sentiments,
            neutral: json.total_neutral_sentiments,
            positive: json.total_positive_sentiments,
        }
    }
}

export default {
    fromJson,
}