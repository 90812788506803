import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { HumanRanking, Target } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import HumanRankingDto from "../../../dto/human_ranking.dto";
import SearchResultEntity from "../../../../domain/entities/search_result.entity";

//TODO missing paginator
const searchRankingAgentsApiImpl = async (_: {
    query?: string, initDate?: Date, endDate?: Date,
    page: number, itemsPerPage: number, sortBy: "ASC" | "DESC",
}): Promise<Either<ErrorEntity, SearchResultEntity<HumanRanking>>> => {
    try {
        console.log("searchRankingAgentsApiImpl", _);
        let relativeUrl = `/stats/human-agents-ranking-paged?sort=${_.sortBy}&page_number=${_.page}&page_size=${_.itemsPerPage}`;
        if(_.query) relativeUrl += `&name=${_.query}`;
        if (_.initDate) relativeUrl += `&start_date=${DateDto.toServer(_.initDate)}`;
        if (_.endDate) relativeUrl += `&end_date=${DateDto.toServer(_.endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        console.log("response", response);
        const parsed = response.content.map((item: any) => HumanRankingDto.fromJSON(item));
        return right({
            data: parsed,
            total: response.total_elements,
            page: response.number,
            itemsPerPage: response.size,
            pages: response.total_pages,
        });

    } catch (error) {
        console.log("error", error)
        return left(ErrorEntity.fromError(error));
    }
}

export default searchRankingAgentsApiImpl;