import QACategoryEntity from "./qa_category.entity";

export interface IQAAgentEntity {
    id: string;
    name: string;
    description?: string;
    clientId?: string;
    opTypeId?: string;
    categories: QACategoryEntity[];
}

export default class QAAgentEntity implements IQAAgentEntity{
    id: string;
    name: string;
    description?: string | undefined;
    clientId?: string | undefined;
    opTypeId?: string | undefined;
    categories: QACategoryEntity[];

    constructor(_: IQAAgentEntity) {
        this.id = _.id;
        this.name = _.name;
        this.description = _.description;
        this.clientId = _.clientId;
        this.opTypeId = _.opTypeId;
        this.categories = _.categories;
    }

    getMaxPoints(): number {
        return this.categories.reduce((acc, category) => acc + category.getMaxQuestionPoints(), 0);
    }

}