import { injectable } from "inversify";
import AuthRepository from "../../repositories/auth.repository";
import UserProvider from "../../providers/user/UserProvider";

interface _Props {
    authRepository: AuthRepository;
    userProvider: UserProvider;
}

@injectable()
export default class LogoutUseCase {
    private userProvider: UserProvider;
    private authRepository: AuthRepository;
    constructor(props: _Props) {
        this.userProvider = props.userProvider;
        this.authRepository = props.authRepository;
    }
    public call = async () => {
        await this.authRepository.logout();
        this.userProvider.Actions.setUser(null);
    }
}

export const LogoutUseCaseName = "LogoutUseCase";