import { Either, right, left } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";

const UploadAudioFileApiImpl = async (file: File, urlFetch: string): Promise<Either<ErrorEntity, void>> => {
    try {
        const response = await fetch(urlFetch, {
            method: 'PUT',
            headers: {
                'Content-Type': 'audio/mpeg',
            },
            body: file
        });
        return right(undefined);
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default UploadAudioFileApiImpl;