import { injectable } from "inversify";
import DashboardRepository, { AverageCalls, HumanRanking, GetOperationReportResponse, ReasonForCalls, SentimentsOnDate, Target } from "../../../domain/repositories/dashboard.repository";
import { Either, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import MasterItemEntity from "../../../domain/entities/master_item.entity";
import Testing from "../../../presentation/utils/Testing";
import ClientAnalysisEntity from "../../../domain/entities/client_analysis.entity";
import QACommentEntity from "../../../domain/entities/qa_comment.entity";
import { fakeUser } from "../auth/auth.repository.fake";
import SearchResultEntity from "../../../domain/entities/search_result.entity";

export const testComment: QACommentEntity = {
    comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vehicula eros libero, vel viverra mauris lobortis at. Donec volutpat tincidunt eleifend. Integer ligula justo, imperdiet eu dignissim non, tincidunt non dolor. Aliquam aliquam diam eu mauris rutrum fringilla ut id libero. Cras auctor a sem a maximus",
    date: new Date(),
    id: "123",
    user: fakeUser,
}

export const testClient: HumanRanking = {
    id: "1",
    name: "HumanRanking name",
    rankingPosition: 3,
    score: 34,
    sentiments: {
        negative: Math.floor(Math.random() * 10),
        neutral: Math.floor(Math.random() * 10),
        positive: Math.floor(Math.random() * 10),
    },
    totalEvaluatedCalls: Math.floor(Math.random() * 100),
}

@injectable()
export default class DashboardRepositoryFake implements DashboardRepository {
    getTopAndBottomAgents = async (_: {
        clientId?: string, startDate: Date, endDate: Date
    }): Promise<Either<ErrorEntity, { best: HumanRanking[]; worst: HumanRanking[]; }>> => {
        await Testing.sleeper(1000);
        return right({
            best: Array.from({ length: 7 }, (_, i) => ({
                id: `id ${i}`,
                name: `name ${i}`,
                rankingPosition: i,
                totalEvaluatedCalls: Math.floor(Math.random() * 100),
                score: (Math.random() * 30) + 60,
                sentiments: {
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                }
            })),
            worst: Array.from({ length: 7 }, (_, i) => ({
                id: `id ${i}`,
                name: `name ${i}`,
                rankingPosition: i,
                totalEvaluatedCalls: Math.floor(Math.random() * 100),
                score: (Math.random() * 30) + 60,
                sentiments: {
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                }
            })),
        })
    }
    searchRanking = async (_: { query: string; initDate?: Date; endDate?: Date; page: number; itemsPerPage: number; sortBy: "ASC" | "DESC"; }): Promise<Either<ErrorEntity, SearchResultEntity<HumanRanking>>> => {
        await Testing.sleeper(1000);
        const limitPage = 3;
        if (_.page <= 3) {
            return right({
                data: Array.from({ length: _.itemsPerPage }, (_, i) => ({
                    id: `id ${i}`,
                    name: `name ${i}`,
                    rankingPosition: i,
                    totalEvaluatedCalls: Math.floor(Math.random() * 100),
                    score: (Math.random() * 30) + 60,
                    sentiments: {
                        negative: Math.floor(Math.random() * 10),
                        neutral: Math.floor(Math.random() * 10),
                        positive: Math.floor(Math.random() * 10),
                    }
                })),
                total: limitPage * _.itemsPerPage,
                itemsPerPage: _.itemsPerPage,
                page: _.page,
                pages: limitPage,
            });
        }

        return right({
            data: [],
            total: limitPage * _.itemsPerPage,
            itemsPerPage: _.itemsPerPage,
            page: _.page,
            pages: limitPage,
        });
    };

    getCallAverages = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => {
        await Testing.sleeper(1400);
        const dates = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24)));
        const dayToDay = dates.map(date => {
            return {
                date,
                sentiments: {
                    veryNegative: Math.floor(Math.random() * 10),
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                    veryPositive: Math.floor(Math.random() * 10),
                },
            }
        });
        const totals = {
            negative: dayToDay.reduce((acc, day) => acc + day.sentiments.negative, 0),
            neutral: dayToDay.reduce((acc, day) => acc + day.sentiments.neutral, 0),
            positive: dayToDay.reduce((acc, day) => acc + day.sentiments.positive, 0),
        }

        const total = totals.negative + totals.neutral + totals.positive;

        const averages = {
            calls: Math.floor(Math.random() * 100),
            agent: Math.floor(Math.random() * 100),
            customer: Math.floor(Math.random() * 100),
        };

        return right({
            averages,
            totalCalls: total,
        })
    }
    getSentiments = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => {
        await Testing.sleeper(800);
        const dates = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24)));
        const dayToDay = dates.map(date => {
            return {
                date,
                sentiments: {
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                },
            }
        });
        return right(dayToDay);
    };
    getReasonForCalls = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ReasonForCalls[]>> => {
        await Testing.sleeper(1100);
        //create an array of dates between the start and end date
        const reasonForCalls = Array.from({ length: 6 }, (_, i) => {
            return {
                name: `name ${i}`,
                count: parseFloat((Math.random() * 100).toFixed(2)),
            }
        });
        return right(reasonForCalls);
    }
    getScoreDetails = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, Target>> => {
        await Testing.sleeper(2000);
        const target = {
            serviceLevel: Math.floor(Math.random() * 100),
            current: Math.floor(Math.random() * 100),
            increment: parseFloat((Math.random() * 100).toFixed(2)),
        };
        return right(target);
    }
    getClientOps = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, { best: HumanRanking[]; worst: HumanRanking[]; }>> => {
        await Testing.sleeper(1600);
        const client = Array.from({ length: Math.floor(Math.random() * 10) }, (_, i) => {
            return {
                id: `id ${i}`,
                name: `name ${i}`,
                totalEvaluatedCalls: Math.floor(Math.random() * 100),
                rankingPosition: i,
                score: (Math.random() * 30) + 60,
                sentiments: {
                    negative: Math.floor(Math.random() * 10),
                    neutral: Math.floor(Math.random() * 10),
                    positive: Math.floor(Math.random() * 10),
                }
            }
        });
        return right({
            best: client,
            worst: client,
        });
    }

    getClientsList = async (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => {
        await Testing.sleeper(2000);
        const clients = Array.from({ length: Math.floor(Math.random() * 10) }, (_, i) => {
            const client: MasterItemEntity = {
                id: `id ${i}`,
                name: `name ${i}`,
            }
            return client;
        });
        return right(clients);
    }

    getClientDetails = async (agentId: string, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ClientAnalysisEntity>> => {
        await Testing.sleeper(1000);
        const text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eleifend vestibulum mauris, ac convallis neque euismod vel. Quisque pulvinar congue velit vel rhoncus. Morbi rutrum sapien congue bibendum lacinia. Sed lorem erat, feugiat eu pharetra et, hendrerit sit amet nunc. Nunc eget lectus porta, suscipit tellus eget, scelerisque ipsum. Nunc ut dui id est semper dapibus ac non leo. Donec et odio sit amet leo tristique hendrerit. Nam vel dolor malesuada, dapibus ipsum in, cursus tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas dictum, metus in varius condimentum, quam lacus tincidunt sapien, ac congue nisi urna sit amet diam. Aenean vulputate tempus mauris nec blandit. Sed augue risus, iaculis et erat quis, maximus molestie erat. \n" +
            "Suspendisse eu metus egestas, condimentum ligula eu, tincidunt diam. In sed rutrum velit, vitae fermentum arcu. Donec non urna libero. Donec vitae erat lacinia tellus maximus pretium at sit amet neque. Phasellus fermentum nulla eu velit accumsan, ac congue justo dignissim. Ut auctor nunc in iaculis interdum. Donec id consectetur dolor. Nulla consectetur rhoncus ex vel efficitur. Mauris et nibh nec augue malesuada vestibulum. \n" +
            "Mauris interdum placerat ante eget eleifend. Cras ante massa, aliquam ut est id, pharetra tincidunt nibh. Nullam blandit arcu mi, sit amet pellentesque ante placerat nec. In at blandit tellus. Fusce metus lacus, egestas ut dictum a, pulvinar a mauris. Curabitur pulvinar ut mauris ultricies laoreet. Proin tempus risus ut justo elementum, sit amet lacinia tellus convallis. Vivamus sit amet diam mi. Aliquam erat volutpat. Quisque aliquet ipsum eget tristique vulputate. Nunc iaculis tempus magna eu malesuada. Integer fermentum, velit sed dignissim fringilla, elit dui volutpat dolor, et viverra magna diam egestas odio. Vestibulum pharetra finibus dapibus. Nullam vitae ullamcorper sapien. Curabitur tempor tempor velit. Morbi ullamcorper, risus at pulvinar laoreet, purus neque sodales risus, at iaculis ante mauris sit amet lorem. \n" +
            "Fusce at mollis libero. Ut ac mi vel lectus cursus semper ac a orci. Nulla et libero laoreet, tristique turpis vel, imperdiet massa. Aenean a convallis purus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus facilisis commodo nibh, vitae molestie sapien pellentesque vitae. Donec ac vehicula tellus. Aliquam sodales justo turpis, sodales fringilla elit rutrum ac. Pellentesque ullamcorper, augue vitae fringilla varius, dui purus vulputate nisl, non accumsan dui purus in velit. Pellentesque placerat, justo nec congue imperdiet, nibh orci rhoncus mauris, id hendrerit quam lacus nec nunc. \n" +
            "Mauris interdum placerat ante eget eleifend. Cras ante massa, aliquam ut est id, pharetra tincidunt nibh. Nullam blandit arcu mi, sit amet pellentesque ante placerat nec. In at blandit tellus. Fusce metus lacus, egestas ut dictum a, pulvinar a mauris. Curabitur pulvinar ut mauris ultricies laoreet. Proin tempus risus ut justo elementum, sit amet lacinia tellus convallis. Vivamus sit amet diam mi. Aliquam erat volutpat. Quisque aliquet ipsum eget tristique vulputate. Nunc iaculis tempus magna eu malesuada. Integer fermentum, velit sed dignissim fringilla, elit dui volutpat dolor, et viverra magna diam egestas odio. Vestibulum pharetra finibus dapibus. Nullam vitae ullamcorper sapien. Curabitur tempor tempor velit. Morbi ullamcorper, risus at pulvinar laoreet, purus neque sodales risus, at iaculis ante mauris sit amet lorem. \n" +
            "Fusce at mollis libero. Ut ac mi vel lectus cursus semper ac a orci. Nulla et libero laoreet, tristique turpis vel, imperdiet massa. Aenean a convallis purus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus facilisis commodo nibh, vitae molestie sapien pellentesque vitae. Donec ac vehicula tellus. Aliquam sodales justo turpis, sodales fringilla elit rutrum ac. Pellentesque ullamcorper, augue vitae fringilla varius, dui purus vulputate nisl, non accumsan dui purus in velit. Pellentesque placerat, justo nec congue imperdiet, nibh orci rhoncus mauris, id hendrerit quam lacus nec nunc. \n" +
            "Mauris interdum placerat ante eget eleifend. Cras ante massa, aliquam ut est id, pharetra tincidunt nibh. Nullam blandit arcu mi, sit amet pellentesque ante placerat nec. In at blandit tellus. Fusce metus lacus, egestas ut dictum a, pulvinar a mauris. Curabitur pulvinar ut mauris ultricies laoreet. Proin tempus risus ut justo elementum, sit amet lacinia tellus convallis. Vivamus sit amet diam mi. Aliquam erat volutpat. Quisque aliquet ipsum eget tristique vulputate. Nunc iaculis tempus magna eu malesuada. Integer fermentum, velit sed dignissim fringilla, elit dui volutpat dolor, et viverra magna diam egestas odio. Vestibulum pharetra finibus dapibus. Nullam vitae ullamcorper sapien. Curabitur tempor tempor velit. Morbi ullamcorper, risus at pulvinar laoreet, purus neque sodales risus, at iaculis ante mauris sit amet lorem. \n" +
            "Fusce at mollis libero. Ut ac mi vel lectus cursus semper ac a orci. Nulla et libero laoreet, tristique turpis vel, imperdiet massa. Aenean a convallis purus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus facilisis commodo nibh, vitae molestie sapien pellentesque vitae. Donec ac vehicula tellus. Aliquam sodales justo turpis, sodales fringilla elit rutrum ac. Pellentesque ullamcorper, augue vitae fringilla varius, dui purus vulputate nisl, non accumsan dui purus in velit. Pellentesque placerat, justo nec congue imperdiet, nibh orci rhoncus mauris, id hendrerit quam lacus nec nunc. \n" +
            "Aliquam a est pellentesque nisi pretium rutrum quis non libero. Quisque vitae sapien eu est ultrices interdum non a lorem. Vivamus pellentesque, neque id blandit lacinia, massa leo vestibulum justo, in fermentum nunc velit vitae arcu. Nulla tincidunt lorem nisi. Sed nisl ex, tristique in pharetra a, aliquet sed lacus. Vivamus sed egestas neque. Cras turpis sem, sagittis sit amet sollicitudin vulputate, pretium eget enim. Suspendisse pretium dictum dolor vulputate euismod. Duis in urna dolor."

        return right({
            agentId: agentId,
            agentName: "Agent Name",
            audios: [
                {
                    url: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
                    name: "2024-12-23:20:23:23_joselito_123123123.mp3",
                    score: 53,
                    jobId: "123",
                },
                {
                    url: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
                    name: "2024-12-23:20:23:23_joselito_123123123.mp3",
                    score: 10,
                    jobId: "123",
                },
                {
                    url: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
                    name: "2024-12-23:20:23:23_joselito_123123123.mp3",
                    score: 80,
                    jobId: "123",
                },
            ],
            calification: 5,
            content: text,
            comments: Array.from({ length: 5 }, (_, i) => {
                let uss = { ...fakeUser };
                return {
                    ...testComment,
                    id: i.toString(),
                    user: {
                        ...uss,
                        id: i % 2 == 0 ? "1" : "321",
                    }
                };
            }),
        });
    }

    addComment = async (_: { employeeID: string, comment: string }): Promise<Either<ErrorEntity, QACommentEntity>> => {
        await Testing.sleeper(2000);
        return right({
            ...testComment,
            id: (Math.random() * 100).toString(),
            comment: _.comment,
        });
    }

}