import { FC, useContext, useEffect, useState } from "react";
import CreateQuestionComponentProps from "./create_question.component.props";
import { useForm } from "react-hook-form";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../../../providers/localization/LocalizationContext";
import QAAgentEntity from "../../../../../../domain/entities/qa_agent.entity";
import QAQuestionEntity from "../../../../../../domain/entities/qa_question.entity";
import KeyWordLocalization from "../../../../../utils/KeyWordLocalization";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../../../utils/Validators";
import ButtonComponent from "../../../../../components/button/button.component";
import ModalsContext from "../../../../../providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";

const CreateQuestionComponent: FC<CreateQuestionComponentProps> = ({ onSave, question, categoryId }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [loading, setLoading] = useState(false);
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const _handleSave = async (data: any) => {
        const newQuestion: QAQuestionEntity = {
            id: question?.id ?? "",
            description: data[KeyWordLocalization.CreateQuestionComponentDescription],
            points: parseInt(data[KeyWordLocalization.CreateQuestionComponentPoints]),
            title: data[KeyWordLocalization.CreateQuestionComponentTitle],
            categoryId: question?.categoryId ?? categoryId ?? "",
        }
        setLoading(true);
        await onSave(newQuestion);
        setLoading(false);
    }

    const _handleLoad = () => {
        if (question) {
            setValue(KeyWordLocalization.CreateQuestionComponentDescription, question.description);
            setValue(KeyWordLocalization.CreateQuestionComponentPoints, question.points);
            setValue(KeyWordLocalization.CreateQuestionComponentTitle, question.title);
        }
    }

    const _handleCancel = () => closeModalCustom();

    useEffect(() => {
        _handleLoad();
    }, [question]);

    return <div className="create_question_component">
        <div className="container">
            <form onSubmit={handleSubmit(_handleSave)} className="row">
                <div className={`form-group col-md-8 my-2 ${errors[KeyWordLocalization.CreateQuestionComponentTitle] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateQuestionComponentTitle)}</label>
                    <input type="text" className="form-control" disabled={loading} placeholder={i18n(KeyWordLocalization.CreateQuestionComponentTitle)} {...register(KeyWordLocalization.CreateQuestionComponentTitle, Validators({
                        required: true,
                        maxLength: 100,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateQuestionComponentTitle} />
                </div>
                <div className={`form-group col-md-4 my-2 ${errors[KeyWordLocalization.CreateQuestionComponentPoints] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateQuestionComponentPoints)}</label>
                    <input type="number" step={1}  disabled={loading} className="form-control" placeholder={i18n(KeyWordLocalization.CreateQuestionComponentPoints)} {...register(KeyWordLocalization.CreateQuestionComponentPoints, Validators({
                        required: true,
                        minValue: 1,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateQuestionComponentPoints} />
                </div>
                <div className={`form-group col-md-12 my-2 ${errors[KeyWordLocalization.CreateQuestionComponentDescription] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateQuestionComponentDescription)}</label>
                    <textarea className="form-control" rows={10} disabled={loading} placeholder={i18n(KeyWordLocalization.CreateQuestionComponentDescription)} {...register(KeyWordLocalization.CreateQuestionComponentDescription, Validators({
                        required: true,
                        minLength: 5,
                        maxLength: 255,
                    }))} />
                    <aside className={`w-100 text-end text_small ${watch((KeyWordLocalization.CreateQuestionComponentDescription) ?? "")?.length > 255 && "error"}`}>{watch((KeyWordLocalization.CreateQuestionComponentDescription) ?? "")?.length ?? 0}/255</aside>
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateQuestionComponentDescription} />
                </div>
                <div className="px-2 mt-2 container_buttons">
                    <ButtonComponent type="button" onClick={_handleCancel} design="primaryOutline" text={i18n(KeyWordLocalization.CreateQuestionComponentCancel)} />
                    <ButtonComponent type="submit" isLoading={loading} text={question != null ? i18n(KeyWordLocalization.CreateQuestionComponentSave) : i18n(KeyWordLocalization.CreateQuestionComponentCreate)} />
                </div>
            </form>
        </div>
    </div>
}

export default CreateQuestionComponent;