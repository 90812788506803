import { id, injectable } from "inversify";
import QARepository from "../../../domain/repositories/qa.repository";
import { Either, right, left } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import QAAgentEntity from "../../../domain/entities/qa_agent.entity";
import QAResultEntity from "../../../domain/entities/qa_result.entity";
import Testing from "../../../presentation/utils/Testing";
import QAQuestionEntity from "../../../domain/entities/qa_question.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import QACategoryEntity from "../../../domain/entities/qa_category.entity";


const fakeAgent = new QAAgentEntity({
    id: "123",
    name: "Test Agent",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce imperdiet egestas eros sit amet aliquet. Nulla porttitor fringilla ultricies. In hendrerit ultricies velit, sed porttitor nibh pulvinar ac. Nullam quis enim nibh. Cras urna lectus, pulvinar vel efficitur nec, finibus id neque. Ut pulvinar consequat",
    // categories: [],
    categories: Array.from({ length: 4 }, (_, i) => {
        const category = new QACategoryEntity({
            id: "123",
            title: "Opening",
            agentId: "123",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce imperdiet egestas eros sit amet aliquet. Nulla porttitor fringilla ultricies. In hendrerit ultricies velit, sed porttitor nibh pulvinar ac. Nullam quis enim nibh. Cras urna lectus, pulvinar vel efficitur nec, finibus id neque. Ut pulvinar consequat",
            questions: Array.from({ length: 4 }, (_, i) => ({
                id: i.toString(),
                title: "Opening",
                description: "Was the agent's greeting appropriate?",
                points: 10,
                categoryId: "2",
                response: {
                    id: "response-123",
                    points: 9,
                    short: "Yes",
                    justification: "The agent greeted the customer warmly and thanked them for their inquiry, which is a friendly and appropriate way to start the conversation.",
                },

            }))
        });
        return category;
    }),
    clientId: "123",
    opTypeId: "123",
});

@injectable()
class QARepositoryFake implements QARepository {
    updateCategory = async (qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>> => {
        await Testing.sleeper(1000);
        return right(qaCategory);
    }
    addCategory = async (qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>> => {
        await Testing.sleeper(1000);
        const randomId = Math.random() * 10000;
        qaCategory.id = randomId.toString();
        await Testing.sleeper(1000);
        return right(qaCategory);
    }
    deleteCategory = async (id: string): Promise<Either<ErrorEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    updateAgent = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        return right(agent);
    }
    deleteQuestionFromAgent = async (questionId: string): Promise<Either<ErrorEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    updateQuestionFromAgent = async (question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        await Testing.sleeper(1000);
        return right(question);
    }
    getAgentById = async (id: string): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        // return left({code: "notFound"});
        const copyAgent = { ...fakeAgent };
        copyAgent.categories = [];
        // copyAgent.categories.forEach((category, index) => {
        //     category.id = `${category.id}-${index}`;
        //     category.questions.forEach((question, index) => {
        //         question.id = `${question.id}-${index}`;
        //     });
        // });
        return right(fakeAgent);
    }
    addQuestionToAgent = async (_:{ category: QACategoryEntity, question: QAQuestionEntity }): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        await Testing.sleeper(1000);
        _.question.response = {
            id: "response-123",
            points: 9,
            short: "Yes",
            justification: "The agent greeted the customer warmly and thanked them for their inquiry, which is a friendly and appropriate way to start the conversation.",
        };

        return right(_.question);
    }
    uploadAudioForTranscription = async (audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>> => {
        await Testing.sleeper(1000);
        return right("https://samplelib.com/lib/preview/mp3/sample-3s.mp3");
    }
    getAudioResults = async (jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => {
        await Testing.sleeper(1000);
        return right(new QAResultEntity({
            transcription: {
                conversation: [
                    {
                        senderIsClient: false,
                        time: Math.random() * 4800,
                        transcription: "Hello, Customer service",
                    },
                    {
                        senderIsClient: true,
                        time: Math.random() * 4800,
                        transcription: "Hello I need bla bla",
                    },
                    {
                        senderIsClient: false,
                        time: Math.random() * 4800,
                        transcription: "Hello, Customer service",
                    },
                    {
                        senderIsClient: true,
                        time: Math.random() * 4800,
                        transcription: "Hello I need bla bla",
                    },
                    {
                        senderIsClient: false,
                        time: Math.random() * 4800,
                        transcription: "Hello, Customer service",
                    },
                    {
                        senderIsClient: true,
                        time: Math.random() * 4800,
                        transcription: "Hello I need bla bla",
                    },
                    {
                        senderIsClient: false,
                        time: Math.random() * 4800,
                        transcription: "Hello, Customer service",
                    },
                    {
                        senderIsClient: true,
                        time: Math.random() * 4800,
                        transcription: "Hello I need bla bla",
                    },
                ]
            },
            id: "123",
            audioUrl: "https://samplelib.com/lib/preview/mp3/sample-3s.mp33",
            categories: fakeAgent.categories.map((category, index) => ({
                ...category,
                id: `${category.id}-${index}`,
            })),
        }));
    }
    createAgent = async (_: {
        name: string,
        description: string,
    }): Promise<Either<ErrorEntity, QAAgentEntity>> => {
        await Testing.sleeper(1000);
        return right(fakeAgent);
    }
    searchAgents = async (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => {
        await Testing.sleeper(1000);
        const limitOfPages = 3;
        if (page > limitOfPages) {
            return right({
                total: limitOfPages * itemsPerPage,
                data: [],
                itemsPerPage,
                page,
                pages: limitOfPages,
            });
        }
        const arrayOfFakes = Array.from({ length: page == limitOfPages ? itemsPerPage / 2 : itemsPerPage }, (_, i) => fakeAgent);
        return right({
            total: (limitOfPages * itemsPerPage) - (itemsPerPage / 2),
            data: arrayOfFakes,
            itemsPerPage,
            page,
            pages: limitOfPages,
        });
    }
    deleteAgent = async (id: string): Promise<Either<ErrorEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
}

export default QARepositoryFake;