import { injectable } from "inversify";
import HumanAgentRepository from "../../../domain/repositories/human_agent.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import HumanAgentEntity, { HumanAgentStatus } from "../../../domain/entities/human_agent.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import searchHumanAgentsApiImpl from "./api/search_human_agents.api.impl";
import getHumanAgentByIdApiImpl from "./api/get_human_agent_by_id.api.impl";
import createHumanAgentApiImpl from "./api/create_human_agent.api.impl";
import updateHumanAgentApiImpl from "./api/update_human_agent.api.impl";
import deleteHumanAgentApiImpl from "./api/delete_human_agent.api.impl";

@injectable()
class HumanAgentRepositoryImpl implements HumanAgentRepository {
    search = (_:{query?: string, status?: HumanAgentStatus, page: number, itemsPerPage: number}): Promise<Either<ErrorEntity, SearchResultEntity<HumanAgentEntity>>> => 
        searchHumanAgentsApiImpl(_);

    delete = (id: string): Promise<Either<ErrorEntity, void>> => 
        deleteHumanAgentApiImpl(id);

    getById = (id: string): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        getHumanAgentByIdApiImpl(id);

    create = (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        createHumanAgentApiImpl(agent);

    update = (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        updateHumanAgentApiImpl(agent);
}

export default HumanAgentRepositoryImpl;