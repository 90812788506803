
import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import SearchResultEntity from "../../../../domain/entities/search_result.entity";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAAgentDto from "../../../dto/qa_agent.dto";

const SearchAgentsApiImpl = async (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => {
    try {
        let relativeUrl: string = `/agent?page_number=${page}&page_size=${itemsPerPage}&keyword=${query??""}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        const parsed = response.content.map((item: any) => QAAgentDto.fromJSON(item));
        return right({
            data: parsed,
            itemsPerPage: itemsPerPage,
            page: page,
            pages: response.total_pages,
            total: response.total_elements
        })
    } catch (e) {
        return left({ code: "Error" });
    }
}

export default SearchAgentsApiImpl;
