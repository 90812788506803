import { injectable } from "inversify";
import DashboardRepository from "../../repositories/dashboard.repository";
import ErrorEntity from "../../entities/error.entity";
import { Either } from "fp-ts/lib/Either";
import ClientAnalysisEntity from "../../entities/client_analysis.entity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class GetAgentAnalysisUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (clientId: string, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ClientAnalysisEntity>> => {
        return this.dashboardRepository.getClientDetails(clientId, startDate, endDate);
    }
}

export default GetAgentAnalysisUseCase;

export const GetAgentAnalysisUseCaseName = "GetAgentAnalysisUseCase";