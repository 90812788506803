import { injectable } from "inversify";
import HumanAgentRepository from "../../repositories/human_agent.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import SearchResultEntity from "../../entities/search_result.entity";
import HumanAgentEntity, { HumanAgentStatus } from "../../entities/human_agent.entity";

interface Props {
    humanAgentRepository: HumanAgentRepository;
}

@injectable()
class SearchHumanAgentsUseCase {
    private humanAgentRepository: HumanAgentRepository;

    constructor({ humanAgentRepository }: Props) {
        this.humanAgentRepository = humanAgentRepository;
    }

    public call = async (_:{query?: string, status?: HumanAgentStatus, page: number, itemsPerPage: number}): Promise<Either<ErrorEntity, SearchResultEntity<HumanAgentEntity>>> => {
        return this.humanAgentRepository.search(_);
    }
}

export default SearchHumanAgentsUseCase;

export const SearchHumanAgentsUseCaseName = "SearchHumanAgentsUseCase";