import { ChangeEvent, FC, useContext, useState } from "react";
import DeleteModalComponentProps from "./DeleteModalComponentProps";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import './DeleteModalComponentStyles.scss';
import ModalsContext from "../../ModalsContext";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";
import LocalizationContext from "../../../localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import ButtonComponent from "../../../../components/button/button.component";

const DeleteModalComponent: FC<DeleteModalComponentProps> = ({ }) => {
    const { closeModalDelete, deleteModal } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    const [inputConfirmation, setInputConfirmation] = useState('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputConfirmation(event.target.value);
    }

    const handleSave = async () => {
        if (i18n(KeyWordLocalization.DeleteModalComponentConfirm) == inputConfirmation) {
            setLoading(true);
            await deleteModal.onConfirm();
            setInputConfirmation('');
            setConfirmed(false);
            setLoading(false);
        } else setConfirmed(true);
    }

    const handleCancel = () => {
        closeModalDelete();
        setInputConfirmation('');
        setConfirmed(false);
    }

    if (deleteModal === undefined) return <></>

    return <div className="delete_modal_component">
        <div className="bg_modal" onClick={handleCancel}></div>
        <div className="modal_container">
            <div className="delete_modal_component_header">
                <div>{deleteModal.title}</div>
                <div onClick={handleCancel}>
                    <span className="material-symbols-outlined">close</span>
                </div>

            </div>
            <div className="delete_modal_message">{i18n(KeyWordLocalization.DeleteModalComponentMessage)}</div>
            <div className="delete_modal_component_content">{deleteModal.content}</div>
            <input className="delete_modal_component_content_input" onChange={(e) => handleInputChange(e)} value={inputConfirmation} type="text" name="" id="" placeholder={i18n(KeyWordLocalization.DeleteModalComponentConfirm)} />
            {confirmed && <div className="px-2 pb-2">
                <aside className="error_input">{i18n(KeyWordLocalization.DeleteModalComponentError)}</aside>
            </div>}
            <div className="delete_modal_component_footer">
                <ButtonComponent className="me-2" onClick={handleCancel} design="dangerOutline" type="button" text={i18n(KeyWordLocalization.DeleteModalComponentCancel)} icon={
                    <span className="material-symbols-outlined">close</span>
                } />
                <ButtonComponent onClick={handleSave} isLoading={loading} design="danger" type="button" text={i18n(KeyWordLocalization.DeleteModalComponentDelete)} icon={
                    <span className="material-symbols-outlined">done</span>
                } />
            </div>

        </div>
    </div>
}

export default DeleteModalComponent;
