import "./table_satisfaction.component.style.scss";
import { FC, useContext } from "react";
import TableSatisfactionComponentProps from "./table_satisfaction.component.props";
import ButtonComponent from "../../../../components/button/button.component";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import { Link } from "react-router-dom";
import routes from "../../../../routes/Routes";

const TableSatisfactionComponent: FC<TableSatisfactionComponentProps> = ({ colors, data, title, openAgentDetails, showHeader = true }) => {
    const handleClickDetails = async (clientId: string, clientName: string) => openAgentDetails?.(clientId, clientName);
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="table_satisfaction_component">
        {showHeader &&
            <div className="w-100 d-flex">
                <div className="flex-grow-1 text_ellipsis">
                    <h5>{title}</h5>
                </div>
                <Link to={routes.ranking_human_agents.relativePathWithParams()}>{i18n(KeyWordLocalization.TableSatisfactionComponentSeeAll)}</Link>
            </div>}
        <div className="w-100 overflow-x-auto">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitlRankingPosition)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitleName)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitleScore)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTitleSentiments)}</th>
                        <th>{i18n(KeyWordLocalization.TableSatisfactionComponentTotalEvaluatedCalls)}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((client, index) => <tr key={index}>
                        <td><div className="py-2">{client.rankingPosition}</div></td>
                        <td><div className="py-2">{client.name}</div></td>
                        <td> <div className="py-2">
                            {client.score?.toFixed(2)}</div></td>
                        <td>
                            <div className="d-flex">
                                <div className="mr-2 sentiment_conent" style={{ background: colors[0] }}>
                                    <span className="material-symbols-outlined">
                                        sentiment_dissatisfied
                                    </span>
                                    <span>{client.sentiments.negative}</span>
                                </div>
                                <div className="mr-2 sentiment_conent" style={{ background: colors[1] }}>
                                    <span className="material-symbols-outlined">
                                        sentiment_neutral
                                    </span>
                                    <span>{client.sentiments.neutral}</span>
                                </div>
                                <div className="mr-2 sentiment_conent" style={{ background: colors[2] }}>
                                    <span className="material-symbols-outlined">
                                        sentiment_satisfied
                                    </span>
                                    <span>{client.sentiments.positive}</span>
                                </div>
                            </div>
                        </td>
                        <td> <div className="py-2">
                            {client.totalEvaluatedCalls}</div></td>
                        <td>
                            <ButtonComponent onClick={() => handleClickDetails(client.id, client.name)} icon={
                                <span className="material-symbols-outlined">
                                    visibility
                                </span>
                            } />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}

export default TableSatisfactionComponent;