const toServer = (date: Date): string => {
    return date.toISOString().split('.')[0];
}

const toServerWithHour = (date: Date): string => {
    //conserv hour when converting to date
    const yyyy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const mmS = mm < 10 ? '0' + mm : mm;
    const dd = date.getUTCDate();
    const ddS = dd < 10 ? '0' + dd : dd;
    const hh = date.getHours();
    const hhS = hh < 10 ? '0' + hh : hh;
    const min = date.getMinutes();
    const minS = min < 10 ? '0' + min : min;
    const sec = date.getSeconds();
    const secS = sec < 10 ? '0' + sec : sec;
    return `${yyyy}-${mmS}-${ddS}T${hhS}:${minS}:${secS}`;

}

const fromServerDate = (date: string): Date => {
    //conserv hour when converting to date
    const yyyy = date.split('-')[0];
    const mm = date.split('-')[1];
    const dd = date.split('-')[2];
    return new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd), 0, 0, 0, 0);
}


export default{
    toServer,
    fromServerDate,
    toServerWithHour,
}