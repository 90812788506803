import React, { useEffect } from 'react';
import LocalizationEntity from '../../../domain/entities/localization.entity';
import ProviderProps from '../../../domain/providers/ProviderProps';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import { injectable } from 'inversify';
import LocalizationProvider from '../../../domain/providers/localization/LocalizationProvider';
import LocalizationContext, { defaultLocalizationContext } from './LocalizationContext';

let _Actions: LocalizationContextType = defaultLocalizationContext;
const _Provider: React.FC<ProviderProps> = ({ children }) => {
    const [localization, setLocalization] = React.useState<LocalizationEntity>(defaultLocalizationContext.localization);
    const [localizations, setLocalizations] = React.useState<LocalizationEntity[]>(defaultLocalizationContext.localizations);
    const i18n = (keyword: string, values: any = null) => {
        if (values == null) return localization?.dictionary[keyword] ?? keyword;
        let result = localization?.dictionary[keyword] ?? keyword;
        Object.keys(values).forEach((key) => {
            result = result.replace(`{${key}}`, values[key]);
        }
        );
        return result;
    };

    _Actions.localization = localization;
    _Actions.setLocalization = setLocalization;
    _Actions.localizations = localizations;
    _Actions.setLocalizations = setLocalizations;
    _Actions.i18n = i18n;

    return <LocalizationContext.Provider value={{ i18n, localization, setLocalization, localizations, setLocalizations }}>
        {children}
    </LocalizationContext.Provider>;
}

@injectable()
class LocalizationProviderImpl implements LocalizationProvider {
    public context = LocalizationContext;
    public Provider = _Provider;
    public Actions = _Actions;
}

export default new LocalizationProviderImpl();