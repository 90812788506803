import { injectable } from "inversify";
import LocalizationEntity from "../../../domain/entities/localization.entity";
import ChangeLanguageApiImpl from "./api/impl/ChangeLanguageApiImpl";
import GetCurrentLanguageApiImpl from "./api/impl/GetCurrentLanguageApiImpl";
import GetAvailableLanguagesApiImpl from "./api/impl/GetAvailableLanguagesApiImpl";
import LocalizationRepository from "../../../domain/repositories/localization.repository";

@injectable()
class LocalizationRepositoryFake implements LocalizationRepository {
    KEY_LANGUAGE = 'language';
    changeLanguage = (language: LocalizationEntity): void => ChangeLanguageApiImpl(language, this.KEY_LANGUAGE);
    getLanguage = (): Promise<LocalizationEntity> => GetCurrentLanguageApiImpl(this.KEY_LANGUAGE);
    getAvailableLanguages = (): Promise<LocalizationEntity[]> => GetAvailableLanguagesApiImpl();
}

export default LocalizationRepositoryFake;