import { FC, useContext } from "react";
import NotFoundComponentProps from "./NotFoundComponentProps";
import LocalizationContext from "../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../utils/KeyWordLocalization";

const NotFoundComponent:FC<NotFoundComponentProps> = ({}) => {
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    return <div className="not_found_component">
        <div className="container">
            <div className="d-flex flex-column align-items-center my-5">
                <h3>{i18n(KeyWordLocalization.NotFoundComponentTitle)}</h3>
                <h5>{i18n(KeyWordLocalization.NotFoundComponentSubtitle)}</h5>
            </div>
        </div>
    </div>
}

export default NotFoundComponent;