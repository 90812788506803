import QACategoryEntity from "../../domain/entities/qa_category.entity";
import QAQuestionDto from "./qa_question.dto";

const fromJSON = (json: any): QACategoryEntity => {
    return new QACategoryEntity({
        id: json['id']?.toString() ?? '',
        title: json['group'] ?? '',
        description: json['description'] ?? '', //TODO CHECK
        agentId: json['agent_id']?.toString() ?? '',
        questions: json['questions'] != null ? json['questions'].map((item: any)=>QAQuestionDto.fromJSON(item)) : [],
    })
}

const QACategoryDto = {
    fromJSON,
}

export default QACategoryDto;