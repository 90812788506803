import "./agent_analysis.component.style.scss";
import { FC, useContext, useEffect, useRef, useState } from "react";
import AgentAnalysisComponentProps from "./agent_analysis.component.props";
import LoadingComponent from "../../../../components/LoadingComponent/loading.component";
import ClientAnalysisEntity from "../../../../../domain/entities/client_analysis.entity";
import NotFoundComponent from "../../../../components/notFound/NotFoundComponent";
import di from "../../../../../dependency_injection";
import GetAgentAnalysisUseCase, { GetAgentAnalysisUseCaseName } from "../../../../../domain/use_cases/dashboard/get_agent_analysis_details.use_case";
import { isLeft } from "fp-ts/lib/Either";
import ModalsContext from "../../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import AccordionComponent from "../../../../components/accordion/accordion.component";
import UserContextType from "../../../../../domain/providers/user/UserContextType";
import UserContext from "../../../../providers/user/UserContext";
import ButtonComponent from "../../../../components/button/button.component";
import { useForm } from "react-hook-form";
import AddCommentToAnalysisUseCase, { AddCommentToAnalysisUseCaseName } from "../../../../../domain/use_cases/dashboard/add_comment_to_analysis.use_case";
import DatesUtils from "../../../../utils/DatesUtils";
import ResultIAAudioComponent from "../../../ai_agent/detailed/components/result_question/result_ia_audio.component";
import GetAudioResultUseCase, { GetAudioResultUseCaseName } from "../../../../../domain/use_cases/qa/get_audio_result.use_case";

const AgentAnalysisComponent: FC<AgentAnalysisComponentProps> = ({ agentId, startDate, endDate }) => {
    const [analysisResult, setAnalysisResult] = useState<ClientAnalysisEntity | null | undefined>(undefined);
    const [commentValue, setCommentValue] = useState<string>("");
    const commentInputRef = useRef<HTMLTextAreaElement>(null);
    const refContentCard = useRef<HTMLDivElement>(null);
    const [loadingComment, setLoadingComment] = useState<boolean>(false);
    const [openSection, setOpenSection] = useState<string>("result");

    const { addToast, closeModalCustom, pushModalCustom, popModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { user } = useContext(UserContext) as UserContextType;

    const _handleOpenVisibility = async (jobId: string) => {
        // pushModalCustom("lg", i18n(KeyWordLocalization.AudioZoneComponentResultTitle), <ResultIAAudioComponent result={result} />);
        pushModalCustom("sm", "", <LoadingComponent />, { hideOnTap: false });
        const response = await di.get<GetAudioResultUseCase>(GetAudioResultUseCaseName).call(jobId);
        popModalCustom();
        if (isLeft(response)) {
            addToast(i18n(response.left.code ?? KeyWordLocalization.AudioZoneComponentErrorGettingData), "error", undefined);
            pushModalCustom("sm", i18n(KeyWordLocalization.AudioZoneComponentResultTitle), <NotFoundComponent />);
            return;
        }
        pushModalCustom("lg", i18n(KeyWordLocalization.AudioZoneComponentResultTitle), <ResultIAAudioComponent result={response.right} />);
    }

    const _handleLoadAnalysis = async () => {
        setAnalysisResult(undefined);
        const response = await di.get<GetAgentAnalysisUseCase>(GetAgentAnalysisUseCaseName).call(agentId, startDate, endDate);
        if (isLeft(response)) {
            setAnalysisResult(null);
            addToast(i18n(response.left.code ?? KeyWordLocalization.AgentAnalysisComponentErrorGettingData), "error", undefined);
            return;
        }
        setAnalysisResult(response.right);
    }

    const _handleAddComment = async () => {
        setLoadingComment(true);
        if (commentInputRef.current == null) return;
        const value = commentInputRef.current.value;
        if (value === "") return;
        const response = await di.get<AddCommentToAnalysisUseCase>(AddCommentToAnalysisUseCaseName).call({
            employeeID: agentId,
            comment: value,
        });
        setLoadingComment(false);
        if (isLeft(response)) {
            addToast(i18n(response.left.code ?? KeyWordLocalization.AgentAnalysisComponentErrorAddingComment), "error", undefined);
            return;
        }
        setCommentValue("");
        const copyAnalysis = { ...analysisResult! };
        copyAnalysis.comments.push(response.right);
        setAnalysisResult(copyAnalysis);
        await new Promise((resolve) => setTimeout(resolve, 100));
        refContentCard.current?.scrollTo(0, refContentCard.current?.scrollHeight);

        addToast(i18n(KeyWordLocalization.AgentAnalysisComponentCommentAdded), "success", undefined);
    }

    useEffect(() => {
        _handleLoadAnalysis();
    }, [agentId, startDate, endDate]);

    if (analysisResult === undefined) return <div className="modal_content lg">
        <LoadingComponent />
    </div>
    else if (analysisResult === null) return <div className="modal_content">
        <NotFoundComponent />
    </div>
    return <div className="agent_analysis_component">
        <div className={`card_ia_result analysis_card ${openSection == "result" && "open"}`} onClick={() => setOpenSection("result")}>
            <div className="title d-flex">
                <div className="flex-grow-1">
                    {i18n(KeyWordLocalization.AgentAnalysisComponentAICoachTitle)}
                </div>
                <div className="icon" onClick={() => closeModalCustom()}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>
            <div className="content_card">
                <div className="d-flex flex-wrap">
                    <div className="chip ms-0">
                        {i18n(KeyWordLocalization.AgentEntityId)}: <strong>{analysisResult.agentId}</strong>
                    </div>
                    <div className="chip">
                        {i18n(KeyWordLocalization.AgentEntityName)}: <strong>{analysisResult.agentName}</strong>
                    </div>
                    <div className="chip">
                        {i18n(KeyWordLocalization.QuestionEntityAvgScore)}: <strong>{analysisResult.calification}</strong>
                    </div>
                </div>
                <p>{analysisResult.content}</p>
            </div>
        </div>
        <div className={`card_comments_list analysis_card ${openSection == "comment" && "open"}`} onClick={() => setOpenSection("comment")}>
            <div className="title">
                {i18n(KeyWordLocalization.AgentAnalysisComponentCommentsTitle)}
            </div>
            <div className="content_card">
                <div className="comment-card" ref={refContentCard}>
                    <div className="comment-list">
                        {analysisResult.comments.map((comment, index) => <div className={`comment-container ${comment.user.id == user?.id ? "my-comment" : "other-comment"}`} key={index}>
                            {comment.user.id != user?.id && <img src={comment.user.photo} className="image-user" alt="" />}
                            <div className="comment_content">
                                {comment.user.id != user?.id && <strong>{comment.user.name}</strong>}
                                <p>{comment.comment}</p>
                                <div className="text_light">{comment.date.toDateString()}</div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <form className="d-flex w-100 align-items-start mt-1">
                    <div className="flex-grow-1 me-2">
                        <textarea
                            className="w-100 form-control"
                            maxLength={255}
                            disabled={loadingComment}
                            placeholder={i18n(KeyWordLocalization.AgentAnalysisComponentCommentPlaceholder)}
                            ref={commentInputRef}
                            value={commentValue}
                            onChange={(e) => setCommentValue(e.target.value)}
                        ></textarea>
                        <aside className={`w-100 text-end text_small ${commentValue.length > 255 && "error"}`}>
                            {commentValue.length}/255
                        </aside>
                    </div>
                    <ButtonComponent isLoading={loadingComment} className="circle_button" onClick={_handleAddComment} text="" icon={
                        <span className="material-symbols-outlined">
                            send
                        </span>
                    } />
                </form>
            </div>
        </div>
        <div className={`card_ia_result analysis_card ${openSection == "audios" && "open"}`} onClick={() => setOpenSection("audios")}>
            <div className="title">
                {i18n(KeyWordLocalization.AgentAnalysisComponentAudiosTitle)}
            </div>
            <div className="content_card">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{i18n(KeyWordLocalization.AgentAnalysisComponentAudiosTableTitle)}</th>
                            <th>{i18n(KeyWordLocalization.AgentAnalysisComponentAudiosTableScore)}</th>
                            <th>{i18n(KeyWordLocalization.AgentAnalysisComponentAudiosTableView)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {analysisResult.audios.map((audio, index) => <tr key={index}>
                            <td>
                                <a href={audio.url} target="_blank" rel="noreferrer">{audio.name}</a>
                            </td>
                            <td>
                                {audio.score}
                            </td>
                            <td>
                                <ButtonComponent text="" onClick={() => _handleOpenVisibility(audio.jobId)} icon={<span className="material-symbols-outlined">
                                    visibility
                                </span>} />
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default AgentAnalysisComponent;