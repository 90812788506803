import "./human_agent_list.page.style.scss";
import { useNavigate, useParams } from "react-router-dom";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import "./human_agent_list.page.style.scss";
import { FC, useContext, useEffect, useState } from "react";
import SearchResultEntity from "../../../../domain/entities/search_result.entity";
import di from "../../../../dependency_injection";
import { isLeft } from "fp-ts/lib/Either";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import routes from "../../../routes/Routes";
import PaginatorComponent from "../../../components/paginator/PaginatorComponent";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";
import ButtonComponent from "../../../components/button/button.component";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import NotResultsComponent from "../../../components/noResults/not_results.component";
import ModalsContext from "../../../providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import HumanAgentEntity, { HumanAgentStatus } from "../../../../domain/entities/human_agent.entity";
import CreateHumanAgentComponent from "../create/create_human_agent.component";
import DeleteHumanAgentUseCase, { DeleteHumanAgentUseCaseName } from "../../../../domain/use_cases/human_agent/delete_human_agent.use_case";
import SearchHumanAgentsUseCase, { SearchHumanAgentsUseCaseName } from "../../../../domain/use_cases/human_agent/search_human_agents.use_case";

const HumanAgentListPage: FC<{}> = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchQuery = searchParams.get("search");
    const pageQuery = parseInt(searchParams.get("page") ?? "1");
    const statusQuery = searchParams.get("status") as HumanAgentStatus;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast, openModalDelete, openModalCustom, closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const [result, setResult] = useState<SearchResultEntity<HumanAgentEntity> | undefined>(undefined);
    const navigate = useNavigate();
    const itemsPerPage = 20;
    let _timerTap: any;

    const _searchHumanAgents = async () => {
        const data = getValues();
        clearTimeout(_timerTap);
        setResult(undefined);
        const response = await di.get<SearchHumanAgentsUseCase>(SearchHumanAgentsUseCaseName).call({
            query: data[KeyWordLocalization.HumanAgentListPageQuery],
            status: data[KeyWordLocalization.HumanAgentEntityStatus],
            page: data[KeyWordLocalization.HumanAgentListCurrentPage],
            itemsPerPage
        });
        if (isLeft(response)) {
            setResult({
                data: [],
                page: 1,
                total: 0,
                itemsPerPage: 0,
                pages: 0,
            })
            return;
        }
        setResult(response.right);
    }

    const _handleSearch = (data: any) => {
        const search = data[KeyWordLocalization.HumanAgentListPageQuery];
        const page = data[KeyWordLocalization.HumanAgentListCurrentPage];
        const status = data[KeyWordLocalization.HumanAgentEntityStatus];
        let query = "?";
        if (search && search.length > 0) query += `search=${search}&`;
        if (page) query += `page=${page}&`;
        if (status) query += `status=${status}&`;
        if (query.endsWith("&")) query = query.slice(0, -1);
        navigate(routes.human_agent_list.relativePath + query);
    }

    const _handleChangeTextSearch = () => {
        clearTimeout(_timerTap);
        const data = getValues();
        _timerTap = setTimeout(() => _handleSearch(data), 500);
    }

    const _handleChange = () => {
        const data = getValues();
        _handleSearch(data);
    }

    const _handleLoad = () => {
        setValue(KeyWordLocalization.HumanAgentListPageQuery, searchQuery);
        setValue(KeyWordLocalization.HumanAgentListCurrentPage, pageQuery);
        setValue(KeyWordLocalization.HumanAgentEntityStatus, statusQuery);
        _searchHumanAgents();
    }

    const _handleOnChangePage = (page: number) => {
        setValue(KeyWordLocalization.HumanAgentListCurrentPage, page);
        const data = getValues();
        _handleSearch(data);
    }

    const _handleOnClickAgent = (agent: HumanAgentEntity) =>
        navigate(routes.human_agent.relativePathWithParams({ id: agent.id }));

    const _handleOnClickDeleteAgent = async (agent: HumanAgentEntity) => {
        const deleteAgent = async () => {
            const response = await di.get<DeleteHumanAgentUseCase>(DeleteHumanAgentUseCaseName).call(agent.id);
            if (isLeft(response)) return addToast(response.left.code ?? KeyWordLocalization.UnknownError, 'error', undefined);
            addToast(i18n(KeyWordLocalization.HumanAgentListPageDeleteSuccess), 'success', undefined);
            _searchHumanAgents();
        }
        openModalDelete(
            i18n(KeyWordLocalization.HumanAgentListDeleteModalTitle),
            i18n(KeyWordLocalization.HumanAgentListDeleteModalSubtitle),
            deleteAgent
        );
    };

    const _handleOnClickUpdateAgent = (agent: HumanAgentEntity) => {
        const _onSuccess = (agent: HumanAgentEntity) => {
            closeModalCustom();
            _searchHumanAgents();
        }
        openModalCustom(
            "lg",
            i18n(KeyWordLocalization.HumanAgentListPageUpdateAgent),
            <CreateHumanAgentComponent humanAgent={agent} onCreated={_onSuccess} />,
            { hideOnTap: false });
    }

    const _handleOnCreateSingleAgent = () => {
        const _onSuccess = (agent: HumanAgentEntity) => {
            closeModalCustom();
            _searchHumanAgents();
        };
        openModalCustom(
            "lg",
            i18n(KeyWordLocalization.HumanAgentListPageBulkImport),
            // Aquí irá el componente para la importación masiva
            <CreateHumanAgentComponent onCreated={_onSuccess} />
        );
    };

    const _handleDownloadAgents = () => {
        //TODO
    }

    const _handleOnBulkImport = () => navigate(routes.bulk_human_agent.relativePath);

    useEffect(() => {
        _handleLoad();
    }, [pageQuery, searchQuery, statusQuery]);

    return <div className="human_agent_list_page">
        <LayoutComponent title={i18n(KeyWordLocalization.HumanAgentListTitle)}>
            <div className="container py-5">
                <form onSubmit={handleSubmit(_handleSearch)}>
                    <div className="row">
                        <div className="col-md-8">
                            <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.AgentListPageSearchInput)} {...register(KeyWordLocalization.HumanAgentListPageQuery)} onKeyUp={_handleChangeTextSearch} />
                        </div>
                        <div className="col-md-2">
                            <select className="form-control" {...register(KeyWordLocalization.HumanAgentEntityStatus, { onChange: _handleChange })} >
                                <option value="">{i18n(KeyWordLocalization.HumanAgentEntityStatusAll)}</option>
                                <option value={HumanAgentStatus.active}>{i18n(KeyWordLocalization.HumanAgentEntityStatusActive)}</option>
                                <option value={HumanAgentStatus.inactive}>{i18n(KeyWordLocalization.HumanAgentEntityStatusInactive)}</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <ButtonComponent
                                className="w-100 d-flex justify-content-center"
                                icon={<span className="material-symbols-outlined">search</span>}
                                text={i18n(KeyWordLocalization.HumanAgentListPageSearch)} design="primary" type="submit" />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-end mt-3">
                        <ButtonComponent
                            design="primary"
                            onClick={_handleDownloadAgents}
                            icon={<span className="material-symbols-outlined">download</span>}
                            className="btn_bulk_import mx-2"
                            text={i18n(KeyWordLocalization.HumanAgentListPageDownloadAgents)}
                        />
                        <ButtonComponent
                            design="outstanding"
                            onClick={_handleOnBulkImport}
                            icon={<span className="material-symbols-outlined">upload_file</span>}
                            className="btn_bulk_import mx-2"
                            text={i18n(KeyWordLocalization.HumanAgentListPageBulkImport)}
                        />
                        <ButtonComponent
                            design="outstanding"
                            onClick={_handleOnCreateSingleAgent}
                            icon={<span className="material-symbols-outlined">add</span>}
                            className="btn_bulk_import"
                            text={i18n(KeyWordLocalization.HumanAgentListPageAddAgent)}
                        />
                    </div>

                    <div className="list">
                        {result == undefined && <div className="mt-5">
                            <LoadingComponent />
                        </div>}
                        {result && result.data.length == 0 && <NotResultsComponent />}
                        {result && result.data.length > 0 && <table className="table my-3" style={{ tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th>{i18n(KeyWordLocalization.HumanAgentListPageId)}</th>
                                    <th>{i18n(KeyWordLocalization.HumanAgentListPageName)}</th>
                                    <th>{i18n(KeyWordLocalization.HumanAgentListPageActions)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result.data.map((agent, index) => <tr key={index}>
                                    <td className="hover" onClick={() => _handleOnClickAgent(agent)} style={{ width: 10 }}>
                                        {agent.id}
                                    </td>
                                    <td className="hover" onClick={() => _handleOnClickAgent(agent)}>
                                        {agent.name}
                                    </td>
                                    <td style={{ width: '15em' }}>
                                        <div className="d-flex">
                                            <ButtonComponent
                                                onClick={() => _handleOnClickUpdateAgent(agent)}
                                                className="mx-2"
                                                design="primary"
                                                text={i18n(KeyWordLocalization.HumanAgentListPageActionEdit)}
                                                icon={<span className="material-symbols-outlined">edit</span>}
                                            />
                                            <ButtonComponent
                                                onClick={() => _handleOnClickDeleteAgent(agent)}
                                                design="danger"
                                                type="button"
                                                text={i18n(KeyWordLocalization.HumanAgentListPageActionDelete)}
                                                icon={<span className="material-symbols-outlined">delete</span>}
                                            />
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>}
                    </div>
                    <div className="paginator">
                        {result && result.pages > 1 &&
                            <PaginatorComponent resultData={result} onChangePage={_handleOnChangePage} />
                        }
                    </div>
                </form>
            </div>
        </LayoutComponent>
    </div>
}

export default HumanAgentListPage;