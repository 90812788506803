import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import "./human_agent_bulk_import.page.style.scss";
import { FC, useState, useContext } from "react";
import { read, utils, write } from "xlsx";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import ButtonComponent from "../../../components/button/button.component";
import HumanAgentEntity, { HumanAgentStatus } from "../../../../domain/entities/human_agent.entity";
import BulkImportHumanAgentsUseCase, { BulkImportHumanAgentsUseCaseName } from "../../../../domain/use_cases/human_agent/bulk_import_human_agents.use_case";
import di from "../../../../dependency_injection";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";

const HumanAgentBulkImportPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState<boolean>(false);
    const [agents, setAgents] = useState<{ agent: HumanAgentEntity, error?: string }[]>([]);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const handleFileUpload = (file: File) => {
        setLoading(true);
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target!.result as ArrayBuffer);
                const workbook = read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const json = utils.sheet_to_json<HumanAgentEntity>(sheet);
                const columnName = i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewName);
                const columnAgentId = i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewAgentId);

                const parsedAgents = json.map((agent: any) => {
                    let error;
                    if (!agent[columnName] || !agent[columnAgentId]) {
                        error = i18n(KeyWordLocalization.HumanAgentBulkImportPageMissingFields);
                    }
                    return { agent: { id: agent[columnAgentId], name: agent[columnName], agentId: agent[columnAgentId], status: HumanAgentStatus.active }, error: error, };
                });
                setAgents(parsedAgents);
            };
            reader.readAsArrayBuffer(file);
        } catch (error) {
            addToast(i18n(KeyWordLocalization.HumanAgentBulkImportPageInvalidFile), 'error', undefined);
        }
        setLoading(false);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        if (progress != null) return;
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        if (progress != null) return;
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        if (progress != null) return;
        e.preventDefault();
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            return;
        }
        setIsDragging(false);
    };

    const handleBulkImport = async () => {
        setProgress(0);
        let successCount = 0;
        let failureCount = 0;
        const failedAgents: { agent: HumanAgentEntity, error?: string }[] = [];

        const updateOne = (response: { agent: HumanAgentEntity, error?: string }) => {
            if (response.error != null) {
                failureCount++;
                failedAgents.push(response);
            } else {
                successCount++;
            }
            setProgress((successCount + failureCount) / agents.length * 100);
        };

        const response = await di.get<BulkImportHumanAgentsUseCase>(BulkImportHumanAgentsUseCaseName).call(agents.map(a => a.agent), updateOne);

        setAgents(failedAgents);
        setProgress(undefined);

        if (successCount > 0 && failureCount === 0) {
            addToast(i18n(KeyWordLocalization.HumanAgentBulkImportPageSuccess), 'success', undefined);
        } else if (successCount > 0 && failureCount > 0) {
            addToast(i18n(KeyWordLocalization.HumanAgentBulkImportPagePartialSuccess, { successCount, failureCount }), 'warning', undefined);
        } else {
            addToast(i18n(KeyWordLocalization.HumanAgentBulkImportPageFailure), 'error', undefined);
        }
    };

    const handleDownloadTemplate = () => {
        const ws = utils.json_to_sheet([]);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, i18n(KeyWordLocalization.HumanAgentBulkImportPageAgentsSheet));
        utils.sheet_add_aoa(ws, [[i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewName), i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewAgentId)]], { origin: "A1" });
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "human_agent_template.xlsx";
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div className="human_agent_bulk_import" onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
            <LayoutComponent title={i18n(KeyWordLocalization.HumanAgentBulkImportPageTitle)}>
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="actions w-100 d-flex align-items-center">
                                <div className="flex-grow-1">
                                    {i18n(KeyWordLocalization.HumanAgentBulkImportPageDescription)}
                                </div>
                                {agents.length > 0 && <div>
                                    <div className="input_upload_container mx-3">
                                        <input value="" type="file" accept=".xlsx" onChange={handleFileChange} id="fileInput" />
                                        <label htmlFor="fileInput">
                                            <ButtonComponent isLoading={loading} design="primary" text={i18n(KeyWordLocalization.HumanAgentBulkImportPageUpload)} icon={<span className="material-symbols-outlined">cloud_upload</span>} />
                                        </label>
                                    </div>
                                </div>
                                }
                                <ButtonComponent design="primary" text={i18n(KeyWordLocalization.HumanAgentBulkImportPageDownloadTemplate)} onClick={handleDownloadTemplate} icon={<span className="material-symbols-outlined">cloud_download</span>} />
                            </div>

                        </div>
                    </div>
                    {agents.length <= 0 &&
                        <div className="card my-4">
                            <div className="card-body text-center d-flex justify-content-center align-items-center flex-column">
                                {i18n(KeyWordLocalization.HumanAgentBulkImportPageDragDrop)}
                                <div className="input_upload_container my-3">
                                    <input value="" type="file" accept=".xlsx" onChange={handleFileChange} id="fileInput" />
                                    <label htmlFor="fileInput">
                                        <ButtonComponent isLoading={loading} design="outstanding" text={i18n(KeyWordLocalization.HumanAgentBulkImportPageUpload)} icon={<span className="material-symbols-outlined">cloud_upload</span>} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {isDragging && (
                        <div className="drop-zone">
                            <input value="" type="file" accept=".xlsx" onChange={handleFileChange} id="fileInputDrag" />
                            <label htmlFor="fileInputDrag" className="drop-zone-content">
                                <span className="material-symbols-outlined" style={{ fontSize: '50px' }}>cloud_upload</span>
                                <p>{i18n(KeyWordLocalization.HumanAgentBulkImportPageDropHere)}</p>
                            </label>
                        </div>
                    )}
                    {agents.length > 0 && (
                        <div className="preview my-3 card">
                            {/* <h3>{i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewTitle)}</h3> */}
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewName)}</th>
                                            <th>{i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewAgentId)}</th>
                                            <th>{i18n(KeyWordLocalization.HumanAgentBulkImportPagePreviewError)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agents.map((agent, index) => (
                                            <tr key={index}>
                                                <td>{agent.agent.name}</td>
                                                <td>{agent.agent.agentId}</td>
                                                <td>{agent.error || ''}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    )}
                    {progress !== undefined && (
                        <div className="progress_bulk">
                            <div className="progress_internal">
                                <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                                    <LoadingComponent showText={false}/>
                                    <h3>{i18n(KeyWordLocalization.HumanAgentBulkImportPageBulkModalTitle)}</h3>
                                    <span>{i18n(KeyWordLocalization.HumanAgentBulkImportPageBulkModalSubtitle)}</span>
                                </div>
                                <div className="w-100 d-flex justify-content-between mt-3">
                                    <div>
                                        <span>{i18n(KeyWordLocalization.HumanAgentBulkImportPageBulkModalLoading)}</span>
                                    </div>
                                    <div>
                                        {progress.toFixed(1)}%
                                    </div>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                            </div>
                        </div>
                    )}
                    {agents.length > 0 && <div className="floating_button">
                        <ButtonComponent isLoading={progress != undefined} onClick={handleBulkImport} design="outstanding" text={i18n(KeyWordLocalization.HumanAgentBulkImportPageStart)} icon={<span className="material-symbols-outlined">play_arrow</span>} />
                    </div>}
                </div>
            </LayoutComponent>
        </div>
    );
};

export default HumanAgentBulkImportPage;