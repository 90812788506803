import { injectable } from "inversify";
import QARepository from "../../../domain/repositories/qa.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import QAAgentEntity from "../../../domain/entities/qa_agent.entity";
import QAQuestionEntity from "../../../domain/entities/qa_question.entity";
import QAResultEntity from "../../../domain/entities/qa_result.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import SearchAgentsApiImpl from "./api/search_agents.api.impl";
import getAgentByIdApiImpl from "./api/get_agent_by_id.api.impl";
import UpdateQuestionApiImpl from "./api/UpdateQuestionApiImpl";
import createAgentApiImpl from "./api/create_agent.api.impl";
import deleteQuestionApiImpl from "./api/delete_question.api.impl";
import UploadAudioForTranscriptionApiImpl from "./api/UploadAudioForTranscriptionApiImpl";
import GetAudioResultsApiImpl from "./api/GetAudioResultsApiImpl";
import deleteAgentApiImpl from "./api/delete_agent.api.impl";
import QACategoryEntity from "../../../domain/entities/qa_category.entity";
import createQuestionApiImpl from "./api/create_question.api.impl";
import updateAgentApiImpl from "./api/update_agent.api.impl";
import createCategoryApiImpl from "./api/create_qa_category.api.impl";
import deleteCategoryApiImpl from "./api/delete_category.api.impl";
import updateCategoryApiImpl from "./api/update_qa_category.api.impl";

@injectable()
class QARepositoryImpl implements QARepository {
    //category
    addCategory = (qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>> => createCategoryApiImpl(qaCategory);
    updateCategory = (qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>> => updateCategoryApiImpl(qaCategory);
    deleteCategory = (id: string): Promise<Either<ErrorEntity, void>> => deleteCategoryApiImpl(id);

    //agent
    deleteAgent = (id: string): Promise<Either<ErrorEntity, void>> => deleteAgentApiImpl(id);
    updateAgent = (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => updateAgentApiImpl(agent);
    createAgent = async (_: {
        name: string,
        description: string,
    }): Promise<Either<ErrorEntity, QAAgentEntity>> => createAgentApiImpl(_);
    searchAgents = (query: string, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>> => SearchAgentsApiImpl(query, page, itemsPerPage);
    getAgentById = async (id: string): Promise<Either<ErrorEntity, QAAgentEntity>> => getAgentByIdApiImpl(id);

    //others
    getAudioResults = async (jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => GetAudioResultsApiImpl(jobId, onCallBack);
    uploadAudioForTranscription = async (audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>> => UploadAudioForTranscriptionApiImpl(audio, agentId, onCallBack);

    //questions
    addQuestionToAgent = async (_:{ category: QACategoryEntity, question: QAQuestionEntity }): Promise<Either<ErrorEntity, QAQuestionEntity>> => createQuestionApiImpl(_);
    deleteQuestionFromAgent = async (questionId: string): Promise<Either<ErrorEntity, void>> => deleteQuestionApiImpl(questionId);
    updateQuestionFromAgent = (question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => UpdateQuestionApiImpl(question);

}

export default QARepositoryImpl;