import { injectable } from "inversify";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "../localization/GetCurrentLanguageUseCase";
import di from "../../../dependency_injection";
import GetCurrentUserUseCase, { GetCurrentUserUseCaseName } from "../auth/GetCurrentUserUseCase";

interface Props {
}
@injectable()
export default class LoadUseCase {

    constructor(_props: Props) {
    }

    public call = async (): Promise<void> => {
        await di.get<GetCurrentLanguageUseCase>(GetCurrentLanguageUseCaseName).call(true);
        await di.get<GetCurrentUserUseCase>(GetCurrentUserUseCaseName).call();
    }
}

export const LoadUseCaseName = "LoadUseCase";