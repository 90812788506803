import './loading.component.style.css';
import { FC, useContext } from 'react';
import LoadingComponentProps from './loading.component.props';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import LocalizationContext from '../../providers/localization/LocalizationContext';
import KeyWordLocalization from '../../utils/KeyWordLocalization';

const LoadingComponent: FC<LoadingComponentProps> = ({ large = false, fullScreen = false, showText = true }) => {
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  return (
    <div className={`loading_component ${fullScreen ? 'full_screen' : ''}`}>
      <div className={`spinner ${large ? 'large' : ''}`}>
        <img className='logo' src='/assets/logo/b_yellow.png' alt='logo' />
        <div className='double-bounce1'></div>
        <div className='double-bounce2'></div>
      </div>
      {showText &&
        <strong>{i18n(KeyWordLocalization.LoadingComponentLoading)}</strong>
      }
    </div>
  );
}

export default LoadingComponent;