import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import { useForm } from "react-hook-form";
import QAQuestionEntity from "../../../../domain/entities/qa_question.entity";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../providers/modal/ModalsContext";
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../dependency_injection";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "../../../../domain/use_cases/qa/get_agent_by_id.use_case";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";
import CategoriesEditionComponent from "./widgets/categories_edition/categories_edition.component";
import ButtonComponent from "../../../components/button/button.component";
import UpdateAgentUseCase, { UpdateAgentUseCaseName } from "../../../../domain/use_cases/qa/update_agent.use_case";
import CreateAgentComponent from "../create/form/create_agent.component";

const EditAgentPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast, closeModalCustom, openModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const [agent, setAgent] = useState<QAAgentEntity | null | undefined>(undefined);


    const _searchAgent = async () => {
        if (id == undefined) return setAgent(null);
        const response = await di.get<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).call(id);
        if (isLeft(response)) return setAgent(null);
        setAgent(response.right);
    }

    const _handleUpdateAgent = async () => {
        const _updateAgent = async (agent: QAAgentEntity) => {
            const response = await di.get<UpdateAgentUseCase>(UpdateAgentUseCaseName).call(agent);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentCategoryError), 'error', undefined);
            closeModalCustom();
            setAgent(response.right);
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentCategoryAdded), 'success', undefined);
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CategoriesEditionComponentCreateCategory), <CreateAgentComponent onUpdated={_updateAgent} agent={agent!} />, { hideOnTap: false });

    }

    useEffect(() => {
        _searchAgent();
    }, [id]);

    return <div className="detailed_agent_page">
        <LayoutComponent title={i18n(KeyWordLocalization.EditAgentPageTitle)}>
            {agent === undefined && <LoadingComponent />}
            {agent === null && <NotFoundComponent />}
            {agent && <>
                <div className="container py-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1 text_ellipsis">
                                    <h1 style={{ fontSize: '1.5em' }}>{i18n(KeyWordLocalization.DetailedAgentPageTitle)}: {agent.name}</h1>
                                </div>
                                <ButtonComponent icon={
                                    <span className="material-symbols-outlined">edit
                                    </span>
                                } onClick={_handleUpdateAgent} text={i18n(KeyWordLocalization.edit)} />
                            </div>
                            <p>{agent.description}</p>
                            {/* <ButtonComponent onClick={_handleEdit} icon={
                            <span className="material-symbols-outlined">edit</span>
                        } text={KeyWordLocalization.edit} /> */}
                        </div>
                    </div>

                    <div className="card elvated my-3">
                        <CategoriesEditionComponent agent={agent} setAgent={setAgent} />
                    </div>

                </div>
            </>}
        </LayoutComponent>
    </div >

}

export default EditAgentPage;