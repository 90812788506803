import './modal.component.style.css';
import { FC } from "react";
import ModalComponentProps from "./modal.component.props";
import { MdClose } from 'react-icons/md';

const ModalComponent: FC<ModalComponentProps> = ({ children, isOpen, title, toggle, size, hideOnClick = true, hideCardBody = false, aditionalIndex = 0 }) => {
    return <div className={`modal_component ${hideCardBody && "hide_card_body"}`} style={{ zIndex: 1000 + aditionalIndex }}>
        <div className="back_modal" onClick={hideOnClick ? toggle : undefined}></div>
        <div className={`modal_content ${size}`}>
            {!hideCardBody && <>
                <div className="modal_header">
                    <div className="modal_title">
                        {title}
                    </div>
                    <div className="modal_close" onClick={toggle}>
                        <MdClose />
                    </div>
                </div>
                <hr />
            </>}
            <div className="modal_body">
                {children}
            </div>
        </div>
    </div>
}

export default ModalComponent;