import { injectable } from "inversify";
import QARepository from "../../repositories/qa.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import QACategoryEntity from "../../entities/qa_category.entity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class CreateCategoryUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (category: QACategoryEntity, ) : Promise<Either<ErrorEntity, QACategoryEntity>> => {
        return this.qaRepository.addCategory(category);
    }
}

export const CreateCategoryUseCaseName = "CreateCategoryUseCase";