import { injectable } from "inversify";
import LocalizationEntity from "../../entities/localization.entity";
import LocalizationRepository from "../../repositories/localization.repository";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface props {
    localizationRepository: LocalizationRepository;
    localizationProvider: LocalizationProvider;
}
@injectable()
class GetCurrentLanguageUseCase {
    _localizationRepository: LocalizationRepository;
    _localizationProvider: LocalizationProvider;

    constructor(_: props) {
        this._localizationRepository = _.localizationRepository;
        this._localizationProvider = _.localizationProvider;
    }
    public call = async (force?: boolean): Promise<LocalizationEntity> => {
        if(!force && this._localizationProvider.Actions.localization != undefined) return this._localizationProvider.Actions.localization;
        const response = await this._localizationRepository.getLanguage();
        this._localizationProvider.Actions.setLocalization(response);
        return response;
    }
}

export default GetCurrentLanguageUseCase;

export const GetCurrentLanguageUseCaseName = "GetCurrentLanguageUseCase";