import { Either, left, right } from "fp-ts/lib/Either";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import ErrorEntity from "../../../../domain/entities/error.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const updateAgentApiImpl = async (agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>> => {
    try {
        const relativeUrl = `/agent/${agent.id}`;
        const body = {
            "name": agent.name,
            "description": agent.description,
        };
        const response = await di.get<HostApi>(HostApiName).put(relativeUrl, body, {
            contentType: "application/json",
        });
        console.log("response update response", response);

        const parsed = new QAAgentEntity({
            id: response.id,
            name: response.name,
            description: response.description,
            clientId: response.clientId,
            categories: [],
        });
        console.log("response update parsed", parsed);
        return right(parsed);
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default updateAgentApiImpl;