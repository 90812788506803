import { injectable } from "inversify";
import DashboardRepository, { HumanRanking } from "../../repositories/dashboard.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import SearchResultEntity from "../../entities/search_result.entity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class SearchRankingHumanAgentsUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (_:{query: string, 
        initDate?: Date, endDate?: Date, page: number, itemsPerPage: number, sortBy: "ASC" | "DESC",}): Promise<Either<ErrorEntity, SearchResultEntity<HumanRanking>>> => {
        return this.dashboardRepository.searchRanking(_);
    }
}

export default SearchRankingHumanAgentsUseCase;

export const SearchRankingHumanAgentsUseCaseName = "SearchRankingHumanAgentsUseCase";