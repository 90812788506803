import { HumanRanking } from "../../domain/repositories/dashboard.repository"

const fromJSON = (json: any): HumanRanking => {
    const temp: HumanRanking = {
        id: json.human_agent_id,
        name: json.human_agent_name,
        sentiments: {
            negative: json.total_negative,
            neutral: json.total_neutral,
            positive: json.total_positive,
        },
        rankingPosition: json.ranking_position,
        score: json.average_score,
        totalEvaluatedCalls: json.total_calls_evaluated,
    }
    return temp
}

export default {
    fromJSON,
}