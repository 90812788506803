import './result_ia_audio.component.style.scss';
import { FC, useContext, useRef, useState } from 'react';
import ResultIAAudioComponentProps from './result_ia_audio.component.props';
import AccordionComponent from '../../../../../components/accordion/accordion.component';
import LocalizationContextType from '../../../../../../domain/providers/localization/LocalizationContextType';
import LocalizationContext from '../../../../../providers/localization/LocalizationContext';
import KeyWordLocalization from '../../../../../utils/KeyWordLocalization';
import DatesUtils from '../../../../../utils/DatesUtils';

const ResultIAAudioComponent: FC<ResultIAAudioComponentProps> = ({ result }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [expandedQuestions, setExpandedQuestions] = useState<(string | number)[]>([]);
    const scrollRef = useRef<HTMLDivElement>(null);

    const _questionIsExpanded = (index: string) => expandedQuestions.includes(index);

    const _toggleExpandedQuestions = (index: string) => {
        _scrollUp();
        if (_questionIsExpanded(index)) setExpandedQuestions(expandedQuestions.filter(expanded => expanded !== index));
        else setExpandedQuestions([...expandedQuestions, index]);
    }

    const _scrollUp = () => {
        scrollRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const _onToggleCategory = (opened: (string | number)[]) => {
        setExpandedQuestions(opened);
        _scrollUp();
    }


    return <div className="result_ia_audio_component" ref={scrollRef}>
        <h5>{i18n(KeyWordLocalization.ResultIaAudioComponentTitle)}</h5>
        <div className="result">
            <div className="row">
                <div className="col-md-10">
                    <audio controls src={result.audioUrl} />
                </div>
                <div className="col-md-2">
                    <div className="score d-flex flex-column align-items-end">
                        <h5 className='mb-0'>{result.getTotalPointsResponse()}/{result.getMaxPoints()}</h5>
                        <h6>{i18n(KeyWordLocalization.ResultIaAudioComponentScore)}</h6>
                    </div>
                </div>
            </div>
            <AccordionComponent body={[
                {
                    header: <div className='px-2'>{i18n(KeyWordLocalization.ResultIaAudioComponentTranscription)}</div>,
                    content: <div>
                        {result.transcription.conversation.map((chat, index) => <div className={`chat ${chat.senderIsClient && 'mine'}`} key={index}>
                            {chat.transcription}
                            <div className="text_light">{DatesUtils.ssToTime(chat.time)}</div>
                        </div>)}
                    </div>
                }
            ]} />
            <h6 className='mt-4'>
                {i18n(KeyWordLocalization.ResultIaAudioComponentResultsByCategory)}
            </h6>
            <AccordionComponent onToggle={_onToggleCategory} body={result.categories.map((category) => {
                // const _percentage = category.getPercentageOfPoints();
                const _percentage = 70;
                return {
                    header: <div>
                        <div className="d-flex align-items-center">
                            {_percentage < 60 && <span className="material-symbols-outlined text-danger">sentiment_dissatisfied</span>}
                            {_percentage >= 60 && _percentage < 80 && <span className="material-symbols-outlined text_color_primary">sentiment_satisfied</span>}
                            {_percentage >= 80 && <span className="material-symbols-outlined text-success">sentiment_very_satisfied</span>}
                            <h6 className='ms-2'>{category.title} ({category.getTotalPointsResponse()}/{category.getMaxQuestionPoints()} {i18n(KeyWordLocalization.ResultIaAudioComponentPoints)})</h6>
                        </div>
                    </div>,
                    content: <div className="category_result">
                        <div className="category_description">
                            {category.description}
                        </div>
                        {category.questions.map((question, index) => <div key={index} className="">
                            <div className="question">
                                <h6>{question.title} ({question.points} {i18n(KeyWordLocalization.ResultIaAudioComponentPoints)})</h6>
                                {question.description.length > 100 && !_questionIsExpanded(index + "q") ? `${question.description.substring(0, 100)}...` : question.description}
                                {question.description.length > 100 && <span className='more_text' onClick={() => _toggleExpandedQuestions(index + "q")}>{_questionIsExpanded(index + "q") ? "Hide" : "Show"} {i18n(KeyWordLocalization.ResultIaAudioComponentMore)}</span>}
                            </div>
                            <div className='response'>
                                <strong>{question.response?.short} ({question.response?.points} {i18n(KeyWordLocalization.ResultIaAudioComponentPoints)})</strong>
                                <p>{question.response?.justification}</p>
                            </div>
                        </div>)}
                    </div>
                }
            })} />
        </div>
    </div>
}

export default ResultIAAudioComponent;