import { ReasonForCalls } from "../../domain/repositories/dashboard.repository";

const fromJSON = (json: any): ReasonForCalls => {
    return {
        name: json.call_type,
        count: json.total_count,
    };
}

export default {
    fromJSON,
};