import { injectable } from "inversify";
import HumanAgentRepository from "../../../domain/repositories/human_agent.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import HumanAgentEntity, { HumanAgentStatus } from "../../../domain/entities/human_agent.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import HumanAgentRepositoryFake from "./human_agent.repository.fake";

@injectable()
class HumanAgentRepositoryDev implements HumanAgentRepository {
    fakeRepository = new HumanAgentRepositoryFake();

    search = (_:{query?: string, status?: HumanAgentStatus, page: number, itemsPerPage: number}): Promise<Either<ErrorEntity, SearchResultEntity<HumanAgentEntity>>> => 
        this.fakeRepository.search(_);

    delete = (id: string): Promise<Either<ErrorEntity, void>> => 
        this.fakeRepository.delete(id);

    getById = (id: string): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        this.fakeRepository.getById(id);

    create = (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        this.fakeRepository.create(agent);

    update = (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => 
        this.fakeRepository.update(agent);
}

export default HumanAgentRepositoryDev;