import { injectable } from "inversify";
import DashboardRepository, { AverageCalls, GetOperationReportResponse, HumanRanking, ReasonForCalls, SentimentsOnDate, Target } from "../../../domain/repositories/dashboard.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import MasterItemEntity from "../../../domain/entities/master_item.entity";
import getCallAveragesApiImpl from "./api/get_call_averages.api.impl";
import getDayToDayApiImpl from "./api/get_date_to_day.api.impl";
import getReasonsForCallApiImpl from "./api/get_reasons_for_call.api.impl";
import getBestClientsApiImpl from "./api/search_ranking_agents.api.impl";
import getScoreDetailsApiImpl from "./api/get_score_details.api.impl";
import ClientAnalysisEntity from "../../../domain/entities/client_analysis.entity";
import QACommentEntity from "../../../domain/entities/qa_comment.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import getTopAndBottomAgentsApiImpl from "./api/get_top_and_bottom_agents.api.impl";
import searchRankingAgentsApiImpl from "./api/search_ranking_agents.api.impl";

@injectable()
class DashboardRepositoryImpl implements DashboardRepository {
    getTopAndBottomAgents = (_: { startDate: Date; endDate: Date; clientId?: string; }): Promise<Either<ErrorEntity, { best: HumanRanking[]; worst: HumanRanking[]; }>> => getTopAndBottomAgentsApiImpl(_);
    searchRanking =(_: { query: string; initDate: Date; endDate: Date; page: number; itemsPerPage: number; sortBy: "ASC" | "DESC"; }): Promise<Either<ErrorEntity, SearchResultEntity<HumanRanking>>> => searchRankingAgentsApiImpl(_);
    getCallAverages= (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => getCallAveragesApiImpl(clientId, startDate, endDate);
    getSentiments = (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => getDayToDayApiImpl(clientId, startDate, endDate);
    getReasonForCalls = (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, ReasonForCalls[]>> => getReasonsForCallApiImpl(clientId, startDate, endDate);
    getScoreDetails = (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, Target>> => getScoreDetailsApiImpl(clientId, startDate, endDate);
    getClientsList = async (): Promise<Either<ErrorEntity, MasterItemEntity[]>> => {
        throw new Error("Method not implemented.");
    }
    getClientDetails = (agentId: string, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, ClientAnalysisEntity>> => {
        throw new Error("Method not implemented.");
    }
    addComment = (_:{employeeID: string, comment: string}): Promise<Either<ErrorEntity, QACommentEntity>> => {
        throw new Error("Method not implemented.");
    }

}

export default DashboardRepositoryImpl;