import { injectable } from "inversify";
import ErrorEntity from "../../domain/entities/error.entity";
import KeyWordLocalization from "../../presentation/utils/KeyWordLocalization";

@injectable()
class RestApi {
    public KEY_TOKEN = "token_api_key";
    public url = "";

    public getToken = () => window.localStorage.getItem(this.KEY_TOKEN) ? `Bearer ${window.localStorage.getItem(this.KEY_TOKEN)}` : "";

    public setToken = (token: string) => {
        window.localStorage.setItem(this.KEY_TOKEN, token);
    };

    public removeToken = () => window.localStorage.removeItem(this.KEY_TOKEN);

    public _getHeaders = (headers?: { contentType?: string }) => {
        return {
            'Content-Type': headers?.contentType ?? 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Authorization': this.getToken(),
        };
    }

    public _parseError = async (response: Response): Promise<ErrorEntity> => {
        try {
            const error = await response.json();
            const mergeAndParse = error.detail.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
            return {
                code: 'ErrorHost' + mergeAndParse,
            }
        } catch (error) {
            const errorAsText = await response.text();
            const key: string = 'ErrorHost' + errorAsText;
            return {
                code: key in KeyWordLocalization ? (KeyWordLocalization as { [key: string]: string })[key] : KeyWordLocalization.UnknownError,
            }
        }
    }


    public get = (relativeUrl: string, params?: {
        contentType?: string
    }): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'GET',
            // mode: 'no-cors',
            headers: this._getHeaders(params),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });


    public post = (relativeUrl: string, body: any | string, params?: {
        noCors?: boolean
        contentType?: string
    }): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'POST',
            mode: params?.noCors == true ? 'no-cors' : undefined,
            headers: this._getHeaders(params),
            body: typeof body == "string" ? body : JSON.stringify(body),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });

    public put = (relativeUrl: string, body: any, params?: {
        contentType?: string
    }): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'PUT',
            headers: this._getHeaders(params),
            body: JSON.stringify(body),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });

    public remove = (relativeUrl: string, params?: {
        contentType?: string
    }): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'DELETE',
            headers: this._getHeaders(params),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });
}
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default RestApi;