import di from "../../dependency_injection";
import LocalizationProvider, { LocalizationProviderName } from "../../domain/providers/localization/LocalizationProvider";
import KeyWordLocalization from "./KeyWordLocalization";

const dayForGraphs = (date: Date): string => {
    const months = [KeyWordLocalization.january, KeyWordLocalization.february, KeyWordLocalization.march, KeyWordLocalization.april, KeyWordLocalization.may, KeyWordLocalization.june, KeyWordLocalization.july, KeyWordLocalization.august, KeyWordLocalization.september, KeyWordLocalization.october, KeyWordLocalization.november, KeyWordLocalization.december];
    const i18n = di.get<LocalizationProvider>(LocalizationProviderName).Actions.i18n;
    const day = date.getDate();
    const dd = day < 10 ? `0${day}` : day;
    const month = date.getMonth();
    const mm = i18n(months[month]);
    return `${dd} ${mm}`;
}

const isSameDate = (date1: Date, date2: Date): boolean => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

const getHHMMSS = (date: Date): string => {
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const hhs = hh >= 10 ? hh : "0"+hh;
    const mms = mm >= 10 ? mm : "0"+mm;
    const sss = ss >= 10 ? ss : "0"+ss;
    return `${hhs}:${mms}:${sss}`
}

const ssToTime = (seconds: number): string => {
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor((seconds % 3600) / 60);
    const ss = Math.floor(seconds % 60);
    const hhs = hh >= 10 ? hh : "0"+hh;
    const mms = mm >= 10 ? mm : "0"+mm;
    const sss = ss >= 10 ? ss : "0"+ss;
    return `${hhs}:${mms}:${sss}`
}

export default {
    dayForGraphs,
    isSameDate,
    getHHMMSS,
    ssToTime,
}