import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { isLeft } from "fp-ts/lib/Either";
import { useForm } from "react-hook-form";
import di from "../../../../../dependency_injection";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import CreateAgentUseCase, { CreateAgentUseCaseName } from "../../../../../domain/use_cases/qa/create_agent.use_case";
import ButtonComponent from "../../../../components/button/button.component";
import LocalizationContext from "../../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";
import Validators from "../../../../utils/Validators";
import QAAgentEntity from "../../../../../domain/entities/qa_agent.entity";
import CreateAgentComponentProps from "./create_agent.component.props";

const CreateAgentComponent: FC<CreateAgentComponentProps> = ({ onCreated, onUpdated, agent }) => {
    const { register, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast, closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState<boolean>(false);

    const _handleCreateAgent = async (data: any) => {
        setLoading(true);
        const response = await di.get<CreateAgentUseCase>(CreateAgentUseCaseName).call({ name: data[KeyWordLocalization.CreateAgentPageTitle], description: data[KeyWordLocalization.CreateAgentPageDescription] });
        if (isLeft(response)) return addToast(i18n(KeyWordLocalization.CreateAgentPageCreateError), 'error', undefined);
        addToast(i18n(KeyWordLocalization.CreateAgentPageCreateSuccess), 'success', undefined);
        onCreated?.(response.right);
    }

    const _loadInfo = async () => {
        if (agent == null) return;
        setValue(KeyWordLocalization.CreateAgentPageTitle, agent.name);
        setValue(KeyWordLocalization.CreateAgentPageDescription, agent.description);
    }

    const _handleOnEdit = async () => {
        if(agent == null || onUpdated == null) return;
        setLoading(true);
        const tempAgent:QAAgentEntity = new QAAgentEntity(agent);
        tempAgent.name = getValues(KeyWordLocalization.CreateAgentPageTitle);
        tempAgent.description = getValues(KeyWordLocalization.CreateAgentPageDescription);
        const response = await onUpdated?.(tempAgent);
        setLoading(false);
    }

    useEffect(() => {
        _loadInfo();
    }, [agent]);

    return <div className="create_agent_page">
        <div className="container">
            <form onSubmit={handleSubmit(agent == null ? _handleCreateAgent : _handleOnEdit)}>
                <div className={`form-group my-3 ${errors[KeyWordLocalization.CreateAgentPageTitle] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateAgentPageTitle)}</label>
                    <input type="text" disabled={loading} className="form-control" placeholder={i18n(KeyWordLocalization.CreateAgentPageTitle)} {...register(KeyWordLocalization.CreateAgentPageTitle, Validators({
                        required: true,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateAgentPageTitle} />
                </div>
                <div className={`form-group my-3 ${errors[KeyWordLocalization.CreateAgentPageDescription] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateAgentPageDescription)}</label>
                    <textarea disabled={loading} className="form-control" placeholder={i18n(KeyWordLocalization.CreateAgentPageDescription)} {...register(KeyWordLocalization.CreateAgentPageDescription, Validators({
                        maxLength: 255,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateAgentPageDescription} />
                </div>
                <div className="w-100 d-flex justify-content-end my-3">
                    {agent == null &&
                        <ButtonComponent isLoading={loading} icon={<span className="material-symbols-outlined" >
                            add </span>} type="submit" design="primary" text={i18n(KeyWordLocalization.CreateAgentPageCreate)} />}
                    {agent != null && <ButtonComponent isLoading={loading} icon={<span className="material-symbols-outlined" >
                        save </span>} type="submit" design="primary" text={i18n(KeyWordLocalization.CreateAgentPageUpdate)} />}
                </div>
            </form>
        </div>
    </div >
};

export default CreateAgentComponent;