import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { HumanRanking, Target } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import HumanRankingDto from "../../../dto/human_ranking.dto";

const getTopAndBottomAgentsApiImpl = async (_: {
    clientId?: string, startDate: Date, endDate: Date
}): Promise<Either<ErrorEntity, { worst: HumanRanking[], best: HumanRanking[] }>> => {
    try {
        let relativeUrl = `/stats/human-agents-ranking-divided?start_date=${DateDto.toServer(_.startDate)}&end_date=${DateDto.toServer(_.endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        const best = response.top_performers.map((item: any) => HumanRankingDto.fromJSON(item));
        const worst = response.bottom_performers.map((item: any) => HumanRankingDto.fromJSON(item));
        console.log({ best, worst });
        return right({
            best, worst
        });

    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default getTopAndBottomAgentsApiImpl;