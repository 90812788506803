import "./modal_custom.component.style.css";
import React, { useContext } from "react";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import ModalCustomComponentProps from "./modal_custom.component.props";
import ModalComponent from "../modalItem/modal.component";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContext from "../../ModalsContext";
import LocalizationContext from "../../../localization/LocalizationContext";
import KeyWordLocalization from "../../../../utils/KeyWordLocalization";

const ModalCustomComponent: React.FC<ModalCustomComponentProps> = () => {
    const { isOpenModalCustom, closeModalCustom, customModal, popModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    if (customModal == null) return <></>

    return <>
        <div className="modal_custom_component">
            {customModal.map((modal, index) =>
                <ModalComponent key={index} hideCardBody={modal?.params?.hideCardBody} title={modal?.title ?? ""} aditionalIndex={index} hideOnClick={modal?.params?.hideOnTap ?? true} centered size={modal.size ?? 'lg'} isOpen={isOpenModalCustom} toggle={popModalCustom} >
                    <div style={{ whiteSpace: "pre-wrap" }}>
                        {modal.content}
                    </div>
                    {modal?.params?.closeButton && <div className="modal_footer d-flex justify-content-end">
                        <button onClick={closeModalCustom} className="finish_modal_container_button">
                            {i18n(KeyWordLocalization.ModalCustomCompoonentCloseButton)}
                        </button>
                    </div>}
                </ModalComponent>)}
        </div>
    </>
}

export default ModalCustomComponent;