/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the implementation of the Constants interface, and it is used to store the
 * constants of the dev enviroment
 ********************************************************************************/

import Constants from "./Constants";

const ConstantsImpl: Constants = {
    HOST_URL: "https://back.dev-demos.lat",
    HOST_IMAGE_URL: "https://novo.blackpool.la",
    DEFAULT_IMAGE_URL: "",
    GOOGLE_MAPS_API_KEY: "",
}

export default ConstantsImpl;
