import { FC, useContext } from "react";
import LayoutComponentProps from "./LayoutHeaderComponentProps";
import UserContextType from "../../../../../domain/providers/user/UserContextType";
import UserContext from "../../../../providers/user/UserContext";

const LayoutHeaderComponent: FC<LayoutComponentProps> = ({ title, open, setOpen }) => {
    const { user } = useContext(UserContext) as UserContextType;
    const handleToggle = () => setOpen(!open);
    return <nav className="header">
        <div className="btn_open hover" onClick={handleToggle}>
            <span className="material-symbols-outlined">
                {open ? 'menu_open' : 'menu'}
            </span>
        </div>
        <div className="title">{title}</div>
        {user && <div className="user d-flex align-items-center">
            <div className="user_photo">
                <img src={user.photo} alt="" />
            </div>
            <div className="ms-3 info">
                <strong>
                    {user.name}
                </strong>
                <br />
                {user.clientName}
            </div>
        </div>}
    </nav>
}

export default LayoutHeaderComponent;