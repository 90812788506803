import CallTranscriptionEntity from "./call_transcription.entity";
import QACategoryEntity from "./qa_category.entity";

export interface IQAResultEntity {
    id: string;
    audioUrl: string;
    transcription: CallTranscriptionEntity;
    categories: QACategoryEntity[];
}

export default class QAResultEntity implements IQAResultEntity{
    audioUrl: string;
    categories: QACategoryEntity[];
    id: string;
    transcription: CallTranscriptionEntity;

    constructor(_: IQAResultEntity) {
        this.id = _.id;
        this.audioUrl = _.audioUrl;
        this.categories = _.categories;
        this.transcription = _.transcription;
    }

    getTotalPointsResponse = (): number | null => {
        let allNulls = true;
        let totalPoints = 0;
        this.categories.forEach(category => {
            const _total = category.getTotalPointsResponse();
            if (_total !== null) {
                allNulls = false;
                totalPoints += _total;
            }
        });
        if(allNulls) return null;
        return totalPoints;
    }

    getMaxPoints = (): number => this.categories.reduce((acc, category)=>acc + category.getMaxQuestionPoints(), 0);

}