import QAQuestionEntity from "../../domain/entities/qa_question.entity";

const fromJSON = (json: any): QAQuestionEntity => {
    return {
        id: json['id']?.toString() ?? '',
        points: json['points'] ?? 0,
        description: json['description'] ?? '',
        title: json['title'] ?? '',
        categoryId: json['category_id']?.toString() ?? '',
        agentId: json['agent_id']?.toString() ?? '',
    }
}

const toJSON = (entity: QAQuestionEntity): any => {
    const parsed: { id?: number, points: number, description: string, title: string, category_id: string, agent_id?: string } = {
        id: entity.id ? parseInt(entity.id) : undefined,
        points: entity.points,//
        description: entity.description,//
        title: entity.title,//
        category_id: entity.categoryId,//
        agent_id: entity.agentId,//
    }
    if (parsed.id == null ) delete parsed.id;
    return parsed;
}

const QAQuestionDto = {
    fromJSON,
    toJSON,
}

export default QAQuestionDto;