import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAAgentDto from "../../../dto/qa_agent.dto";

const getAgentByIdApiImpl = async (id: string): Promise<Either<ErrorEntity, QAAgentEntity>> => {
    try {
        const relativeUrl: string = `/agent/${id}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        const parsed = QAAgentDto.fromJSON(response);
        return right(parsed)
    } catch (e) {
        return left({ code: "Error" });
    }
}

export default getAgentByIdApiImpl;
