import './detailed_agent.page.scss';
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../dependency_injection";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "../../../../domain/use_cases/qa/get_agent_by_id.use_case";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import routes from "../../../routes/Routes";
import AudioZoneComponent from "./components/audioZone/audio_zone.component";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import AccordionComponent from '../../../components/accordion/accordion.component';
import ButtonComponent from '../../../components/button/button.component';

const DetailedAgentPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [agent, setAgent] = useState<QAAgentEntity | null | undefined>(undefined);
    const navigate = useNavigate();
    const [openedCategory, setOpenedCategory] = useState<string | null>('123-0');
    const _canBeUsed = true;

    const _searchAgent = async () => {
        if (id == undefined) return setAgent(null);
        const response = await di.get<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).call(id);
        if (isLeft(response)) return setAgent(null);
        setAgent(response.right);
    }

    const _handleEdit = () => navigate(routes.edit_agent.relativePathWithParams({ id: id! }) + "?open=1",);

    useEffect(() => {
        _searchAgent();
    }, [id]);

    return <div className="detailed_agent_page">
        <LayoutComponent title={i18n(KeyWordLocalization.DetailedAgentPageTitle)}>
            {agent === undefined && <LoadingComponent />}
            {agent === null && <NotFoundComponent />}
            {agent && <>
                <div className="container py-5">
                    <div className="card">
                        <div className="card-body">
                            <h1 style={{ fontSize: '1.5em' }}>{i18n(KeyWordLocalization.DetailedAgentPageTitle)}: {agent.name}</h1>
                            <p>{agent.description}</p>
                            {/* <ButtonComponent onClick={_handleEdit} icon={
                            <span className="material-symbols-outlined">edit</span>
                        } text={i18n(KeyWordLocalization.edit)} /> */}
                        </div>
                    </div>
                    {!_canBeUsed || agent.getMaxPoints() == 0 && <div className="row">
                        <div className="col-12 my-3">
                            <div className="card elevated">
                                <div className="card-body">
                                    {i18n(KeyWordLocalization.DetailedAgentPageNoCategoriesOrQuestions)}
                                    <div className="d-flex mt-4 justify-content-center">
                                        <ButtonComponent onClick={_handleEdit} icon={
                                            <span className="material-symbols-outlined">edit</span>
                                        } text={i18n(KeyWordLocalization.DetailedAgentPageAddQuestions)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {_canBeUsed && <div className="row">
                        <div className="col-md-6 my-3">
                            <div className="card elevated">
                                <AccordionComponent body={
                                    agent.categories.map((category, index) => {
                                        return {
                                            header: <div className="category_header">
                                                <h5 className='text_color_primary text_bold'>{category.getMaxQuestionPoints()}</h5>
                                                <h5 className='title'>{category.title}</h5>
                                            </div>,
                                            content: <div className="questions">
                                                {category.questions.map((question, index) => <div key={index} className="question">
                                                    <h5 className="mb-1">{question.title} ({question.points} {i18n(KeyWordLocalization.DetailedAgentPagePoints)})</h5>
                                                    <p>{question.description}</p>
                                                </div>)}
                                            </div>
                                        }
                                    })} />
                            </div>
                        </div>
                        { agent.getMaxPoints() > 0 && <div className="col-md-6 my-3">
                            <div className="card elevated">
                                <div className="card-body">
                                    <AudioZoneComponent qaAgent={agent} />
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </>}
        </LayoutComponent>
    </div >

}

export default DetailedAgentPage;