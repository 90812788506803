import UserEntity, { UserType } from "../../domain/entities/user.entity";

const fromJSON = (json: any): UserEntity => {
    return {
        name: json.username,
        role: json.role == "ADMIN" ? UserType.ADMIN : UserType.CLIENT,
        clientName: json.client_name,
        operationName: json.operation_name,
        photo: json.photo ?? "https://concepto.de/wp-content/uploads/2018/08/persona-e1533759204552.jpg",
        id: json.id //TODO Check if this is correct
    }
}

const toJSON = (user: UserEntity): any => {
    return {
        username: user.name,
        role: user.role,
        client_name: user.clientName,
        operation_name: user.operationName,
        photo: user.photo,
    }
}

export default {
    fromJSON,
    toJSON
}