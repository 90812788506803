import { FC, useContext, useState } from "react";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import HorizontalSteperComponent from "../../../components/horizontal_stepper/horizontal_stepper.component";
import CreateAgentComponent from "./form/create_agent.component";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import CategoriesEditionComponent from "../edit/widgets/categories_edition/categories_edition.component";
import ButtonComponent from "../../../components/button/button.component";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/Routes";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../providers/localization/LocalizationContext";

const CreateAgentPage: FC<{}> = () => {
    const [step, setStep] = useState<number>(0);
    const [agent, setAgent] = useState<QAAgentEntity | null | undefined>(undefined);
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const navigate = useNavigate();

    const _changeStep = (_step: number) => {
        if (_step > step) setStep(_step);
    }

    const _handleCreateAgent = (agent: QAAgentEntity) => {
        setStep(1);
        setAgent(agent);
    }

    const _handleSetAgent = (agent: QAAgentEntity) => {
        setAgent(new QAAgentEntity(agent));
    }
    const _handleLastEnd = () => navigate(routes.agent.relativePathWithParams({ id: agent!.id }), {
        replace: true,
    });

    return <LayoutComponent>
        <div className="container">
            <div className="card mt-3 card-body">
                <HorizontalSteperComponent steps={[
                    "Create Agent",
                    "Add Questions",
                ]} changeStep={_changeStep} currentStep={step}>
                    <CreateAgentComponent onCreated={_handleCreateAgent} />
                    {agent ? <div>
                        <CategoriesEditionComponent agent={agent} setAgent={_handleSetAgent} />
                        <div className="w-100 d-flex justify-content-end mt-3">
                            <ButtonComponent design="primary" text={i18n(agent.categories.length > 0 ? KeyWordLocalization.CreateAgentPageFinish : KeyWordLocalization.CreateAgentPageSkip)} onClick={_handleLastEnd} />
                        </div>
                    </div> : <></>}
                </HorizontalSteperComponent>
            </div>
        </div>
    </LayoutComponent>
}

export default CreateAgentPage;