import { injectable } from "inversify";
import DashboardRepository from "../../repositories/dashboard.repository";
import ErrorEntity from "../../entities/error.entity";
import { Either } from "fp-ts/lib/Either";
import ClientAnalysisEntity from "../../entities/client_analysis.entity";
import QACommentEntity from "../../entities/qa_comment.entity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class AddCommentToAnalysisUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (_:{employeeID: string, comment: string}): Promise<Either<ErrorEntity, QACommentEntity>> => {
        return this.dashboardRepository.addComment(_);
    }
}

export default AddCommentToAnalysisUseCase;

export const AddCommentToAnalysisUseCaseName = "AddCommentToAnalysisUseCase";