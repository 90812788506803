import { injectable } from "inversify";
import DashboardRepository, { GetOperationReportResponse, Sentiments, SentimentsOnDate } from "../../repositories/dashboard.repository";
import { Either, isRight, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";

interface props {
    dashboardRepository: DashboardRepository;
}

@injectable()
class GetOperationReportUseCase {
    private dashboardRepository: DashboardRepository;

    constructor({ dashboardRepository }: props) {
        this.dashboardRepository = dashboardRepository;
    }

    public call = async (clientId: string | undefined, startDate: Date, endDate: Date, onGetOne: (data: GetOperationReportResponse) => void): Promise<Either<ErrorEntity, GetOperationReportResponse>> => {
        let data: GetOperationReportResponse = {};
        const _callCallDetails = async () => {
            const response = await this.dashboardRepository.getCallAverages(clientId, startDate, endDate);
            if (isRight(response)) {
                data.averages = response.right.averages;
                data.totalCalls = response.right.totalCalls;
                onGetOne(data);
            }
        }
        const _callTables = async () => {
            const response = await this.dashboardRepository.getTopAndBottomAgents({
                startDate, endDate
            });
            if (isRight(response)) {
                data.bestClients = response.right.best;
                data.worstClients = response.right.worst;
                onGetOne(data);
            }
        }

        const _callReasons = async () => {
            const response = await this.dashboardRepository.getReasonForCalls(clientId, startDate, endDate);
            if (isRight(response)) {
                data.reasonForCalls = response.right;
                onGetOne(data);
            }
        }

        const _callTarget = async () => {
            const response = await this.dashboardRepository.getScoreDetails(clientId, startDate, endDate);
            if (isRight(response)) {
                data.target = response.right;
                onGetOne(data);
            }
        }

        const _callSentiments = async () => {
            const response = await this.dashboardRepository.getSentiments(clientId, startDate, endDate);
            if (isRight(response)) {
                data.dayToDay = response.right;
                const sumDates = response.right.reduce((acc: Sentiments, val: SentimentsOnDate) => {
                    return {
                        positive: acc.positive + val.sentiments.positive,
                        negative: acc.negative + val.sentiments.negative,
                        neutral: acc.neutral + val.sentiments.neutral,
                    }
                }, {
                    positive: 0,
                    negative: 0,
                    neutral: 0
                });
                data.totalSentiments = sumDates;
                onGetOne(data);
            }
        }

        await Promise.all([
            _callCallDetails(),
            _callTables(),
            _callReasons(),
            _callTarget(),
            _callSentiments()
        ]);
        return right(data);
    }
}

export default GetOperationReportUseCase;

export const GetOperationReportUseCaseName = "GetOperationReportUseCase";