import { injectable } from "inversify";
import AuthRepository from "../../repositories/auth.repository";
import ErrorEntity from "../../entities/error.entity";
import UserEntity from "../../entities/user.entity";
import { Either, isRight } from "fp-ts/lib/Either";
import UserProvider from "../../providers/user/UserProvider";

interface _Props {
    userProvider: UserProvider;
    authRepository: AuthRepository;
}

@injectable()
export default class GetCurrentUserUseCase {
    private userProvider: UserProvider;
    private authRepository: AuthRepository;
    constructor(props: _Props) {
        this.userProvider = props.userProvider;
        this.authRepository = props.authRepository;
    }
    public call = async (): Promise<UserEntity | null> => {
        const currentUser = await this.authRepository.getCurrentUser();
        this.userProvider.Actions.setUser(currentUser);
        return currentUser;
    }
}

export const GetCurrentUserUseCaseName = "GetCurrentUserUseCase";