import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import QAQuestionEntity from "../../../../domain/entities/qa_question.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAQuestionDto from "../../../dto/qa_question.dto";

const UpdateQuestionApiImpl = async (agent: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
    try {
        const relativeUrl = `/question/${agent.id}`;
        const body = QAQuestionDto.toJSON(agent);
        const response = await di.get<HostApi>(HostApiName).put(relativeUrl, body, { contentType: 'application/json' });
        return right(QAQuestionDto.fromJSON(response));
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default UpdateQuestionApiImpl;