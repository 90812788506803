import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import QAQuestionEntity from "../../../../domain/entities/qa_question.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import QAQuestionDto from "../../../dto/qa_question.dto";
import QACategoryEntity from "../../../../domain/entities/qa_category.entity";

const createQuestionApiImpl = async (_:{ category: QACategoryEntity, question: QAQuestionEntity }): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
    try {
        const relativeUrl = "/question";
        const body = QAQuestionDto.toJSON(_.question);
        body['category_id'] = _.category.id;
        body['agent_id'] = _.category.agentId;
        //remove id from bod
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, { contentType: 'application/json' });
        return right(QAQuestionDto.fromJSON(response));
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default createQuestionApiImpl;