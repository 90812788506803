import { Either, right, left } from "fp-ts/lib/Either";
import HumanAgentRepository from "../../../domain/repositories/human_agent.repository";
import ErrorEntity from "../../../domain/entities/error.entity";
import HumanAgentEntity, { HumanAgentStatus } from "../../../domain/entities/human_agent.entity";
import SearchResultEntity from "../../../domain/entities/search_result.entity";
import { injectable } from "inversify";

export const fakeHumanAgent: HumanAgentEntity = {
    id: "1",
    name: "John Doe",
    agentId: "123",
    status: HumanAgentStatus.active,
};

@injectable()
class HumanAgentRepositoryFake implements HumanAgentRepository {
    private agents: HumanAgentEntity[] = [
        { id: "1", name: "John Doe", agentId: "123", status: HumanAgentStatus.active, },
        { id: "2", name: "Jane Smith", agentId: "456", status: HumanAgentStatus.active, },
        { id: "3", name: "Alice Johnson", agentId: "789", status: HumanAgentStatus.active, },
        // Agrega más agentes falsos si es necesario
    ];

    search = async (_:{query?: string, status?: HumanAgentStatus, page: number, itemsPerPage: number}): Promise<Either<ErrorEntity, SearchResultEntity<HumanAgentEntity>>> => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const limitPage = 3;
        const {page, itemsPerPage} = _;
        if (page <= 3) {
            return right({
                data: Array.from({ length: itemsPerPage }, (_, i) => ({
                    id: `${i + 1}`,
                    name: `Agent ${i + 1}`,
                    agentId: `${i + 1}`,
                    status: HumanAgentStatus.active,
                })),
                total: limitPage * itemsPerPage,
                itemsPerPage,
                page,
                pages: limitPage,
            });
        }

        return right({
            data: [],
            total: limitPage * itemsPerPage,
            itemsPerPage,
            page,
            pages: limitPage,
        });
    };

    delete = async (id: string): Promise<Either<ErrorEntity, void>> => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return right(undefined);
    };

    getById = async (id: string): Promise<Either<ErrorEntity, HumanAgentEntity>> => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const agent = this.agents.find(agent => agent.id === id);
        return agent ? right(agent) : left({ message: "Agent not found" });
    };

    create = async (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        //90% fail for testing purposes
        if (Math.random() > 0.8) {
            return left({ code: "Error creating the human" });
        }
        this.agents.push(agent);
        return right(agent);
    };

    update = async (agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>> => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const index = this.agents.findIndex(a => a.id === agent.id);
        if (index !== -1) {
            this.agents[index] = agent;
            return right(agent);
        }
        return left({ message: "Agent not found" });
    };
}

export default HumanAgentRepositoryFake;