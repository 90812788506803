import { injectable } from "inversify";
import AuthRepository from "../../../domain/repositories/auth.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../../domain/entities/error.entity";
import UserEntity from "../../../domain/entities/user.entity";
import loginWithUserAndPasswordApiImpl from "./api/login_with_user_and_password.api.impl";
import getCurrentUserApiImpl from "./api/get_current_user.api.impl";
import logoutApiImpl from "./api/logout.api.impl";

@injectable()
export default class AuthRepositoryImpl implements AuthRepository {
    getCurrentUser = (): Promise<UserEntity | null> => getCurrentUserApiImpl();
    loginWithEmailAndPassword = (email: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => loginWithUserAndPasswordApiImpl(email, password);
    loginWithGoogle(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    loginWithFacebook(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    loginWithApple(): Promise<Either<ErrorEntity, UserEntity>> {
        throw new Error("Method not implemented.");
    }
    logout = (): Promise<Either<ErrorEntity, void>> => logoutApiImpl();

}