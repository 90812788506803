import React, { useContext, useEffect, useState } from "react";
import RoutesComponentProps from "./RoutesComponentProps";
import LoadingComponent from "../components/LoadingComponent/loading.component";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import UserContext from "../providers/user/UserContext";
import UserContextType from "../../domain/providers/user/UserContextType";
import LayoutComponent from "../layouts/layoutComponent/layout.component";
import routes, { RouteEntity } from "./Routes";
import di from "../../dependency_injection";
import LoadUseCase, { LoadUseCaseName } from "../../domain/use_cases/default/LoadUseCase";
import ModalsComponent from "../providers/modal/modals/ModalsComponent";
import Error404Page from "../pages/error/error_404.page";


const RoutesComponent: React.FC<RoutesComponentProps> = ({ children }) => {
    const { user } = useContext(UserContext) as UserContextType;
    const [loaded, setLoaded] = useState<boolean>(true);

    const _load = async () => {
        try {
            await di.get<LoadUseCase>(LoadUseCaseName).call();
            setLoaded(true);
        } catch (_) {
        }
    }

    useEffect(() => {
        _load();
    }, []);

    if (!loaded || user === undefined) return <div className="bg_1" style={{ width: "100vw", height: "100vh" }}>
        <LoadingComponent fullScreen />
    </div>
    return <>
        <BrowserRouter>
            <Routes>
                {Object.values(routes).map((route: RouteEntity) => <Route key={route.path} path={route.path} element={
                    route.auth(user) ? <ModalsComponent>{React.createElement(route.component, {}, undefined)}</ModalsComponent> : <Navigate to={user ? routes.home.relativePath : routes.login.relativePath} />
                }>
                </Route>)}
                <Route path="*" element={<Error404Page />} />
            </Routes>
        </BrowserRouter >
    </>
}

export default RoutesComponent;
export { RoutesComponent };
