import { injectable } from "inversify";
import QARepository from "../../repositories/qa.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class DeleteCategoryUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (id: string) : Promise<Either<ErrorEntity, void>> => {
        return this.qaRepository.deleteCategory(id);
    }
}

export const DeleteCategoryUseCaseName = "DeleteCategoryUseCase";