import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { AverageCalls, ReasonForCalls } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import ReasonForCallDto from "../../../dto/reason_for_call.dto";

const getReasonsForCallApiImpl = async (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, ReasonForCalls[]>> => {
    try {

        const relativeUrl = `/stats/reason-detail?start_date=${DateDto.toServer(startDate)}&end_date=${DateDto.toServer(endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        const parsed = response.map((item: any) => ReasonForCallDto.fromJSON(item));
        return right(parsed);

    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default getReasonsForCallApiImpl;