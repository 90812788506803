import { Either, isLeft, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import generateAudioLinkApiImpl from "./generate_audio_link.api.impl";
import UploadAudioFileApiImpl from "./UploadAudioFileApiImpl";
import KeyWordLocalization from "../../../../presentation/utils/KeyWordLocalization";

const UploadAudioForTranscriptionApiImpl = async (audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>> => {
    try {
        const dataUrl = await generateAudioLinkApiImpl([audio.name], agentId);
        if (isLeft(dataUrl)) return dataUrl;
        const { url, jobID, audioName } = dataUrl.right;
        onCallBack?.(0, KeyWordLocalization.CheckAudioWithQuestionsUseCaseUploadingAudio);
        const responseUpload = await UploadAudioFileApiImpl(audio, url);
        if (isLeft(responseUpload)) return responseUpload;
        onCallBack?.(0, KeyWordLocalization.CheckAudioWithQuestionsUseCaseUploadedAudio);
        return right(jobID);
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default UploadAudioForTranscriptionApiImpl;