import LocalizationEntity from "../../../../../domain/entities/localization.entity";
import LocalizationList from "./dictionaries/LocalizationList";

const GetCurrentLanguageApiImpl = async (keyStorage: string): Promise<LocalizationEntity> => {
    //load json in host/'localization/en.json
    try{
        const response = await fetch(`/localization/en.json`);
        const data = await response.json();
        return {
            code: 'es',
            dictionary: data,
            image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-400.png',
            name: 'English'	
        }
    }catch(e){
        return {
            code: 'es',
            dictionary: LocalizationList[0],
            image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-400.png',
            name: 'English'
        }
    }
}

export default GetCurrentLanguageApiImpl;