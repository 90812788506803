import { FC, useContext, useEffect, useState } from "react"
import CategoriesEditionComponentProps from "./categories_edition.component.props";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from "../../../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../../../providers/modal/ModalsContext";
import QACategoryEntity from "../../../../../../domain/entities/qa_category.entity";
import QAQuestionEntity from "../../../../../../domain/entities/qa_question.entity";
import AccordionComponent from "../../../../../components/accordion/accordion.component";
import ButtonComponent from "../../../../../components/button/button.component";
import KeyWordLocalization from "../../../../../utils/KeyWordLocalization";
import { category } from "fp-ts";
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../../../dependency_injection";
import CreateQuestionForAgentUseCase, { CreateQuestionForAgentUseCaseName } from "../../../../../../domain/use_cases/qa/create_question_for_agent.use_case";
import CreateQuestionComponent from "../question_creation/create_question.component";
import UpdateQuestionForAgentUseCase, { UpdateQuestionForAgentUseCaseName } from "../../../../../../domain/use_cases/qa/update_question_for_agent.use_case";
import DeleteQuestionFromAgentUseCase, { DeleteQuestionFromAgentUseCaseName } from "../../../../../../domain/use_cases/qa/delete_question_from_agent.use_case";
import CreateCategoryUseCase, { CreateCategoryUseCaseName } from "../../../../../../domain/use_cases/qa/create_category.use_case";
import CategoryCreationComponent from "../category_creation/category_creation.component";
import UpdateCategoryUseCase, { UpdateCategoryUseCaseName } from "../../../../../../domain/use_cases/qa/update_category.use_case";
import DeleteCategoryUseCase, { DeleteCategoryUseCaseName } from "../../../../../../domain/use_cases/qa/delete_category.use_case";

const CategoriesEditionComponent: FC<CategoriesEditionComponentProps> = ({ agent, setAgent }) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const openDefault = queryParameters.get("open");

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const { addToast, openModalCustom, closeModalCustom, openModalDelete, closeModalDelete } = useContext(ModalsContext) as ModalsContextType;
    const _canBeUsed = (agent?.categories.length ?? 0) > 0;



    const _handleAddCategory = () => {
        const _addCategory = async (category: QACategoryEntity) => {
            const response = await di.get<CreateCategoryUseCase>(CreateCategoryUseCaseName).call(category);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentCategoryError), 'error', undefined);
            agent.categories.push(response.right);
            closeModalCustom();
            setAgent(agent);
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentCategoryAdded), 'success', undefined);
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CategoriesEditionComponentCreateCategory), <CategoryCreationComponent onSave={_addCategory} agentId={agent.id} />, {hideOnTap: false});
    }

    const _handleAddQuestion = (category: QACategoryEntity) => {
        const _addQuestion = async (question: QAQuestionEntity) => {
            const response = await di.get<CreateQuestionForAgentUseCase>(CreateQuestionForAgentUseCaseName).call({category: category, question: question});
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentQuestionError), 'error', undefined);
            closeModalCustom();
            category.questions.push(response.right);
            setAgent(agent);
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentQuestionAdded), 'success', undefined);
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CategoriesEditionComponentCreateQuestion), <CreateQuestionComponent onSave={_addQuestion} question={undefined} categoryId={category?.id} />);
    }

    const _handleDeleteQuestion = (category: QACategoryEntity, question: QAQuestionEntity) => {
        const _deleteQuestion = async () => {
            const response = await di.get<DeleteQuestionFromAgentUseCase>(DeleteQuestionFromAgentUseCaseName).call(question.id);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentDeleteQuestionError), 'error', undefined);
            closeModalDelete();
            const copy = category.questions.filter((item) => item.id != question.id);
            category.questions = copy;
            setAgent(agent); //todo test
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentQuestionDeleted), 'success', undefined);
        }
        openModalDelete(i18n(KeyWordLocalization.CategoriesEditionComponentDeleteQuestionTitle), i18n(KeyWordLocalization.CategoriesEditionComponentDeleteQuestionSubtitle), _deleteQuestion);
    }

    const _handleEditQuestion = (category: QACategoryEntity, questionPrev: QAQuestionEntity) => {
        const _editQuestion = async (question: QAQuestionEntity) => {
            const response = await di.get<UpdateQuestionForAgentUseCase>(UpdateQuestionForAgentUseCaseName).call(question);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentUpdateQuestionError), 'error', undefined);
            const index = category.questions.findIndex((item) => item.id == question.id);
            if (index == -1) return;
            closeModalCustom();
            category.questions[index] = response.right;
            setAgent(agent);
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentQuestionUpdated), 'success', undefined);
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CategoriesEditionComponentEditQuestion), <CreateQuestionComponent onSave={_editQuestion} question={questionPrev} />);
    }

    const _handleEditCategory = (category: QACategoryEntity, event: React.MouseEvent) => {
        event.stopPropagation();
        const _editCategory = async (category: QACategoryEntity) => {
            const response = await di.get<UpdateCategoryUseCase>(UpdateCategoryUseCaseName).call(category);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentCategoryError), 'error', undefined);
            const index = agent.categories.findIndex((item) => item.id == category.id);
            if (index == -1) return;
            closeModalCustom();
            agent.categories[index] = response.right;
            setAgent(agent);
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentCategoryUpdated), 'success', undefined);
        }
        openModalCustom("lg", i18n(KeyWordLocalization.CategoriesEditionComponentEditCategory), <CategoryCreationComponent onSave={_editCategory} category={category} agentId={agent.id} />);
    }

    const _handleDeleteCategory = (category: QACategoryEntity, event: React.MouseEvent) => {
        event.stopPropagation();
        const _deleteCategory = async () => {
            const response = await di.get<DeleteCategoryUseCase>(DeleteCategoryUseCaseName).call(category.id);
            if (isLeft(response)) return addToast(i18n(response.left.code ?? KeyWordLocalization.CategoriesEditionComponentDeleteCategoryError), 'error', undefined);
            closeModalDelete();
            const copy = agent.categories.filter((item) => item.id != category.id);
            agent.categories = copy;
            setAgent(agent); //todo test
            addToast(i18n(KeyWordLocalization.CategoriesEditionComponentCategoryDeleted), 'success', undefined);
        }
        openModalDelete(i18n(KeyWordLocalization.CategoriesEditionComponentDeleteCategoryTitle), i18n(KeyWordLocalization.CategoriesEditionComponentDeleteCategorySubtitle), _deleteCategory);
    }

    useEffect(() => {
        if (openDefault == "1" && agent.categories.length == 0) _handleAddCategory();
    }, [agent]);

    if (!_canBeUsed) return <div className="row">
        <div className="col-12 my-3">
            <div className="card-body text-center">
                {i18n(KeyWordLocalization.CategoriesEditionComponentNoCategoriesCreated)}
                <div className="d-flex mt-4 justify-content-center">
                    <ButtonComponent onClick={_handleAddCategory} icon={
                        <span className="material-symbols-outlined">edit</span>
                    } text={i18n(KeyWordLocalization.CategoriesEditionComponentAddQuestions)} />
                </div>
            </div>
        </div>
    </div>
    return <div className="categories_edition_component">
        <div className="w-100 px-3 my-3 d-flex justify-content-between align-items-center">
            <div className="flex-grow-1 text_ellipsis"><h5>{i18n(KeyWordLocalization.CategoriesEditionComponentCategories)}</h5></div>
            <ButtonComponent icon={
                <span className="material-symbols-outlined">add</span>
            } type="button" design="primary" text={i18n(KeyWordLocalization.CategoriesEditionComponentAddCategory)} onClick={_handleAddCategory} />


        </div> <AccordionComponent body={agent.categories.map((category) => {
            return {
                header: <div>
                    <div className="flex-grow-1 d-flex overflow-x-hidden text_ellipsis">
                        <div className="flex-grow-1 text_ellipsis">
                            {category.title} {category.questions.length > 0 ? `(${category.getMaxQuestionPoints()} ${i18n(KeyWordLocalization.DetailedAgentPagePoints)})` : ""}
                        </div>
                        <div>
                            {category.questions.length == 0 && <span className="badge bg-danger ms-2">{i18n(KeyWordLocalization.CategoriesEditionComponentNoQuestions)}</span>}
                        </div>
                    </div>
                    <div className="d-flex align-items-center me-3">
                        <ButtonComponent onClick={(e) => _handleEditCategory(category, e)} icon={
                            <span className="material-symbols-outlined">edit</span>
                        } type="button" design="primaryOutline" className="mx-2" text={i18n(KeyWordLocalization.CategoriesEditionComponentEdit)} />
                        <ButtonComponent icon={
                            <span className="material-symbols-outlined">delete</span>
                        } type="button" design="dangerOutline" text={i18n(KeyWordLocalization.CategoriesEditionComponentDelete)}
                            onClick={(e) => _handleDeleteCategory(category, e)} />
                    </div>
                </div>,
                content: <div>
                    <div className="category_description mb-3">
                        {category.description}
                    </div>
                    <div className="card p-2">
                        <div className="w-100 d-flex align-items-center justify-content-between ps-3 pe-3 ">
                            <div className="flex-grow-1 text_ellipsis">
                                <h5 className="m-0">
                                    {i18n(KeyWordLocalization.CategoriesEditionComponentQuestions)}
                                </h5>
                            </div>
                            <div className="d-flex align-items-center">
                                <ButtonComponent icon={
                                    <span className="material-symbols-outlined">add</span>
                                } type="button" design="primaryOutline" className="mx-2" text={i18n(KeyWordLocalization.CategoriesEditionComponentAddQuestion)} onClick={() => { _handleAddQuestion(category); }} />
                            </div>
                        </div>
                        <AccordionComponent body={category.questions.map((question, index) => {
                            return {
                                header: <div className="w-100 d-flex justify-content-between align-items-center">
                                    <div className="flex-grow-1 text_ellipsis">
                                        <h6>{question.title} ({question.points} {i18n(KeyWordLocalization.CategoriesEditionComponentQuestionPoints)})</h6>
                                    </div>
                                    <div className="d-flex align-items-center me-2">
                                        <ButtonComponent icon={
                                            <span className="material-symbols-outlined">edit</span>
                                        } type="button" design="primaryOutline" className="mx-2" text={i18n(KeyWordLocalization.CategoriesEditionComponentEdit)} onClick={(e) => { e.stopPropagation(); _handleEditQuestion(category, question); }} />
                                        <ButtonComponent icon={
                                            <span className="material-symbols-outlined">delete</span>
                                        } type="button" design="dangerOutline" text={i18n(KeyWordLocalization.CategoriesEditionComponentDelete)}
                                            onClick={(e) => { e.stopPropagation(); _handleDeleteQuestion(category, question); }} />
                                    </div>
                                </div>,
                                content: <div className="w-100">
                                    <p>{question.description}</p>
                                </div>
                            }
                        })} /></div>
                </div>
            }
        })} />
    </div>

}
export default CategoriesEditionComponent;