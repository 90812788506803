import { injectable } from "inversify";
import HumanAgentRepository from "../../repositories/human_agent.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";

interface Props {
    humanAgentRepository: HumanAgentRepository;
}

@injectable()
class DeleteHumanAgentUseCase {
    private humanAgentRepository: HumanAgentRepository;

    constructor({ humanAgentRepository }: Props) {
        this.humanAgentRepository = humanAgentRepository;
    }

    public call = async (id: string): Promise<Either<ErrorEntity, void>> => {
        return this.humanAgentRepository.delete(id);
    }
}

export default DeleteHumanAgentUseCase;

export const DeleteHumanAgentUseCaseName = "DeleteHumanAgentUseCase";