import { injectable } from "inversify";
import HumanAgentRepository from "../../repositories/human_agent.repository";
import { Either, isLeft } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import HumanAgentEntity from "../../entities/human_agent.entity";

interface Props {
    humanAgentRepository: HumanAgentRepository;
}

@injectable()
class BulkImportHumanAgentsUseCase {
    private humanAgentRepository: HumanAgentRepository;

    constructor({ humanAgentRepository }: Props) {
        this.humanAgentRepository = humanAgentRepository;
    }

    public call = async (agents: HumanAgentEntity[], onUpdateOne: (response: {agent: HumanAgentEntity, error?: string})=>void): Promise<{agent: HumanAgentEntity, error?: string}[]> => {
        //for each agent, call the repository to create it 
        let responses: {agent: HumanAgentEntity, error?: string}[] = [];
        for (let agent of agents) {
            const response = await this.humanAgentRepository.create(agent);
            const parsed = {
                agent,
                error: isLeft(response) ? response.left.code : undefined
            };
            onUpdateOne(parsed);
            responses.push(parsed);
        }

        return responses;
    }
}

export default BulkImportHumanAgentsUseCase;

export const BulkImportHumanAgentsUseCaseName = "BulkImportHumanAgentsUseCase";