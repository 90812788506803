import { Either, left, right } from "fp-ts/lib/Either";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import ErrorEntity from "../../../../domain/entities/error.entity";
import QAQuestionDto from "../../../dto/qa_question.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const createAgentApiImpl = async (_: {
    name: string,
    description: string,
}): Promise<Either<ErrorEntity, QAAgentEntity>> => {
    try {
        const relativeUrl = "/agent";
        const body = {
            "name": _.name,
            "description": _.description,
        };
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, {
            contentType: "application/json",
        });

        const parsed = new QAAgentEntity({
            id: response.id,
            name: response.name,
            description: response.description,
            clientId: response.clientId,
            categories: [],
        });
        console.log("response create parsed", parsed);
        return right(parsed);
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default createAgentApiImpl;