import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './error_404.page.style.scss';
import UserContextType from '../../../domain/providers/user/UserContextType';
import UserContext from '../../providers/user/UserContext';
import LayoutComponent from '../../layouts/layoutComponent/layout.component';

const _ErrorComponent: React.FC = () => {
    return <div className="error-404">
        <h1>Error 404</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <Link to="/">Go back to Home</Link>
    </div>
}
const Error404Page: React.FC = () => {
    const { user } = useContext(UserContext) as UserContextType;

    if(user) {
        return <LayoutComponent>
            <_ErrorComponent />
        </LayoutComponent>
    }
    return <_ErrorComponent />
}

export default Error404Page;