import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import UserEntity from "../../../../domain/entities/user.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import UserDto from "../../../dto/user.dto";
import KeyWordLocalization from "../../../../presentation/utils/KeyWordLocalization";

const loginWithUserAndPasswordApiImpl = async (user: string, password: string): Promise<Either<ErrorEntity, UserEntity>> => {
    try {
        const relativeRoute = "/auth/token";
        const response = await di.get<HostApi>(HostApiName).post(relativeRoute, `username=${user}&password=${password}`);
        if(response.detail == "Invalid username or password") return left({code: KeyWordLocalization.ErrorHostInvalidUsernameOrPassword});
        di.get<HostApi>(HostApiName).setToken(response.access_token);
        const userParsed = UserDto.fromJSON(response.user_info);
        return right(userParsed);
    } catch (e) {
        return left(ErrorEntity.fromError(e));
    }
}

export default loginWithUserAndPasswordApiImpl;