import { Container } from "inversify";
import "reflect-metadata";
import Constants, { ConstantsName } from "./data/settings/constants/Constants";
import ConstantsFake from "./data/settings/constants/ConstantsFake";
import HostApi, { HostApiName } from "./data/settings/HostApi";
import ConstantsDev from "./data/settings/constants/ConstantsDev";
import ConstantsImpl from "./data/settings/constants/ConstantsImpl";
import LocalizationRepository, { LocalizationRepositoryName } from "./domain/repositories/localization.repository";
import LocalizationRepositoryFake from "./data/repositories/localization/localization.repository.fake";
import LocalizationRepositoryDev from "./data/repositories/localization/localization.repository.dev";
import LocalizationProvider, { LocalizationProviderName } from "./domain/providers/localization/LocalizationProvider";
import LocalizationProviderImpl from "./presentation/providers/localization/LocalizationProviderImpl";
import UserProvider, { UserProviderName } from "./domain/providers/user/UserProvider";
import UserProviderImpl from "./presentation/providers/user/UserProviderImpl";
import ModalsProvider, { ModalsProviderName } from "./domain/providers/modal/ModalsProvider";
import ModalsProviderImpl from "./presentation/providers/modal/ModalsProviderImpl";
import LoadUseCase, { LoadUseCaseName } from "./domain/use_cases/default/LoadUseCase";
import GetAvailableLanguagesUseCase, { GetAvailableLanguagesUseCaseName } from "./domain/use_cases/localization/GetAvailableLanguagesUseCase";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "./domain/use_cases/localization/GetCurrentLanguageUseCase";
import SetCurrentLanguageUseCase, { SetCurrentLanguageUseCaseName } from "./domain/use_cases/localization/SetCurrentLanguageUseCase";
import LoginWithEmailAndPasswordUseCase, { LoginWithEmailAndPasswordUseCaseName } from "./domain/use_cases/auth/LoginWithEmailAndPasswordUseCase";
import AuthRepository, { AuthRepositoryName } from "./domain/repositories/auth.repository";
import LogoutUseCase, { LogoutUseCaseName } from "./domain/use_cases/auth/LogoutUseCase";
import AuthRepositoryFake from "./data/repositories/auth/auth.repository.fake";
import AuthRepositoryDev from "./data/repositories/auth/auth.repository.dev";
import AuthRepositoryImpl from "./data/repositories/auth/auth.repository.impl";
import GetCurrentUserUseCase, { GetCurrentUserUseCaseName } from "./domain/use_cases/auth/GetCurrentUserUseCase";
import QARepository, { QARepositoryName } from "./domain/repositories/qa.repository";
import QARepositoryFake from "./data/repositories/qa/qa.repository.fake";
import QARepositoryDev from "./data/repositories/qa/qa.repository.dev";
import QARepositoryImpl from "./data/repositories/qa/qa.repository.impl";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "./domain/use_cases/qa/search_agents.use_case";
import GetAgentByIdUseCase, { GetAgentByIdUseCaseName } from "./domain/use_cases/qa/get_agent_by_id.use_case";
import DeleteAgentUseCase, { DeleteAgentUseCaseName } from "./domain/use_cases/qa/delete_agent.use_case";
import CreateAgentUseCase, { CreateAgentUseCaseName } from "./domain/use_cases/qa/create_agent.use_case";
import UpdateAgentUseCase, { UpdateAgentUseCaseName } from "./domain/use_cases/qa/update_agent.use_case";
import CheckAudioWithQuestionsUseCase, { CheckAudioWithQuestionsUseCaseName } from "./domain/use_cases/qa/check_audio_with_questions.use_case";
import UpdateQuestionForAgentUseCase, { UpdateQuestionForAgentUseCaseName } from "./domain/use_cases/qa/update_question_for_agent.use_case";
import CreateQuestionForAgentUseCase, { CreateQuestionForAgentUseCaseName } from "./domain/use_cases/qa/create_question_for_agent.use_case";
import DeleteQuestionFromAgentUseCase, { DeleteQuestionFromAgentUseCaseName } from "./domain/use_cases/qa/delete_question_from_agent.use_case";
import GetClientsListUseCase, { GetClientsListUseCaseName } from "./domain/use_cases/dashboard/get_clients_list.use_case";
import DashboardRepository, { DashboardRepositoryName } from "./domain/repositories/dashboard.repository";
import GetOperationReportUseCase, { GetOperationReportUseCaseName } from "./domain/use_cases/dashboard/get_operation_report.use_case";
import DashboardRepositoryFake from "./data/repositories/dashboard/dashboard.repository.fake";
import DashboardRepositoryDev from "./data/repositories/dashboard/dashboard.repository.dev";
import DashboardRepositoryImpl from "./data/repositories/dashboard/dashboard.repository.impl";
import GetAgentAnalysisUseCase, { GetAgentAnalysisUseCaseName } from "./domain/use_cases/dashboard/get_agent_analysis_details.use_case";
import LocalizationRepositoryImpl from "./data/repositories/localization/localization.repository.impl";
import CreateCategoryUseCase, { CreateCategoryUseCaseName } from "./domain/use_cases/qa/create_category.use_case";
import DeleteCategoryUseCase, { DeleteCategoryUseCaseName } from "./domain/use_cases/qa/delete_category.use_case";
import UpdateCategoryUseCase, { UpdateCategoryUseCaseName } from "./domain/use_cases/qa/update_category.use_case";
import AddCommentToAnalysisUseCase, { AddCommentToAnalysisUseCaseName } from "./domain/use_cases/dashboard/add_comment_to_analysis.use_case";
import HumanAgentRepository, { HumanAgentRepositoryName } from "./domain/repositories/human_agent.repository";
import BulkImportHumanAgentsUseCase, { BulkImportHumanAgentsUseCaseName } from "./domain/use_cases/human_agent/bulk_import_human_agents.use_case";
import CreateHumanAgentUseCase, { CreateHumanAgentUseCaseName } from "./domain/use_cases/human_agent/create_human_agent.use_case";
import DeleteHumanAgentUseCase, { DeleteHumanAgentUseCaseName } from "./domain/use_cases/human_agent/delete_human_agent.use_case";
import GetHumanAgentByIdUseCase, { GetHumanAgentByIdUseCaseName } from "./domain/use_cases/human_agent/get_human_agent_by_id.use_case";
import SearchHumanAgentsUseCase, { SearchHumanAgentsUseCaseName } from "./domain/use_cases/human_agent/search_human_agents.use_case";
import UpdateHumanAgentUseCase, { UpdateHumanAgentUseCaseName } from "./domain/use_cases/human_agent/update_human_agent.use_case";
import HumanAgentRepositoryFake from "./data/repositories/human_agent/human_agent.repository.fake";
import HumanAgentRepositoryImpl from "./data/repositories/human_agent/human_agent.repository.impl";
import HumanAgentRepositoryDev from "./data/repositories/human_agent/human_agent.repository.dev";
import GetAudioResultUseCase, { GetAudioResultUseCaseName } from "./domain/use_cases/qa/get_audio_result.use_case";
import SearchRankingHumanAgentsUseCase, { SearchRankingHumanAgentsUseCaseName } from "./domain/use_cases/dashboard/search_ranking_human_agents.use_case";

enum MODE_DI { PRODUCTION, DEVELOPMENT, TEST, FAKE }

let mode = MODE_DI.DEVELOPMENT.toString();
const di = new Container();
//#region ------------------ CONSTANTS ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsFake);
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsDev);
} else {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsImpl);
}

di.bind<HostApi>(HostApiName).to(HostApi).inSingletonScope();
//#endregion ------------------ CONSTANTS ------------------ 0//

//#region ------------------ REPOSITORIES ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryFake).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryFake).inSingletonScope();
    di.bind<HumanAgentRepository>(HumanAgentRepositoryName).to(HumanAgentRepositoryFake).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryFake).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryFake).inSingletonScope();
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryDev).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryDev).inSingletonScope();
    di.bind<HumanAgentRepository>(HumanAgentRepositoryName).to(HumanAgentRepositoryDev).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryDev).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryDev).inSingletonScope();
} else {
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryImpl).inSingletonScope();
    di.bind<DashboardRepository>(DashboardRepositoryName).to(DashboardRepositoryImpl).inSingletonScope();
    di.bind<HumanAgentRepository>(HumanAgentRepositoryName).to(HumanAgentRepositoryImpl).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryImpl).inSingletonScope();
    di.bind<QARepository>(QARepositoryName).to(QARepositoryImpl).inSingletonScope();
}
//#endregion ------------------ REPOSITORIES ------------------ //

//#region ------------------ PROVIDERS ------------------ //   
di.bind<LocalizationProvider>(LocalizationProviderName).toConstantValue(LocalizationProviderImpl);
di.bind<ModalsProvider>(ModalsProviderName).toConstantValue(ModalsProviderImpl);
di.bind<UserProvider>(UserProviderName).toConstantValue(UserProviderImpl);
//#endregion ------------------ PROVIDERS ------------------ //   


//#region ------------------ USE CASES ------------------//
//#region ------------------ Authentication ------------------ //
di.bind<GetCurrentUserUseCase>(GetCurrentUserUseCaseName).toDynamicValue((context) => {
    return new GetCurrentUserUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
di.bind<LoginWithEmailAndPasswordUseCase>(LoginWithEmailAndPasswordUseCaseName).toDynamicValue((context) => {
    return new LoginWithEmailAndPasswordUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
di.bind<LogoutUseCase>(LogoutUseCaseName).toDynamicValue((context) => {
    return new LogoutUseCase({
        authRepository: context.container.get<AuthRepository>(AuthRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Authentication ------------------ //
//#region ------------------ Dashboard ------------------ //
di.bind<AddCommentToAnalysisUseCase>(AddCommentToAnalysisUseCaseName).toDynamicValue((context) => {
    return new AddCommentToAnalysisUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
di.bind<GetAgentAnalysisUseCase>(GetAgentAnalysisUseCaseName).toDynamicValue((context) => {
    return new GetAgentAnalysisUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
di.bind<GetClientsListUseCase>(GetClientsListUseCaseName).toDynamicValue((context) => {
    return new GetClientsListUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
di.bind<GetOperationReportUseCase>(GetOperationReportUseCaseName).toDynamicValue((context) => {
    return new GetOperationReportUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
di.bind<SearchRankingHumanAgentsUseCase>(SearchRankingHumanAgentsUseCaseName).toDynamicValue((context) => {
    return new SearchRankingHumanAgentsUseCase({
        dashboardRepository: context.container.get<DashboardRepository>(DashboardRepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ Dashboard ------------------ //
//#region ------------------ Default ------------------ //
di.bind<LoadUseCase>(LoadUseCaseName).toDynamicValue((context) => {
    return new LoadUseCase({
    });
}).inSingletonScope();
//#endregion ------------------ Default ------------------ //
//#region ------------------ Human Agent ------------------ //
di.bind<DeleteHumanAgentUseCase>(DeleteHumanAgentUseCaseName).toDynamicValue((context) => {
    return new DeleteHumanAgentUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();

di.bind<SearchHumanAgentsUseCase>(SearchHumanAgentsUseCaseName).toDynamicValue((context) => {
    return new SearchHumanAgentsUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();

di.bind<GetHumanAgentByIdUseCase>(GetHumanAgentByIdUseCaseName).toDynamicValue((context) => {
    return new GetHumanAgentByIdUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();

di.bind<CreateHumanAgentUseCase>(CreateHumanAgentUseCaseName).toDynamicValue((context) => {
    return new CreateHumanAgentUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();

di.bind<UpdateHumanAgentUseCase>(UpdateHumanAgentUseCaseName).toDynamicValue((context) => {
    return new UpdateHumanAgentUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();

di.bind<BulkImportHumanAgentsUseCase>(BulkImportHumanAgentsUseCaseName).toDynamicValue((context) => {
    return new BulkImportHumanAgentsUseCase({
        humanAgentRepository: context.container.get<HumanAgentRepository>(HumanAgentRepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ Human Agent ------------------ ////#region ------------------ Localization ------------------ //
di.bind<GetAvailableLanguagesUseCase>(GetAvailableLanguagesUseCaseName).toDynamicValue((context) => {
    return new GetAvailableLanguagesUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<GetCurrentLanguageUseCase>(GetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new GetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<SetCurrentLanguageUseCase>(SetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new SetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Localization ------------------ //
//#region ------------------ QA ------------------ //
di.bind<CheckAudioWithQuestionsUseCase>(CheckAudioWithQuestionsUseCaseName).toDynamicValue((context) => {
    return new CheckAudioWithQuestionsUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<CreateAgentUseCase>(CreateAgentUseCaseName).toDynamicValue((context) => {
    return new CreateAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<CreateCategoryUseCase>(CreateCategoryUseCaseName).toDynamicValue((context) => {
    return new CreateCategoryUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<CreateQuestionForAgentUseCase>(CreateQuestionForAgentUseCaseName).toDynamicValue((context) => {
    return new CreateQuestionForAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<DeleteAgentUseCase>(DeleteAgentUseCaseName).toDynamicValue((context) => {
    return new DeleteAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<DeleteCategoryUseCase>(DeleteCategoryUseCaseName).toDynamicValue((context) => {
    return new DeleteCategoryUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<DeleteQuestionFromAgentUseCase>(DeleteQuestionFromAgentUseCaseName).toDynamicValue((context) => {
    return new DeleteQuestionFromAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<GetAgentByIdUseCase>(GetAgentByIdUseCaseName).toDynamicValue((context) => {
    return new GetAgentByIdUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<GetAudioResultUseCase>(GetAudioResultUseCaseName).toDynamicValue((context) => {
    return new GetAudioResultUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<SearchAgentsUseCase>(SearchAgentsUseCaseName).toDynamicValue((context) => {
    return new SearchAgentsUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateAgentUseCase>(UpdateAgentUseCaseName).toDynamicValue((context) => {
    return new UpdateAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateCategoryUseCase>(UpdateCategoryUseCaseName).toDynamicValue((context) => {
    return new UpdateCategoryUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateQuestionForAgentUseCase>(UpdateQuestionForAgentUseCaseName).toDynamicValue((context) => {
    return new UpdateQuestionForAgentUseCase({
        qaRepository: context.container.get<QARepository>(QARepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ QA ------------------ //
//#endregion ------------------ USE CASES ------------------//

export default di;