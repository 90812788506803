import { useContext, useState } from "react";
import "./layout.component.style.scss";
import LayoutComponentProps from "./layout.component.props"
import LayoutSideBarComponent from "./components/sidebar/layout_side_bar.component";
import LayoutHeaderComponent from "./components/header/LayoutHeaderComponent";

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children, title }) => {
    const [open, setOpen] = useState<boolean>(false);
    return <div className={`layout_component ${open && 'open'}`}>
        <LayoutHeaderComponent title={title} open={open} setOpen={setOpen} />
        <div className="layout_scroll">
            <LayoutSideBarComponent open={open} setOpen={setOpen} />
            <div className="layout_content">
                {children}
            </div>
        </div>
    </div>
}

export default LayoutComponent;