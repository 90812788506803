import React from "react";
import LocalizationList from "../../../data/repositories/localization/api/impl/dictionaries/LocalizationList";
import LocalizationEntity from "../../../domain/entities/localization.entity";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";

export const defaultLocalizationContext: LocalizationContextType = {
    localizations: [],
    localization: LocalizationList[0],
    i18n: (keyWord: string, values?: any) => keyWord,
    setLocalization: (localization: LocalizationEntity) => {},
    setLocalizations: (localization: LocalizationEntity[]) => {}

};

const LocalizationContext = React.createContext<LocalizationContextType>(defaultLocalizationContext);

export default LocalizationContext;