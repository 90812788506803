import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../entities/error.entity";
import QAAgentEntity from "../entities/qa_agent.entity";
import QAResultEntity from "../entities/qa_result.entity";
import QAQuestionEntity from "../entities/qa_question.entity";
import SearchResultEntity from "../entities/search_result.entity";
import QACategoryEntity from "../entities/qa_category.entity";

export default interface QARepository {
    uploadAudioForTranscription(audio: File, agentId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, string>>;
    getAudioResults(jobId: string, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>>;
    createAgent(_: {
        name: string,
        description: string,
    }): Promise<Either<ErrorEntity, QAAgentEntity>>;
    searchAgents(query: string | undefined, page: number, itemsPerPage: number): Promise<Either<ErrorEntity, SearchResultEntity<QAAgentEntity>>>;
    getAgentById(id: string): Promise<Either<ErrorEntity, QAAgentEntity>>;
    addQuestionToAgent(_: { category: QACategoryEntity, question: QAQuestionEntity }): Promise<Either<ErrorEntity, QAQuestionEntity>>;
    deleteQuestionFromAgent(questionId: string): Promise<Either<ErrorEntity, void>>;
    updateQuestionFromAgent(question: QAQuestionEntity): Promise<Either<ErrorEntity, QAQuestionEntity>>;
    deleteAgent(id: string): Promise<Either<ErrorEntity, void>>
    updateAgent(agent: QAAgentEntity): Promise<Either<ErrorEntity, QAAgentEntity>>;
    addCategory(qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>>;
    deleteCategory(id: string): Promise<Either<ErrorEntity, void>>
    updateCategory(qaCategory: QACategoryEntity): Promise<Either<ErrorEntity, QACategoryEntity>>;
}

export const QARepositoryName = "QARepository";