import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../entities/error.entity";
import HumanAgentEntity, { HumanAgentStatus } from "../entities/human_agent.entity";
import SearchResultEntity from "../entities/search_result.entity";

export default interface HumanAgentRepository {
    search(_:{query?: string, status?: HumanAgentStatus, page: number, itemsPerPage: number}): Promise<Either<ErrorEntity, SearchResultEntity<HumanAgentEntity>>>;
    delete(id: string): Promise<Either<ErrorEntity, void>>;
    getById(id: string): Promise<Either<ErrorEntity, HumanAgentEntity>>;
    create(agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>>;
    update(agent: HumanAgentEntity): Promise<Either<ErrorEntity, HumanAgentEntity>>;
}

export const HumanAgentRepositoryName = "HumanAgentRepository";