import { injectable } from "inversify";
import QARepository from "../../repositories/qa.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import QAQuestionEntity from "../../entities/qa_question.entity";
import QACategoryEntity from "../../entities/qa_category.entity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class CreateQuestionForAgentUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (_: { category: QACategoryEntity, question: QAQuestionEntity }): Promise<Either<ErrorEntity, QAQuestionEntity>> => {
        return this.qaRepository.addQuestionToAgent(_);
    }
}

export const CreateQuestionForAgentUseCaseName = "CreateQuestionForAgentUseCase";