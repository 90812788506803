import { useNavigate, useParams } from "react-router-dom";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import "./agent_list.page.style.scss";
import { FC, useContext, useEffect, useState } from "react";
import SearchResultEntity from "../../../../domain/entities/search_result.entity";
import QAAgentEntity from "../../../../domain/entities/qa_agent.entity";
import di from "../../../../dependency_injection";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "../../../../domain/use_cases/qa/search_agents.use_case";
import { isLeft } from "fp-ts/lib/Either";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import routes from "../../../routes/Routes";
import PaginatorComponent from "../../../components/paginator/PaginatorComponent";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";
import ButtonComponent from "../../../components/button/button.component";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import NotResultsComponent from "../../../components/noResults/not_results.component";
import ModalsContext from "../../../providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import DeleteAgentUseCase, { DeleteAgentUseCaseName } from "../../../../domain/use_cases/qa/delete_agent.use_case";

const AgentListPage: FC<{}> = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchQuery = searchParams.get("search");
  const pageQuery = parseInt(searchParams.get("page") ?? "1");
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  const { addToast, openModalDelete, closeModalDelete } = useContext(ModalsContext) as ModalsContextType;
  const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
  const [result, setResult] = useState<SearchResultEntity<QAAgentEntity> | undefined>(undefined);
  const navigate = useNavigate();
  const itemsPerPage = 20;
  let _timerTap: any;

  const _searchAgents = async () => {
    const data = getValues();
    clearTimeout(_timerTap);
    setResult(undefined);
    const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(data[KeyWordLocalization.AgentListPageQuery], data[KeyWordLocalization.AgentListCurrentPage], itemsPerPage);
    if (isLeft(response)) {
      setResult({
        data: [],
        page: 1,
        total: 0,
        itemsPerPage: 0,
        pages: 0,
      })
      return;
    }
    setResult(response.right);
  }


  const _handleSearch = (data: any) => {
    //set current input value to search param
    const search = data[KeyWordLocalization.AgentListPageQuery];
    const page = data[KeyWordLocalization.AgentListCurrentPage];
    let query = "?";
    if (search && search.length > 0) query += `search=${search}&`;
    if (page) query += `page=${page}&`;
    if (query.endsWith("&")) query = query.slice(0, -1);
    navigate(routes.agent_list.relativePath + query);
  }

  const _handleChangeTextSearch = () => {
    //add timing to call on change with the idea of gave space to search
    clearTimeout(_timerTap);
    const data = getValues();
    _timerTap = setTimeout(() => _handleSearch(data), 500);
  }

  const _handleLoad = () => {
    setValue(KeyWordLocalization.AgentListPageQuery, searchQuery);
    setValue(KeyWordLocalization.AgentListCurrentPage, pageQuery);
    _searchAgents();
  }

  const _handleOnChangePage = (page: number) => {
    setValue(KeyWordLocalization.AgentListCurrentPage, page);
    const data = getValues();
    _handleSearch(data);
  }

  const _handleOnClickAgent = (agent: QAAgentEntity) => navigate(routes.agent.relativePathWithParams({ id: agent.id }));

  const _handleOnClickDeleteAgent = async (agent: QAAgentEntity) => {
    const deleteAgent = async () => {
      const response = await di.get<DeleteAgentUseCase>(DeleteAgentUseCaseName).call(agent.id);
      if (isLeft(response)) return addToast(response.left.code ?? KeyWordLocalization.UnknownError, 'error', undefined);
      addToast(i18n(KeyWordLocalization.AgentListPageDeleteSuccess), 'success', undefined);
      _searchAgents();
      closeModalDelete();
    }
    openModalDelete(i18n(KeyWordLocalization.AgentListDeleteModalTitle), i18n(KeyWordLocalization.AgentListDeleteModalSubtitle), deleteAgent);
  };

  const _handleOnClickUpdateAgent = (agent: QAAgentEntity) => navigate(routes.edit_agent.relativePathWithParams({ id: agent.id }));

  const _handleOnCreateAgent = () => navigate(routes.agent_create.relativePath);

  useEffect(() => {
    _handleLoad();
  }, [pageQuery, searchQuery]);


  return <div className="agent_list_page">
    <LayoutComponent title={i18n(KeyWordLocalization.AgentListTitle)}>
      <div className="container py-5">
        <form onSubmit={handleSubmit(_handleSearch)}>
          <div className="search">
            <input className="input_search" placeholder={i18n(KeyWordLocalization.AgentListPageSearchInput)} type="text" {...register(KeyWordLocalization.AgentListPageQuery)} onKeyUp={_handleChangeTextSearch} />
            <button className="btn_search" type="submit">
              <span className="material-symbols-outlined">
                search
              </span> {i18n(KeyWordLocalization.AgentListPageSearch)}
            </button>
          </div>
          <div className="list">
            {result == undefined && <div className="mt-5">
              <LoadingComponent />
            </div>}
            {result && result.data.length == 0 && <NotResultsComponent />}
            {result && result.data.length > 0 && <table className="table my-5" style={{ tableLayout: "auto" }}>
              <thead>
                <tr>
                  <th>{i18n(KeyWordLocalization.AgentListPageId)}</th>
                  <th>{i18n(KeyWordLocalization.AgentListPageName)}</th>
                  <th>{i18n(KeyWordLocalization.AgentListPageActions)}</th>
                </tr>
              </thead>
              <tbody>
                {result.data.map((agent, index) => <tr key={index}>
                  <td className="hover" onClick={() => _handleOnClickAgent(agent)} style={{ width: 10 }}>{agent.id}</td>
                  <td className="hover" onClick={() => _handleOnClickAgent(agent)}>
                    {agent.name}
                  </td>
                  <td style={{ width: '10em' }}>
                    <div className="d-flex">
                      <ButtonComponent onClick={() => _handleOnClickUpdateAgent(agent)} className="mx-2" design="primary" text={i18n(KeyWordLocalization.AgentListPageActionEdit)} icon={
                        <span className="material-symbols-outlined">edit</span>
                      } />
                      <ButtonComponent onClick={() => _handleOnClickDeleteAgent(agent)} design="danger" type="button" text={i18n(KeyWordLocalization.AgentListPageActionDelete)} icon={
                        <span className="material-symbols-outlined">delete</span>
                      } />
                    </div>
                  </td>
                </tr>)}
              </tbody>
            </table>
            }
          </div>
          <div className="paginator">
            {result && result.pages > 1 && <PaginatorComponent resultData={result} onChangePage={_handleOnChangePage} />}
          </div>
        </form>
        <div className="floating_button">
          <ButtonComponent design="outstanding" onClick={_handleOnCreateAgent} icon={
            <span className="material-symbols-outlined">add</span>
          } className="btn_create_agent" text={i18n(KeyWordLocalization.AgentListPageAddAgent)} />
        </div>
      </div>
    </LayoutComponent>
  </div>
}

export default AgentListPage;