import path from "path";
import UserEntity from "../../domain/entities/user.entity";
import AgentListPage from "../pages/ai_agent/list/agent_list.page";
import SignInPage from "../pages/auth/signin/SignInPage";
import DetailedAgentPage from "../pages/ai_agent/detailed/detailed_agent.page";
import DashboardPage from "../pages/dashboard/dashboard.page";
import EditAgentPage from "../pages/ai_agent/edit/edit_agent.page";
import CreateAgentPage from "../pages/ai_agent/create/create_agent.page";
import HumanAgentListPage from "../pages/human_agent/list/human_agent_list.page";
import HumanAgentBulkImportPage from "../pages/human_agent/bulk_import/human_agent_bulk_import.page";
import RankingAgentsPage from "../pages/human_agent/ranking/ranking_agents.page";

export interface RouteEntity {
    path: string;
    relativePath: string;
    component: any;
    auth: (user: UserEntity | undefined | null) => boolean;
    title?: string;
    relativePathWithParams?: (params: any) => string;
};
const routes = {
    login: {
        path: "/",
        relativePath: "/",
        component: SignInPage,
        auth: (user: UserEntity | undefined | null) => !user,
        title: "Login",
        relativePathWithParams: (params: any) => "/",
    },
    home: {
        path: "/dashboard",
        relativePath: "/dashboard",
        component: DashboardPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Dashboard",
        relativePathWithParams: (params: any) => "/dashboard",
    },
    agent_list: {
        path: "/ai_agents",
        relativePath: "/ai_agents",
        component: AgentListPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "AI Agents",
        relativePathWithParams: (params: any) => "/ai_agents",
    },
    agent_create: {
        path: "/ai-agents/create",
        relativePath: "/ai-agents/create",
        component: CreateAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Create AI Agent",
        relativePathWithParams: (params: any) => "/ai_agents",
    },
    edit_agent: {
        path: "/ai-agents/edit/:id",
        relativePath: "/ai-agents/edit",
        component: EditAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Edit AI Agent",
        relativePathWithParams: (params: { id: string }) => `/ai-agents/edit/${params.id}`,
    },
    agent: {
        path: "/ai-agents/:id",
        relativePath: "/ai_agents",
        component: DetailedAgentPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "AI Agent",
        relativePathWithParams: (params: { id: string }) => `/ai-agents/${params.id}`,
    },
    human_agent_list: {
        path: "/agents",
        relativePath: "/agents",
        component: HumanAgentListPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Agents",
        relativePathWithParams: () => "/agents",
    },
    edit_human_agent: {
        path: "/agents/edit/:id",
        relativePath: "/agents/edit",
        component: HumanAgentListPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Edit Agent",
        relativePathWithParams: (params: { id: string }) => `/agents/edit/${params.id}`,
    },
    bulk_human_agent: {
        path: "/agents/bulk",
        relativePath: "/agents/bulk",
        component: HumanAgentBulkImportPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Bulk Agents",
        relativePathWithParams: () => "/agents/bulk",
    },
    human_agent: {
        path: "/agents/:id",
        relativePath: "/agents",
        component: HumanAgentListPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Agent",
        relativePathWithParams: (params: { id: string }) => `/agents/${params.id}`,
    },
    ranking_human_agents: {
        path: "/agents/ranking",
        relativePath: "/agents/ranking",
        component: RankingAgentsPage,
        auth: (user: UserEntity | undefined | null) => user != null,
        title: "Ranking Agents",
        relativePathWithParams: () => "/agents/ranking",
    },
    
};

export default routes;