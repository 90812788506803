import "./accordion.component.style.scss";
import { FC, useRef, useState } from "react";
import AccordionComponentProps from "./accordion.component.props";

const AccordionComponent: FC<AccordionComponentProps> = ({ body, expanded, onToggle, multipleOpened = false }) => {
    const [opened, setOpened] = useState<(string | number)[]>(expanded == true ? [(body[0].id ?? 0)] : []);

    const _handleToggle = (index: number | string) => {
        const copyOpened = [...opened];
        if (copyOpened.includes(index)) {
            copyOpened.splice(copyOpened.indexOf(index), 1);
            setOpened(copyOpened);
        } else {
            if (!multipleOpened) {
                copyOpened.splice(0, copyOpened.length);
            }
            setOpened([...copyOpened, index]);
        }
        onToggle?.(copyOpened);
    }

    return <div className="accordion_component">
        {body.map((item, index) => <div key={index} className={`accordion_item ${opened.includes(item.id ?? index) && "opened"}`}>
            <div className="accordion_item_header" onClick={() => _handleToggle(index)}>
                {item.header}
            </div>
            {opened.includes(index) && <div className="accordion_item_content">
                {item.content}
            </div>}
        </div>)}
    </div>
}

export default AccordionComponent;