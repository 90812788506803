import { injectable } from "inversify";
import HumanAgentRepository from "../../repositories/human_agent.repository";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import HumanAgentEntity from "../../entities/human_agent.entity";

interface Props {
    humanAgentRepository: HumanAgentRepository;
}

@injectable()
class GetHumanAgentByIdUseCase {
    private humanAgentRepository: HumanAgentRepository;

    constructor({ humanAgentRepository }: Props) {
        this.humanAgentRepository = humanAgentRepository;
    }

    public call = async (id: string): Promise<Either<ErrorEntity, HumanAgentEntity>> => {
        return this.humanAgentRepository.getById(id);
    }
}

export default GetHumanAgentByIdUseCase;

export const GetHumanAgentByIdUseCaseName = "GetHumanAgentByIdUseCase";