import UserEntity from "../../../../domain/entities/user.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import UserDto from "../../../dto/user.dto";

const getCurrentUserApiImpl = async (): Promise<UserEntity | null> => {
    try {
        const relativeRoute = "/auth/validate";
        const response = await di.get<HostApi>(HostApiName).get(relativeRoute);
        const userParsed = UserDto.fromJSON(response);
        return userParsed;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export default getCurrentUserApiImpl;