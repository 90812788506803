import QAResultEntity from "../../domain/entities/qa_result.entity";

const fromJSON = (json: any): QAResultEntity => {
    //TODO QUESTION update agents info
    throw new Error("Method not implemented.");
    // return {
    //     id: json.id ?? "",
    //     audioUrl: json.output_result?.audios?.conversation_audio ?? "",
    //     responses: json.output_result.analysis.customs.map((custom: any) => {
    //         return {
    //             question: {
    //                 title: custom.title,
    //                 description: custom.question,
    //                 points: custom.points,
    //             },
    //             response: {
    //                 short: custom.response.answer,
    //                 justification: custom.response.justification,
    //                 points: custom.response.score
    //             }
    //         }
    //     }),
    // }
}


export default {
    fromJSON,
};