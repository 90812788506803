import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const generateAudioLinkApiImpl = async (audios: string[], agentId: string): Promise<Either<ErrorEntity, {
    url: string,
    jobID: string,
    audioName: string
}>> => {
    try {
        const relativeUrl = "/audio";
        const body = {
            "audio_files": audios,
            "agent_id": agentId
        }
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body, {
            contentType: "application/json"
        });
        if(response.length == 0) {
            return left(ErrorEntity.fromError(new Error("No audio files")));
        }

        return right({
            url: response[0].url,
            jobID: response[0].job_id,
            audioName: response[0].audio_file
        });

    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default generateAudioLinkApiImpl;