import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { Sentiments, SentimentsOnDate } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import SentimentsOnDateDto from "../../../dto/sentiment_on_date.dto";
import DatesUtils from "../../../../presentation/utils/DatesUtils";

const getDayToDayApiImpl = async (clientId: string | undefined, startDate: Date, endDate: Date): Promise<Either<ErrorEntity, SentimentsOnDate[]>> => {
    try {
        endDate.setDate(endDate.getDate() + 1);
        startDate.setDate(startDate.getDate() + 1);
        const relativeUrl = `/stats/call-sentiments?start_date=${DateDto.toServer(startDate)}&end_date=${DateDto.toServer(endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        const parsed = response.map((item: any) => SentimentsOnDateDto.fromJson(item));
        const clean: Sentiments = {
            negative: 0,
            neutral: 0,
            positive: 0,
        }
        //run day by day and add clean where needed
        const arrayOfDates = Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => new Date(startDate.getTime() + (i * 1000 * 60 * 60 * 24)));
        const result = arrayOfDates.map((date: Date) => {
            const found = parsed.find((item: SentimentsOnDate) => DatesUtils.isSameDate(item.date, date));
            if (found) {
                return found;
            }
            return {
                date,
                sentiments: clean,
            }
        });
        if(result.length == 1){
            result.push(result[0]);
        }
        return right(result);
    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default getDayToDayApiImpl;