import QAQuestionEntity from "./qa_question.entity";

export interface IQACategoryEntity {
    id: string;
    title: string;
    description: string;
    agentId: string;
    questions: QAQuestionEntity[];
}

export default class QACategoryEntity implements IQACategoryEntity {

    id: string;
    title: string;
    description: string;
    agentId: string;
    questions: QAQuestionEntity[];

    constructor(_: IQACategoryEntity) {
        this.id = _.id;
        this.title = _.title;
        this.description = _.description;
        this.questions = _.questions;
        this.agentId = _.agentId;
    }

    getTotalPointsResponse = (): number | null => {
        let allNulls = true;
        let totalPoints = 0;
        this.questions.forEach(question => {
            if (question.response !== null) {
                allNulls = false;
                totalPoints += question.response!.points;
            }
        });
        if(allNulls) return null;
        return totalPoints;
    }

    getPercentageOfPoints = (): number => {
        const totalPoints = this.getTotalPointsResponse();
        if (totalPoints === null) return 0;
        return (totalPoints / this.getMaxQuestionPoints()) * 100;
    }

    getMaxQuestionPoints = (): number => {
        const max = this.questions.reduce((acc, question) => acc + question.points, 0);
        const error0 = parseInt(max.toString());
        return error0;
    }
}