import { FC, useEffect, useState } from "react";
import BarSatifactionComponentProps from "./BarSatisfactionComponentProps";
import { Line } from "react-chartjs-2";

const BarSatifactionComponent: FC<BarSatifactionComponentProps> = ({ title, chartData, yTitle }) => {
    const [aspectRatio, setAspectRatio] = useState(window.innerWidth > 768 ? undefined : 1);

    useEffect(() => {
        const handleResize = () => {
            setAspectRatio(window.innerWidth > 768 ? undefined : 1);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="bar_satisfaction_component" style={{ minHeight: "300px", maxHeight: 500 }}>
            <h5 className="mb-3">{title}</h5>
            <Line 
                data={chartData} 
                options={{
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                    },
                    responsive: true,
                    aspectRatio: aspectRatio,
                    scales: {
                        y: yTitle !== undefined ? {
                            title: {
                                display: true,
                                text: yTitle
                            }
                        } : {}
                    },
                }} 
            />
        </div>
    );
}

export default BarSatifactionComponent;