import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import LocalizationContext from '../../providers/localization/LocalizationContext';
import KeyWordLocalization from '../../utils/KeyWordLocalization';
import NotResultsComponentProps from './not_results.component.props';
import './not_results.component.scss';
import { FC, useContext } from "react";

const NotResultsComponent: FC<NotResultsComponentProps> = ({ message }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    return (
        <div className="not_results_component">
            <div className="icon">
                <span className="material-symbols-outlined">search_off</span>
            </div>
            <h1>{message || i18n(KeyWordLocalization.NoResultsComponentMessage)}</h1>
            <p>{i18n(KeyWordLocalization.NoResultsComponentSuggestion)}</p>
        </div>
    );
}

export default NotResultsComponent;