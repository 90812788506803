import { Either, right, left } from "fp-ts/lib/Either";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";
import ErrorEntity from "../../../../domain/entities/error.entity";

const logoutApiImpl = async(): Promise<Either<ErrorEntity, void>> => {
    try {
        di.get<HostApi>(HostApiName).removeToken();
        await di.get<HostApi>(HostApiName).post("/auth/logout", {});
        return right(undefined);
    } catch (e) {
        return left(ErrorEntity.fromError(e));
    }
}

export default logoutApiImpl;