import { Either } from "fp-ts/lib/Either";
import MasterItemEntity from "../entities/master_item.entity";
import ErrorEntity from "../entities/error.entity";
import QACommentEntity from "../entities/qa_comment.entity";
import ClientAnalysisEntity from "../entities/client_analysis.entity";
import SearchResultEntity from "../entities/search_result.entity";

export interface AverageCalls {
    calls: number,
    agent: number,
    customer: number,
};


export interface Sentiments {
    negative: number,
    neutral: number,
    positive: number,
}

export interface SentimentsOnDate {
    date: Date,
    sentiments: Sentiments
}

export interface Target {
    serviceLevel: number,
    current: number,
    increment: number,
}

export interface ReasonForCalls {
    name: string,
    count: number,
}

export interface HumanRanking {
    id: string,
    name: string,
    totalEvaluatedCalls: number;
    sentiments: Sentiments,
    score: number,
    rankingPosition: number;
}

export interface GetOperationReportResponse {
    dayToDay?: SentimentsOnDate[],
    totalSentiments?: Sentiments,
    averages?: AverageCalls,
    totalCalls?: number,
    target?: Target
    reasonForCalls?: ReasonForCalls[],
    bestClients?: HumanRanking[],
    worstClients?: HumanRanking[],
}

export default interface DashboardRepository {
    getClientsList: () => Promise<Either<ErrorEntity, MasterItemEntity[]>>;
    getCallAverages: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, { averages: AverageCalls, totalCalls: number }>>;
    getSentiments: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, SentimentsOnDate[]>>;
    getReasonForCalls: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, ReasonForCalls[]>>;
    getScoreDetails: (clientId: string | undefined, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, Target>>;
    getClientDetails: (clientId: string, startDate: Date, endDate: Date) => Promise<Either<ErrorEntity, ClientAnalysisEntity>>;
    addComment: (_: { employeeID: string, comment: string }) => Promise<Either<ErrorEntity, QACommentEntity>>;
    searchRanking(_: {
        query: string,
        initDate?: Date, endDate?: Date, page: number, itemsPerPage: number, sortBy: "ASC" | "DESC",
    }): Promise<Either<ErrorEntity, SearchResultEntity<HumanRanking>>>;
    getTopAndBottomAgents(_: {
        startDate: Date, endDate: Date, clientId?: string,
    }): Promise<Either<ErrorEntity, { best: HumanRanking[], worst: HumanRanking[] }>>;
}

export const DashboardRepositoryName = "DashboardRepository";