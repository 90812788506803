import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isLeft } from "fp-ts/lib/Either";
import CreateHumanAgentComponentProps from "./create_human_agent.component.props";
import "./create_human_agent.component.style.scss";
import di from "../../../../dependency_injection";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import CreateHumanAgentUseCase, { CreateHumanAgentUseCaseName } from "../../../../domain/use_cases/human_agent/create_human_agent.use_case";
import ButtonComponent from "../../../components/button/button.component";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import Validators from "../../../utils/Validators";
import HumanAgentEntity, { HumanAgentStatus } from "../../../../domain/entities/human_agent.entity";

const CreateHumanAgentComponent: FC<CreateHumanAgentComponentProps> = ({ onCreated, humanAgent }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { addToast, closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState<boolean>(false);

    const _handleCreateHumanAgent = async (data: any) => {
        setLoading(true);
        const newAgent: HumanAgentEntity = {
            id: "",
            name: data[KeyWordLocalization.CreateHumanAgentPageTitle],
            agentId: data[KeyWordLocalization.CreateHumanAgentPageAgentId],
            status: HumanAgentStatus.active,
        };
        const response = await di.get<CreateHumanAgentUseCase>(CreateHumanAgentUseCaseName).call(newAgent);
        if (isLeft(response)) {
            addToast(i18n(KeyWordLocalization.CreateHumanAgentPageCreateError), 'error', undefined);
            setLoading(false);
            return;
        }
        addToast(i18n(KeyWordLocalization.CreateHumanAgentPageCreateSuccess), 'success', undefined);
        onCreated(response.right);
        setLoading(false);
        closeModalCustom();
    }

    const _loadHumanAgent = () => {
        if (humanAgent) {
            setValue(KeyWordLocalization.CreateHumanAgentPageTitle, humanAgent.name);
            setValue(KeyWordLocalization.CreateHumanAgentPageAgentId,humanAgent.agentId);
        } else {
            reset();
        }
    }

    const _handleEditHumanAgent = async (data: any) => {
        setLoading(true);
        const newAgent: HumanAgentEntity = {
            id: humanAgent!.id,
            name: data[KeyWordLocalization.CreateHumanAgentPageTitle],
            agentId: data[KeyWordLocalization.CreateHumanAgentPageAgentId],
            status: humanAgent!.status,
        };
        const response = await di.get<CreateHumanAgentUseCase>(CreateHumanAgentUseCaseName).call(newAgent);
        if (isLeft(response)) {
            addToast(i18n(KeyWordLocalization.CreateHumanAgentPageCreateError), 'error', undefined);
            setLoading(false);
            return;
        }
        addToast(i18n(KeyWordLocalization.CreateHumanAgentPageCreateSuccess), 'success', undefined);
        onCreated(response.right);
        setLoading(false);
        closeModalCustom();
    }

    useEffect(() => {
        _loadHumanAgent();
    }, [humanAgent]);

    return <div className="create_human_agent_page">
        <div className="container">
            <form onSubmit={handleSubmit(humanAgent != null ? _handleEditHumanAgent : _handleCreateHumanAgent)}>
                <div className={`form-group my-3 ${errors[KeyWordLocalization.CreateHumanAgentPageTitle] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateHumanAgentPageTitle)}</label>
                    <input type="text" disabled={loading} className="form-control" placeholder={i18n(KeyWordLocalization.CreateHumanAgentPageTitle)} {...register(KeyWordLocalization.CreateHumanAgentPageTitle, Validators({
                        required: true,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateHumanAgentPageTitle} />
                </div>
                <div className={`form-group my-3 ${errors[KeyWordLocalization.CreateHumanAgentPageAgentId] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CreateHumanAgentPageAgentId)}</label>
                    <input type="text" disabled={loading} className="form-control" placeholder={i18n(KeyWordLocalization.CreateHumanAgentPageAgentId)} {...register(KeyWordLocalization.CreateHumanAgentPageAgentId, Validators({
                        required: true,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CreateHumanAgentPageAgentId} />
                </div>
                <div className="w-100 d-flex justify-content-end my-3">
                    {humanAgent == null ?
                        <ButtonComponent isLoading={loading} icon={<span className="material-symbols-outlined">add</span>} type="submit" design="primary" text={i18n(KeyWordLocalization.CreateHumanAgentPageCreate)} />
                        : <ButtonComponent isLoading={loading} icon={<span className="material-symbols-outlined">edit</span>} type="submit" design="primary" text={i18n(KeyWordLocalization.CreateHumanAgentPageEdit)} />}
                </div>
            </form>
        </div>
    </div>
};

export default CreateHumanAgentComponent;