import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { AverageCalls } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const getCallAveragesApiImpl = async (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, { averages: AverageCalls; totalCalls: number; }>> => {
    try {

        const relativeUrl = `/stats/call-detail?start_date=${DateDto.toServer(startDate)}&end_date=${DateDto.toServer(endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        const toTwoDigits = (num: number): number => Number(num.toFixed(2));
        const parsed = {
            averages: {
                calls: toTwoDigits(response.avg_call_score),
                agent: toTwoDigits(response.avg_agent_score),
                customer: toTwoDigits(response.avg_customer_score),
            },
            totalCalls: response.total_calls,
        }
        return right(parsed);

    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default getCallAveragesApiImpl;