export default class ErrorEntity {
    code?: string;
    message?: string;

    constructor(code?: string, message?: string) {
        this.code = code;
        this.message = message;
    }

    static fromError(error: any): ErrorEntity {
        try {
            return new ErrorEntity(error.code, error.message);
        } catch (e) {
            return new ErrorEntity();
        }
    }
}