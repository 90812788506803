import { injectable } from "inversify";
import QARepository from "../../repositories/qa.repository";
import QAAgentEntity from "../../entities/qa_agent.entity";
import { Either, isLeft } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import QAResultEntity from "../../entities/qa_result.entity";
import KeyWordLocalization from "../../../presentation/utils/KeyWordLocalization";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class CheckAudioWithQuestionsUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (audio: File, qaAgent: QAAgentEntity, onCallBack?: (progress: number, message: string) => void): Promise<Either<ErrorEntity, QAResultEntity>> => {
        const callBackAudio = (progress: number, message: string) => onCallBack?.(progress / 2, message);
        onCallBack?.(0, KeyWordLocalization.CheckAudioWithQuestionsUseCaseLoadingAudio);
        const audioUrl = await this.qaRepository.uploadAudioForTranscription(audio, qaAgent.id, callBackAudio);
        if (isLeft(audioUrl)) {
            return audioUrl;
        }
        onCallBack?.(50, KeyWordLocalization.CheckAudioWithQuestionsUseCaseSendingQuestions);
        const callBackQuestions = (progress: number, message: string) => onCallBack?.(50 + progress / 2, message);
        const res = await this.qaRepository.getAudioResults(audioUrl.right, callBackQuestions);
        return res;
    }
}

export const CheckAudioWithQuestionsUseCaseName = "CheckAudioWithQuestionsUseCase";