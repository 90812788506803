const KeyWordLocalization = {
    TRADE_MARK: 'TRADE_MARK',
    edit: "edit",
    Search: "Search",

    //#region---------------------- Pages ----------------------//
    SignInPageSuccess: "SignInPageSuccess",
    SignInPageTitle: "SignInPageTitle",
    SignInPageSubtitle: "SignInPageSubtitle",
    SignInPageEmail: "SignInPageEmail",
    SignInPagePassword: "SignInPagePassword",
    SignInPageButtonSignIn: "SignInPageButtonSignIn",

    AgentListTitle: "AgentListTitle",
    AgentListPageQuery: "AgentListPageQuery",
    AgentListCurrentPage: "AgentListCurrentPage",
    AgentListPageSearchInput: "AgentListPageSearchInput",
    AgentListTotalPages: "AgentListTotalPages",
    AgentListPageSearch: "AgentListPageSearch",
    AgentListPageId: "AgentListPageId",
    AgentListPageName: "AgentListPageName",
    AgentListPageActions: "AgentListPageActions",
    AgentListPageActionEdit: "AgentListPageActionEdit",
    AgentListPageActionDelete: "AgentListPageActionDelete",
    AgentListPageAddAgent: "AgentListPageAddAgent",
    AgentListPageDeleteSuccess: "AgentListPageDeleteSuccess",
    AgentListDeleteModalTitle: "AgentListDeleteModalTitle",
    AgentListDeleteModalSubtitle: "AgentListDeleteModalSubtitle",

    CreateAgentPageTitle: "CreateAgentPageTitle",
    CreateAgentPageDescription: "CreateAgentPageDescription",
    CreateAgentPageCreateError: "CreateAgentPageCreateError",
    CreateAgentPageCreateSuccess: "CreateAgentPageCreateSuccess",
    CreateAgentPageCreate: "CreateAgentPageCreate",
    CreateAgentPageFinish: "CreateAgentPageFinish",
    CreateAgentPageSkip: "CreateAgentPageSkip",
    CreateAgentPageUpdate: "CreateAgentPageUpdate",

    DetailedAgentPageTitle: "DetailedAgentPageTitle",
    DetailedAgentPageQuestions: "DetailedAgentPageQuestions",
    DetailedAgentPagePoints: "DetailedAgentPagePoints",
    DetailedAgentPageNoCategoriesOrQuestions: "DetailedAgentPageNoCategoriesOrQuestions",
    DetailedAgentPageAddQuestions: "DetailedAgentPageAddQuestions",

    EditAgentPageTitle: "EditAgentPageTitle",

    DashboardPageOpTypeForm: "DashboardPageOpTypeForm",
    DashboardPageDateFromForm: "DashboardPageDateFromForm",
    DashboardPageDateToForm: "DashboardPageDateToForm",
    DashboardPageSentimentNegative: "DashboardPageSentimentNegative",
    DashboardPageSentimentNeutral: "DashboardPageSentimentNeutral",
    DashboardPageSentimentPositive: "DashboardPageSentimentPositive",
    DashboardPageUrlParamOptype: "DashboardPageUrlParamOptype",
    DashboardPageUrlParamDateFrom: "DashboardPageUrlParamDateFrom",
    DashboardPageUrlParamDateTo: "DashboardPageUrlParamDateTo",
    DashboardPageTotalCalls: "DashboardPageTotalCalls",
    DashboardPageAverageCallScore: "DashboardPageAverageCallScore",
    DashboardPageAverageAgentScore: "DashboardPageAverageAgentScore",
    DashboardPageAverageCustomerScore: "DashboardPageAverageCustomerScore",
    DashboardPageSentimentsOnTime: "DashboardPageSentimentsOnTime",
    DashboardPageReasonsForCall: "DashboardPageReasonsForCall",
    DashboardPageBestClients: "DashboardPageBestClients",
    DashboardPageWorstClients: "DashboardPageWorstClients",

    HumanAgentListPageQuery: "HumanAgentListPageQuery",
    HumanAgentListCurrentPage: "HumanAgentListCurrentPage",
    HumanAgentListTitle: "HumanAgentListTitle",
    HumanAgentListPageSearchInput: "HumanAgentListPageSearchInput",
    HumanAgentListPageSearch: "HumanAgentListPageSearch",
    HumanAgentListPageId: "HumanAgentListPageId",
    HumanAgentListPageName: "HumanAgentListPageName",
    HumanAgentListPageActions: "HumanAgentListPageActions",
    HumanAgentListPageActionEdit: "HumanAgentListPageActionEdit",
    HumanAgentListPageActionDelete: "HumanAgentListPageActionDelete",
    HumanAgentListPageAddAgent: "HumanAgentListPageAddAgent",
    HumanAgentListPageBulkImport: "HumanAgentListPageBulkImport",
    HumanAgentListPageDownloadAgents: "HumanAgentListPageDownloadAgents",
    HumanAgentListDeleteModalTitle: "HumanAgentListDeleteModalTitle",
    HumanAgentListDeleteModalSubtitle: "HumanAgentListDeleteModalSubtitle",
    HumanAgentListPageDeleteSuccess: "HumanAgentListPageDeleteSuccess",
    HumanAgentListPageBulkImportTitle: "HumanAgentListPageBulkImportTitle",
    HumanAgentListPageUpdateAgent: "HumanAgentListPageUpdateAgent",

    HumanAgentBulkImportPageTitle: "HumanAgentBulkImportPageTitle",
    HumanAgentBulkImportPageDownloadTemplate: "HumanAgentBulkImportPageDownloadTemplate",
    HumanAgentBulkImportPageUploadFile: "HumanAgentBulkImportPageUploadFile",
    HumanAgentBulkImportPageUpload: "HumanAgentBulkImportPageUpload",
    HumanAgentBulkImportPageDropHere: "HumanAgentBulkImportPageDropHere",
    HumanAgentBulkImportPagePreviewTitle: "HumanAgentBulkImportPagePreviewTitle",
    HumanAgentBulkImportPagePreviewName: "HumanAgentBulkImportPagePreviewName",
    HumanAgentBulkImportPagePreviewAgentId: "HumanAgentBulkImportPagePreviewAgentId",
    HumanAgentBulkImportPagePreviewError: "HumanAgentBulkImportPagePreviewError",
    HumanAgentBulkImportPageStart: "HumanAgentBulkImportPageStart",
    HumanAgentBulkImportPageSuccess: "HumanAgentBulkImportPageSuccess",
    HumanAgentBulkImportPagePartialSuccess: "HumanAgentBulkImportPagePartialSuccess",
    HumanAgentBulkImportPageFailure: "HumanAgentBulkImportPageFailure",
    HumanAgentBulkImportPageInvalidFile: "HumanAgentBulkImportPageInvalidFile",
    HumanAgentBulkImportPageMissingFields: "HumanAgentBulkImportPageMissingFields",
    HumanAgentBulkImportPageAgentsSheet: "HumanAgentBulkImportPageAgentsSheet",
    HumanAgentBulkImportPageDragDrop: "HumanAgentBulkImportPageDragDrop",
    HumanAgentBulkImportPageDescription: "HumanAgentBulkImportPageDescription",
    HumanAgentBulkImportPageBulkModalTitle: "HumanAgentBulkImportPageBulkModalTitle",
    HumanAgentBulkImportPageBulkModalSubtitle: "HumanAgentBulkImportPageBulkModalSubtitle",
    HumanAgentBulkImportPageBulkModalLoading: "HumanAgentBulkImportPageBulkModalLoading",
    //#endregion---------------------- Pages ----------------------//

    //#region---------------------- Components ----------------------//
    ModalCustomCompoonentCloseButton: "ModalCustomCompoonentCloseButton",

    LoadingComponentLoading: "LoadingComponentLoading",

    AuthIfonComponentRevolutionTitle: "AuthIfonComponentRevolutionTitle",
    AuthIfonComponentRevolutionSubitle: "AuthIfonComponentRevolutionSubitle",
    AuthIfonComponentAIPoweredTitle: "AuthIfonComponentAIPoweredTitle",
    AuthIfonComponentAIPoweredSubitle: "AuthIfonComponentAIPoweredSubitle",
    AuthIfonComponentFastTitle: "AuthIfonComponentFastTitle",
    AuthIfonComponentFastSubtitle: "AuthIfonComponentFastSubtitle",
    AuthIfonComponentBoostTitle: "AuthIfonComponentBoostTitle",
    AuthIfonComponentBoostSubtitle: "AuthIfonComponentBoostSubtitle",

    PaginatorComponentShowing: "PaginatorComponentShowing", //showing, total

    DeleteModalComponentConfirm: "DeleteModalComponentConfirm",
    DeleteModalComponentCancel: "DeleteModalComponentCancel",
    DeleteModalComponentDelete: "DeleteModalComponentDelete",
    DeleteModalComponentMessage: "DeleteModalComponentMessage",
    DeleteModalComponentError: "DeleteModalComponentError",

    CreateQuestionComponentDescription: "CreateQuestionComponentDescription",
    CreateQuestionComponentPoints: "CreateQuestionComponentPoints",
    CreateQuestionComponentTitle: "CreateQuestionComponentTitle",
    CreateQuestionComponentSave: "CreateQuestionComponentSave",
    CreateQuestionComponentCancel: "CreateQuestionComponentCancel",
    CreateQuestionComponentCreate: "CreateQuestionComponentCreate",

    NotFoundComponentTitle: "NotFoundComponentTitle",
    NotFoundComponentSubtitle: "NotFoundComponentSubtitle",

    AudioZoneComponentAudioUploaded: "AudioZoneComponentAudioUploaded",
    AudioZoneComponentResultTitle: "AudioZoneComponentResultTitle",
    AudioZoneComponentTitle: "AudioZoneComponentTitle",
    AudioZoneComponentDropHere: "AudioZoneComponentDropHere",
    AudioZoneComponentJustDrop: "AudioZoneComponentJustDrop",
    AudioZoneComponentLoadingAudio: "AudioZoneComponentLoadingAudio",
    AudioZoneComponentErrorGettingData: "AudioZoneComponentErrorGettingData",

    TargetCircleComponentServiceTarget: "TargetCircleComponentServiceTarget",

    TableSatisfactionComponentTitlRankingPosition: "TableSatisfactionComponentTitlRankingPosition",
    TableSatisfactionComponentTitleName: "TableSatisfactionComponentTitleName",
    TableSatisfactionComponentTitleSentiments: "TableSatisfactionComponentTitleSentiments",
    TableSatisfactionComponentTitleScore: "TableSatisfactionComponentTitleScore",
    TableSatisfactionComponentTotalEvaluatedCalls: "TableSatisfactionComponentTotalEvaluatedCalls",
    TableSatisfactionComponentSeeAll: "TableSatisfactionComponentSeeAll",
    TableSatisfactionDetailClient: "TableSatisfactionDetailClient", //name

    ResultIaAudioComponentTitle: "ResultIaAudioComponentTitle",
    ResultIaAudioComponentScore: "ResultIaAudioComponentScore",
    ResultIaAudioComponentResultsByCategory: "ResultIaAudioComponentResultsByCategory",
    ResultIaAudioComponentPoints: "ResultIaAudioComponentPoints",
    ResultIaAudioComponentMore: "ResultIaAudioComponentMore",
    ResultIaAudioComponentTranscription: "ResultIaAudioComponentTranscription",

    CreateAgentComponentTitleCreate: "CreateAgentComponentTitleCreate",

    CategoriesEditionComponentNoCategoriesCreated: "CategoriesEditionComponentNoCategoriesCreated",
    CategoriesEditionComponentCategories: "CategoriesEditionComponentCategories",
    CategoriesEditionComponentAddCategory: "CategoriesEditionComponentAddCategory",
    CategoriesEditionComponentNoQuestions: "CategoriesEditionComponentNoQuestions",
    CategoriesEditionComponentEdit: "CategoriesEditionComponentEdit",
    CategoriesEditionComponentDelete: "CategoriesEditionComponentDelete",
    CategoriesEditionComponentAddQuestion: "CategoriesEditionComponentAddQuestion",
    CategoriesEditionComponentQuestionPoints: "CategoriesEditionComponentQuestionPoints",
    CategoriesEditionComponentQuestionError: "CategoriesEditionComponentQuestionError",
    CategoriesEditionComponentUpdateQuestionError: "CategoriesEditionComponentUpdateQuestionError",
    CategoriesEditionComponentDeleteQuestionError: "CategoriesEditionComponentDeleteQuestionError",
    CategoriesEditionComponentQuestionAdded: "CategoriesEditionComponentQuestionAdded",
    CategoriesEditionComponentCreateQuestion: "CategoriesEditionComponentCreateQuestion",
    CategoriesEditionComponentQuestionUpdated: "CategoriesEditionComponentQuestionUpdated",
    CategoriesEditionComponentEditQuestion: "CategoriesEditionComponentEditQuestion",
    CategoriesEditionComponentQuestionDeleted: "CategoriesEditionComponentQuestionDeleted",
    CategoriesEditionComponentDeleteQuestionTitle: "CategoriesEditionComponentDeleteQuestionTitle",
    CategoriesEditionComponentDeleteQuestionSubtitle: "CategoriesEditionComponentDeleteQuestionSubtitle",
    CategoriesEditionComponentCategoryError: "CategoriesEditionComponentCategoryError",
    CategoriesEditionComponentUpdateCategoryError: "CategoriesEditionComponentUpdateCategoryError",
    CategoriesEditionComponentDeleteCategoryError: "CategoriesEditionComponentDeleteCategoryError",
    CategoriesEditionComponentCategoryAdded: "CategoriesEditionComponentCategoryAdded",
    CategoriesEditionComponentCreateCategory: "CategoriesEditionComponentCreateCategory",
    CategoriesEditionComponentQuestions: "CategoriesEditionComponentQuestions",
    CategoriesEditionComponentAddQuestions: "CategoriesEditionComponentAddQuestions",

    CategoryCreationComponentCancel: "CategoryCreationComponentCancel",
    CategoryCreationComponentSave: "CategoryCreationComponentSave",
    CategoryCreationComponentCreate: "CategoryCreationComponentCreate",
    CategoriesEditionComponentCategoryUpdated: "CategoriesEditionComponentCategoryUpdated",
    CategoriesEditionComponentEditCategory: "CategoriesEditionComponentEditCategory",
    CategoriesEditionComponentCategoryDeleted: "CategoriesEditionComponentCategoryDeleted",
    CategoriesEditionComponentDeleteCategoryTitle: "CategoriesEditionComponentDeleteCategoryTitle",
    CategoriesEditionComponentDeleteCategorySubtitle: "CategoriesEditionComponentDeleteCategorySubtitle",

    AgentAnalysisComponentErrorGettingData: "AgentAnalysisComponentErrorGettingData",
    AgentAnalysisComponentAICoachTitle: "AgentAnalysisComponentAICoachTitle",
    AgentAnalysisComponentCommentsTitle: "AgentAnalysisComponentCommentsTitle",
    AgentAnalysisComponentAudiosTitle: "AgentAnalysisComponentAudiosTitle",
    AgentAnalysisComponentCommentPlaceholder: "AgentAnalysisComponentCommentPlaceholder",
    AgentAnalysisComponentErrorAddingComment: "AgentAnalysisComponentErrorAddingComment",
    AgentAnalysisComponentCommentAdded: "AgentAnalysisComponentCommentAdded",
    AgentAnalysisComponentAudiosTableTitle: "AgentAnalysisComponentAudiosTableTitle",
    AgentAnalysisComponentAudiosTableScore: "AgentAnalysisComponentAudiosTableScore",
    AgentAnalysisComponentAudiosTableView: "AgentAnalysisComponentAudiosTableView",

    CreateHumanAgentPageTitle: "CreateHumanAgentPageTitle",
    CreateHumanAgentPageAgentId: "CreateHumanAgentPageAgentId",
    CreateHumanAgentPageCreate: "CreateHumanAgentPageCreate",
    CreateHumanAgentPageCreateError: "CreateHumanAgentPageCreateError",
    CreateHumanAgentPageCreateSuccess: "CreateHumanAgentPageCreateSuccess",
    CreateHumanAgentPageEdit: "CreateHumanAgentPageEdit",

    RankingAgentsPageTitle: "RankingAgentsPageTitle",
    RankingAgentsPageSearchByName: "RankingAgentsPageSearchByName",
    RankingAgentsPageStartDate: "RankingAgentsPageStartDate",
    RankingAgentsPageEndDate: "RankingAgentsPageEndDate",
    RankingAgentsPageSearch: "RankingAgentsPageSearch",
    RankingAgentsPageNoData: "RankingAgentsPageNoData",
    RankingAgentsPageShowBest: "RankingAgentsPageShowBest",
    RankingAgentsPageShowWorst: "RankingAgentsPageShowWorst",
    RankingAgentsPagePage: "RankingAgentsPagePage",
    RankingAgentsPageSortBy: "RankingAgentsPageSortBy",

    NoResultsComponentMessage: "NoResultsComponentMessage",
    NoResultsComponentSuggestion: "NoResultsComponentSuggestion",
    //#endregion---------------------- Components ----------------------//

    //#region---------------------- Entities ----------------------//
    CategoryEntityTitle: "CategoryEntityTitle",
    CategoryEntityDescription: "CategoryEntityDescription",
    CategoryEntityPoints: "CategoryEntityPoints",

    QuestionEntityTitle: "QuestionEntityTitle",
    QuestionEntityDescription: "QuestionEntityDescription",
    QuestionEntityPoints: "QuestionEntityPoints",
    QuestionEntityCategory: "QuestionEntityCategory",
    QuestionEntityAvgScore: "QuestionEntityAvgScore",

    AgentEntityId: "AgentEntityId",
    AgentEntityName: "AgentEntityName",
    
    HumanAgentEntityStatus: "HumanAgentEntityStatus",
    HumanAgentEntityStatusActive: "HumanAgentEntityStatusActive",
    HumanAgentEntityStatusInactive: "HumanAgentEntityStatusInactive",
    HumanAgentEntityStatusAll: "HumanAgentEntityStatusAll",
    //#endregion---------------------- Entities ----------------------//

    //#region---------------------- Use Cases ----------------------//
    CheckAudioWithQuestionsUseCaseLoadingAudio: "CheckAudioWithQuestionsUseCaseLoadingAudio",
    CheckAudioWithQuestionsUseCaseUploadingAudio: "CheckAudioWithQuestionsUseCaseUploadingAudio",
    CheckAudioWithQuestionsUseCaseUploadedAudio: "CheckAudioWithQuestionsUseCaseUploadedAudio",
    CheckAudioWithQuestionsUseCaseSendingQuestions: "CheckAudioWithQuestionsUseCaseSendingQuestions",
    CheckAudioWithQuestionsUseCaseCreatingPetiton: "CheckAudioWithQuestionsUseCaseCreatingPetiton",
    CheckAudioWithQuestionsUseCaseErrorCreatedPetiton: "CheckAudioWithQuestionsUseCaseErrorCreatedPetiton",
    CheckAudioWithQuestionsUseCaseProcessingPetiton: "CheckAudioWithQuestionsUseCaseProcessingPetiton",
    CheckAudioWithQuestionsUseCaseErrorProcessingQuestions: "CheckAudioWithQuestionsUseCaseErrorProcessingQuestions",
    CheckAudioWithQuestionsUseCaseErrorUploadingAudio: "CheckAudioWithQuestionsUseCaseErrorUploadingAudio",
    CheckAudioWithQuestionsUseCaseProcessingQuestions: "CheckAudioWithQuestionsUseCaseProcessingQuestions",
    CheckAudioWithQuestionsUseCaseDone: "CheckAudioWithQuestionsUseCaseDone",
    //#endregion---------------------- Use Cases ----------------------//

    //#region---------------------- VALIDATORS ----------------------//
    ValidatorRequired: 'ValidatorRequired',
    ValidatorEmail: 'ValidatorEmail',
    ValidatorMinLength: 'ValidatorMinLength',
    ValidatorMaxLength: 'ValidatorMaxLength',
    ValidatorMinValue: 'ValidatorMinValue',
    ValidatorMaxValue: 'ValidatorMaxValue',
    ValidatorMustBeNumber: 'ValidatorMustBeNumber',
    ValidatorSpecialCharactersIsRequired: 'ValidatorSpecialCharactersIsRequired',
    ValidatorUpperCaseIsRequired: 'ValidatorUpperCaseIsRequired',
    ValidatorLowerCaseIsRequired: 'ValidatorLowerCaseIsRequired',
    ValidatorNumberIsRequired: 'ValidatorNumberIsRequired',
    ValidatorMustBeEqual: 'ValidatorMustBeEqual',
    ValidatorSpecialCharacterIsNotAllowed: 'ValidatorSpecialCharacterIsNotAllowed',
    ValidatorUpperCaseIsNotAllowed: 'ValidatorUpperCaseIsNotAllowed',
    ValidatorLowerCaseIsNotAllowed: 'ValidatorLowerCaseIsNotAllowed',
    ValidatorNumberIsNotAllowed: 'ValidatorNumberIsNotAllowed',
    ValidatorIsNotName: 'ValidatorIsNotName',
    ValidatorIsNotPhone: 'ValidatorIsNotPhone',
    ValidatorGreaterThan: 'ValidatorGreaterThan', //value
    ValidatorLessThan: 'ValidatorLessThan', //value
    ValidatorMustHavePartBeforeAt: 'ValidatorMustHavePartBeforeAt',
    ValidatorGreaterOrEqualThan: 'ValidatorGreaterOrEqualThan', //value
    ValidatorMinTime: "ValidatorMinTime", //value
    ValidatorMaxTime: "ValidatorMaxTime", //value    
    // //#endregion---------------------- VALIDATORS ----------------------//

    //#region---------------------- UTILS ----------------------//
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    //#endregion---------------------- UTILS ----------------------//

    //#region---------------------- Others ----------------------//
    UnknownError: 'UnknownError',
    ErrorServerWrongPassword: "ErrorServerWrongPassword",
    ErrorHostInvalidUsernameOrPassword: "ErrorHostInvalidUsernameOrPassword",
    ErrorHostundefined: "ErrorHostundefined",
    //#endregion---------------------- Others ----------------------//

}

export default KeyWordLocalization;