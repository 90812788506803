import QAAgentEntity from "../../domain/entities/qa_agent.entity";
import QACategoryEntity from "../../domain/entities/qa_category.entity";
import QAQuestionEntity from "../../domain/entities/qa_question.entity";
import QACategoryDto from "./qa_category.dto";
import QAQuestionDto from "./qa_question.dto";

const fromJSON = (json: any): QAAgentEntity => {
    const categories:QACategoryEntity[] = json['categories']?.map((item: any) => QACategoryDto.fromJSON(item)) ?? [];
    const questions:QAQuestionEntity[] = json['questions']?.map((item: any) => QAQuestionDto.fromJSON(item)) ?? [];
    
    //asignamos las preguntas a las categorias
    categories.forEach((category) => {
        category.questions = questions.filter((question) => question.categoryId === category.id);
    });
    return new QAAgentEntity({
        id: json['id']?.toString() ?? '',
        description: json['description'] ?? '',
        name: json['name'] ?? '',
        clientId: json['client_id']?.toString() ?? '',
        opTypeId: json['operation_id']?.toString() ?? '',
        categories: categories,
    });
}

export default {
    fromJSON,
}