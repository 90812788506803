import { injectable } from "inversify";
import QARepository from "../../repositories/qa.repository";
import QAAgentEntity from "../../entities/qa_agent.entity";
import { Either } from "fp-ts/lib/Either";
import ErrorEntity from "../../entities/error.entity";
import QAResultEntity from "../../entities/qa_result.entity";

interface _Props {
    qaRepository: QARepository;
}
@injectable()
export default class GetAudioResultUseCase {
    private qaRepository: QARepository;
    constructor(props: _Props) {
        this.qaRepository = props.qaRepository;
    }
    public call = async (jobId: string) : Promise<Either<ErrorEntity, QAResultEntity>> => {
        return this.qaRepository.getAudioResults(jobId);
    }
}

export const GetAudioResultUseCaseName = "GetAudioResultUseCase";