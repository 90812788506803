import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoryCreationComponentProps from "./category_creation.component.props";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import QACategoryEntity from "../../../../../../domain/entities/qa_category.entity";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import ModalsContextType from "../../../../../../domain/providers/modal/ModalsContextType";
import ButtonComponent from "../../../../../components/button/button.component";
import LocalizationContext from "../../../../../providers/localization/LocalizationContext";
import ModalsContext from "../../../../../providers/modal/ModalsContext";
import KeyWordLocalization from "../../../../../utils/KeyWordLocalization";
import Validators from "../../../../../utils/Validators";

const CategoryCreationComponent: FC<CategoryCreationComponentProps> = ({onSave, agentId, category}) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [loading, setLoading] = useState(false);
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const _handleSave = async (data: any) => {
        const newCategory = new QACategoryEntity({
            id: category?.id ?? "",
            description: data[KeyWordLocalization.CategoryEntityDescription],
            agentId: category?.agentId ?? agentId ?? "",
            title: data[KeyWordLocalization.CategoryEntityTitle],
            questions: category?.questions ?? [],
        });
        setLoading(true);
        await onSave(newCategory);
        setLoading(false);
    }

    const _handleLoad = () => {
        if (category) {
            setValue(KeyWordLocalization.CategoryEntityDescription, category.description);
            setValue(KeyWordLocalization.CategoryEntityTitle, category.title);
        }
    }

    const _handleCancel = () => closeModalCustom();

    useEffect(() => {
        _handleLoad();
    }, [category]);

    return <div className="create_category_component">
        <div className="container">
            <form onSubmit={handleSubmit(_handleSave)} className="row">
                <div className={`form-group col-md-12 my-2 ${errors[KeyWordLocalization.CategoryEntityTitle] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CategoryEntityTitle)}</label>
                    <input type="text" className="form-control" disabled={loading} placeholder={i18n(KeyWordLocalization.CategoryEntityTitle)} {...register(KeyWordLocalization.CategoryEntityTitle, Validators({
                        required: true,
                        maxLength: 100,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CategoryEntityTitle} />
                </div>
                <div className={`form-group col-md-12 my-2 ${errors[KeyWordLocalization.CategoryEntityDescription] && 'error error_group'}`}>
                    <label className='form-label'>{i18n(KeyWordLocalization.CategoryEntityDescription)}</label>
                    <textarea className="form-control" rows={10} disabled={loading} placeholder={i18n(KeyWordLocalization.CategoryEntityDescription)} {...register(KeyWordLocalization.CategoryEntityDescription, Validators({
                        required: true,
                        minLength: 5,
                        maxLength: 255,
                    }))} />
                    <aside className={`w-100 text-end text_small ${watch((KeyWordLocalization.CategoryEntityDescription) ?? "")?.length > 255 && "error"}`}>{watch((KeyWordLocalization.CategoryEntityDescription) ?? "")?.length ?? 0}/255</aside>
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.CategoryEntityDescription} />
                </div>
                <div className="px-2 mt-2 container_buttons">
                    <ButtonComponent type="button" onClick={_handleCancel} design="primaryOutline" text={i18n(KeyWordLocalization.CategoryCreationComponentCancel)} />
                    <ButtonComponent type="submit" isLoading={loading} text={category != null ? i18n(KeyWordLocalization.CategoryCreationComponentSave) : i18n(KeyWordLocalization.CategoryCreationComponentCreate)} />
                </div>
            </form>
        </div>
    </div>

}

export default CategoryCreationComponent;