import { Either, left, right } from "fp-ts/lib/Either";
import ErrorEntity from "../../../../domain/entities/error.entity";
import { AverageCalls, ReasonForCalls, Target } from "../../../../domain/repositories/dashboard.repository";
import DateDto from "../../../dto/date.dto";
import di from "../../../../dependency_injection";
import HostApi, { HostApiName } from "../../../settings/HostApi";

const getScoreDetailsApiImpl = async (clientId: string | undefined, startDate: Date, endDate: Date) : Promise<Either<ErrorEntity, Target>> => {
    try {
        const relativeUrl = `/stats/score-detail?start_date=${DateDto.toServer(startDate)}&end_date=${DateDto.toServer(endDate)}`;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl, {
            contentType: 'application/json',
        });
        return right({
            serviceLevel: response.target,
            current: response.current,
            increment: Number((response.current - response.before).toFixed(2)),
        })

    } catch (error) {
        return left(ErrorEntity.fromError(error));
    }
}

export default getScoreDetailsApiImpl;