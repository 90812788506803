import './ranking_agents.page.scss';
import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutComponent from "../../../layouts/layoutComponent/layout.component";
import TableSatisfactionComponent from "../../dashboard/components/tableSatisfaction/table_satisfaction.component";
import LocalizationContext from "../../../providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../utils/KeyWordLocalization";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../../components/button/button.component";
import { ErrorMessage } from "@hookform/error-message";
import Validators from "../../../utils/Validators";
import di from "../../../../dependency_injection";
import SearchRankingHumanAgentsUseCase, { SearchRankingHumanAgentsUseCaseName } from "../../../../domain/use_cases/dashboard/search_ranking_human_agents.use_case";
import { isLeft } from "fp-ts/lib/Either";
import LoadingComponent from "../../../components/LoadingComponent/loading.component";
import SearchResultEntity from "../../../../domain/entities/search_result.entity";
import { HumanRanking } from "../../../../domain/repositories/dashboard.repository";
import PaginatorComponent from "../../../components/paginator/PaginatorComponent";
import AgentAnalysisComponent from '../../dashboard/components/agent_analysis/agent_analysis.component';
import ModalsContext from '../../../providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';

const RankingAgentsPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = useForm();
    const [data, setData] = useState<SearchResultEntity<HumanRanking> | null | undefined>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const colors = ['#ff645e', '#8c8484', '#73bf94'];
    const location = useLocation();
    const navigate = useNavigate();

    const _searchRankingData = async (params: {
        query: string;
        initDate?: Date;
        endDate?: Date;
        page: number;
        itemsPerPage: number;
        sortBy: "ASC" | "DESC";
    }) => {
        setData(undefined);
        setLoaded(false);
        const response = await di.get<SearchRankingHumanAgentsUseCase>(SearchRankingHumanAgentsUseCaseName).call(params);
        if (isLeft(response)) {
            setData(null);
            setLoaded(true);
            return;
        } else {
            setLoaded(true);
            setData(response.right);
        }
    }

    const _updateURL = (params: {
        query: string;
        initDate?: Date;
        endDate?: Date;
        page: number;
        sortBy: "ASC" | "DESC";
    }) => {
        if (params.query != null ||
            params.initDate != null ||
            params.endDate != null ||
            params.page != null || params.sortBy != null) {
            let url = "?";
            if (params.query) url += `query=${params.query}&`;
            if (params.initDate) url += `initDate=${params.initDate.toISOString().split('T')[0]}&`;
            if (params.endDate) url += `endDate=${params.endDate.toISOString().split('T')[0]}&`;
            if (params.page) url += `page=${params.page}&`;
            if (params.sortBy) url += `sortBy=${params.sortBy}&`;

            //if remove last character is & then remove it
            if (url[url.length - 1] === "&") url = url.slice(0, -1);
            navigate({
                pathname: location.pathname,
                search: url
            });
        }
    }

    const _onSubmit = (data: any) => {
        const params = {
            query: data[KeyWordLocalization.RankingAgentsPageSearchByName],
            initDate: data[KeyWordLocalization.RankingAgentsPageStartDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageStartDate]) : undefined,
            endDate: data[KeyWordLocalization.RankingAgentsPageEndDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageEndDate]) : undefined,
            page: 1,
            itemsPerPage: 10,
            sortBy: data[KeyWordLocalization.RankingAgentsPageSortBy]
        };
        setValue(KeyWordLocalization.RankingAgentsPagePage, 1);
        _searchRankingData(params);
        _updateURL(params);
    }

    const _handleChangePage = (page: number) => {
        setValue(KeyWordLocalization.RankingAgentsPagePage, page);
        const data = getValues();
        const params = {
            query: data[KeyWordLocalization.RankingAgentsPageSearchByName],
            initDate: data[KeyWordLocalization.RankingAgentsPageStartDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageStartDate]) : undefined,
            endDate: data[KeyWordLocalization.RankingAgentsPageEndDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageEndDate]) : undefined,
            page: page,
            itemsPerPage: 10,
            sortBy: data[KeyWordLocalization.RankingAgentsPageSortBy]
        };
        _searchRankingData(params);
        _updateURL(params);
    }

    const _handleChangeSortBy = (sortBy: "ASC" | "DESC") => {
        setValue(KeyWordLocalization.RankingAgentsPageSortBy, sortBy);
        const data = getValues();
        const params = {
            query: data[KeyWordLocalization.RankingAgentsPageSearchByName],
            initDate: data[KeyWordLocalization.RankingAgentsPageStartDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageStartDate]) : undefined,
            endDate: data[KeyWordLocalization.RankingAgentsPageEndDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageEndDate]) : undefined,
            page: data[KeyWordLocalization.RankingAgentsPagePage],
            itemsPerPage: 10,
            sortBy: sortBy
        };
        _searchRankingData(params);
        _updateURL(params);
    }

    const _handleSeeAgentDetails = (agentId: string, agentName: string) => {
        const data = getValues();
        const initDate = data[KeyWordLocalization.RankingAgentsPageStartDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageStartDate]) : new Date();
        const endDate = data[KeyWordLocalization.RankingAgentsPageEndDate] ? new Date(data[KeyWordLocalization.RankingAgentsPageEndDate]) : new Date();
        openModalCustom("lg", i18n(KeyWordLocalization.TableSatisfactionDetailClient, { name: agentName }), <AgentAnalysisComponent agentId={agentId} endDate={endDate} startDate={initDate} />, {
            hideCardBody: true,
        });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const initDate = queryParams.get('initDate') ? new Date(queryParams.get('initDate')!) : undefined;
        const endDate = queryParams.get('endDate') ? new Date(queryParams.get('endDate')!) : undefined;
        const page = parseInt(queryParams.get('page') || '1', 10);
        const sortBy = queryParams.get('sortBy') as "ASC" | "DESC" || "ASC";

        setValue(KeyWordLocalization.RankingAgentsPageSearchByName, query);
        setValue(KeyWordLocalization.RankingAgentsPageStartDate, initDate != null ? initDate.toISOString().split('T')[0] : '');
        setValue(KeyWordLocalization.RankingAgentsPageEndDate, endDate != null ? endDate.toISOString().split('T')[0] : '');
        setValue(KeyWordLocalization.RankingAgentsPageSortBy, sortBy);
        setValue(KeyWordLocalization.RankingAgentsPagePage, page);

        const params = {
            query: query,
            initDate: initDate,
            endDate: endDate,
            page: page,
            itemsPerPage: 10,
            sortBy: sortBy
        };
        _searchRankingData(params);
    }, [location.search]);

    return <div className="ranking_agents_page">
        <LayoutComponent title={i18n(KeyWordLocalization.RankingAgentsPageTitle)}>
            <div className="container">
                <form onSubmit={handleSubmit(_onSubmit)}>
                    <div className="row">
                        <div className={`form-group col-md-4 my-3 ${errors[KeyWordLocalization.RankingAgentsPageSearchByName] && 'error error_group'}`}>
                            <label className='form-label'>{i18n(KeyWordLocalization.RankingAgentsPageSearchByName)}</label>
                            <input type="text" className="form-control"
                                {...register(KeyWordLocalization.RankingAgentsPageSearchByName, Validators({}))} />
                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.RankingAgentsPageSearchByName} />
                        </div>
                        <div className={`form-group col-md-3 my-3 ${errors[KeyWordLocalization.RankingAgentsPageStartDate] ? 'error' : ''}`}>
                            <label className="form-label">{i18n(KeyWordLocalization.RankingAgentsPageStartDate)}</label>
                            <input type="date" className="form-control"
                                max={new Date().toISOString().split('T')[0]}
                                {...register(KeyWordLocalization.RankingAgentsPageStartDate, Validators({}))} />
                            <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.RankingAgentsPageStartDate} />
                        </div>
                        <div className={`form-group col-md-3 my-3 ${errors[KeyWordLocalization.RankingAgentsPageEndDate] ? 'error' : ''}`}>
                            <label className="form-label">{i18n(KeyWordLocalization.RankingAgentsPageEndDate)}</label>
                            <input type="date" className="form-control"
                                max={new Date().toISOString().split('T')[0]}
                                {...register(KeyWordLocalization.RankingAgentsPageEndDate, Validators({ greaterOrEqualThan: watch(KeyWordLocalization.RankingAgentsPageStartDate) }))} />
                            <ErrorMessage as="aside" errors={errors} name={KeyWordLocalization.RankingAgentsPageEndDate} />
                        </div>
                        <div className="col-md-2 my-3 d-flex align-items-end">
                            <ButtonComponent className="w-100 justify-content-center" type="submit" icon={
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            } text={i18n(KeyWordLocalization.RankingAgentsPageSearch)} />
                        </div>
                    </div>
                </form>
                <div className="w-100 d-flex justify-content-center">
                    <div className="container_orders col-12 margin-auto">
                        <div className={`btn_order col-6 asc ${watch(KeyWordLocalization.RankingAgentsPageSortBy) === "ASC" ? "active" : ""}`} onClick={() => _handleChangeSortBy("ASC")}>
                            <span className="material-symbols-outlined">arrow_upward</span>
                            {i18n(KeyWordLocalization.RankingAgentsPageShowBest)}
                        </div>
                        <div className={`btn_order col-6 desc ${watch(KeyWordLocalization.RankingAgentsPageSortBy) === "DESC" ? "active" : ""}`} onClick={() => _handleChangeSortBy("DESC")}>
                            <span className="material-symbols-outlined">arrow_downward</span>
                            {i18n(KeyWordLocalization.RankingAgentsPageShowWorst)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {data === undefined && <LoadingComponent />}
                        {data === null && <div>{i18n(KeyWordLocalization.RankingAgentsPageNoData)}</div>}
                        {data && (
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <TableSatisfactionComponent showHeader={false} title={""} data={data.data} colors={colors} openAgentDetails={_handleSeeAgentDetails} />
                                    </div>
                                </div>
                                <div className="paginator mt-3">
                                    {data.pages > 1 && <PaginatorComponent resultData={data} onChangePage={_handleChangePage} />}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </LayoutComponent>
    </div>
}

export default RankingAgentsPage;